<template>
    <div id="customer-feedback-form" class="row">
        <div class="form-fields col-md-8"
             v-show="!sendingCustomerFeedback && !customerFeedbackSentSuccessfully && !customerFeedbackSentWithErrors">
            <div v-if="$content.numberOfStars < 4" id="warning-coupon" class="text-left mb-3">
                <p>
                    <i class="fas fa-exclamation-triangle"></i>
                    <span v-html="$t('customer_feedback_low_score_apologies_intro')"></span>
                </p>
                <p v-html="$t('customer_feedback_low_score_apologies_discount_coupon_intro') + ':'"></p>
                <p id="discount-coupon">{{ $content.discountCouponToken }}</p>
            </div>
            <div id="form-message" class="text-left mb-3">
                <template v-if="$content.numberOfStars === 4 || $content.numberOfStars === 5">
                    <i class="far fa-smile"></i>
                    <template v-if="$content.appointmentInfo.isPureContact">
                        <p v-html="$t('customer_feedback_high_score_intro_with_company_name', { company_name: $content.appointmentInfo.companyName })"></p>
                    </template>
                    <template v-else>
                        <p>{{ $t('customer_feedback_high_score_intro_no_company_name') }}</p>
                    </template>
                </template>
                <template v-else>
                    <i class="far fa-frown-open"></i>
                    <template v-if="$content.appointmentInfo.isPureContact">
                        <p v-html="$t('customer_feedback_low_score_intro_with_company_name', { company_name: $content.appointmentInfo.companyName })"></p>
                    </template>
                    <template v-else>
                        <p>{{ $t('customer_feedback_low_score_intro_no_company_name') }}</p>
                    </template>
                </template>
            </div>
            <div id="ratings" class="ratings-form col-md-12">
                <!-- Service Price -->
                <div class="rating-field row p-2 mb-2">
                    <div class="rating-field-description pt-2 col-md-6 text-left">{{ $t('price_of_the_service') }}:</div>
                    <div class="rating-field-buttons col-md-6 text-right">
                        <button v-for="index in 5"
                                :key="index"
                                :class="[{ 'active' : (formData.servicePrice.value >= index || formData.servicePrice.hoverValue >= index) }]"
                                @click="formData.servicePrice.value = index"
                                @mouseenter="formData.servicePrice.hoverValue = index"
                                @mouseleave="formData.servicePrice.hoverValue = 0"
                        ><i class="fas fa-star"></i></button>
                    </div>
                </div>
                <!-- Service Quality -->
                <div class="rating-field row p-2 mb-2">
                    <div class="rating-field-description pt-2 col-md-6 text-left">{{ $t('quality_of_the_service') }}:</div>
                    <div class="rating-field-buttons col-md-6 text-right">
                        <button v-for="index in 5"
                                :key="index"
                                :class="[{ 'active' : (formData.serviceQuality.value >= index || formData.serviceQuality.hoverValue >= index) }]"
                                @click="formData.serviceQuality.value = index"
                                @mouseenter="formData.serviceQuality.hoverValue = index"
                                @mouseleave="formData.serviceQuality.hoverValue = 0"
                        ><i class="fas fa-star"></i></button>
                    </div>
                </div>
                <!-- Worker Punctuality -->
                <div class="rating-field row p-2 mb-2">
                    <div class="rating-field-description pt-2 col-md-6 text-left">{{ $t('punctuality_of_the_worker') }}:</div>
                    <div class="rating-field-buttons col-md-6 text-right">
                        <button v-for="index in 5"
                                :key="index"
                                :class="[{ 'active' : (formData.workerPunctuality.value >= index || formData.workerPunctuality.hoverValue >= index) }]"
                                @click="formData.workerPunctuality.value = index"
                                @mouseenter="formData.workerPunctuality.hoverValue = index"
                                @mouseleave="formData.workerPunctuality.hoverValue = 0"
                        ><i class="fas fa-star"></i></button>
                    </div>
                </div>
                <!-- Worker Personality -->
                <div class="rating-field row p-2 mb-2">
                    <div class="rating-field-description pt-2 col-md-6 text-left">{{ $t('personality_of_the_worker') }}:</div>
                    <div class="rating-field-buttons col-md-6 text-right">
                        <button v-for="index in 5"
                                :key="index"
                                :class="[{ 'active' : (formData.workerPersonality.value >= index || formData.workerPersonality.hoverValue >= index) }]"
                                @click="formData.workerPersonality.value = index"
                                @mouseenter="formData.workerPersonality.hoverValue = index"
                                @mouseleave="formData.workerPersonality.hoverValue = 0"
                        ><i class="fas fa-star"></i></button>
                    </div>
                </div>
            </div>
            <div id="comments" class="mt-3 mb-3">
                <textarea v-model="formData.comments.value"
                          class="form-control form-control-lg"
                          :class="[{'is-invalid': formData.comments.error}]"
                          :placeholder="$t('comments_optional')">></textarea>
            </div>
            <div id="belfix-platform" class="ratings-form col-md-12">
                <!-- Belfix Platform -->
                <div class="rating-field row p-2 mb-2">
                    <div class="rating-field-description pt-2 col-md-6 text-left">{{ $t('belfix_plaform') }}</div>
                    <div class="rating-field-buttons col-md-6 text-right">
                        <button v-for="index in 5"
                                :key="index"
                                :class="[{ 'active' : (formData.belfixPlatform.value >= index || formData.belfixPlatform.hoverValue >= index) }]"
                                @click="formData.belfixPlatform.value = index"
                                @mouseenter="formData.belfixPlatform.hoverValue = index"
                                @mouseleave="formData.belfixPlatform.hoverValue = 0"
                        ><i class="fas fa-star"></i></button>
                    </div>
                </div>
            </div>
            <div id="submit-form" class="mt-3">
                <button class="btn btn-custom-blue btn-lg btn-block mt-2"
                        :disabled="!(formData.servicePrice.value && formData.serviceQuality.value && formData.workerPunctuality.value && formData.workerPersonality.value && formData.belfixPlatform.value)"
                        @click="submitFeedback">
                    {{ $t('send_feedback') }}
                </button>
            </div>
        </div>
        <div class="sending-customer-feedback col-md-8"
             v-show="sendingCustomerFeedback && !customerFeedbackSentSuccessfully && !customerFeedbackSentWithErrors">
            <p><i class="fas fa-spinner fa-spin fa-7x"></i></p>
            <p>{{ $t('sending_feedback') }}</p>
        </div>
        <div class="customer-feedback-sent col-md-8 text-left"
             v-show="!sendingCustomerFeedback && customerFeedbackSentSuccessfully && !customerFeedbackSentWithErrors">
            <div class="col-md-12 success-message">
                <p><i class="fas fa-check-circle fa-2x"></i> <span>{{ $t('feedback_sent_successfuly') }}</span></p>
            </div>
            <div class="col-md-12 platforms text-center"
                 v-if="($content.numberOfStars === 4 || $content.numberOfStars === 5) && ($content.facebookUrl || $content.trustpilotProfileUrl)">
                <h3>{{ $t('rate_us_title') }}</h3>
                <p v-html="$t('rate_us_message') + ':'"></p>
                <a class="platform facebook"
                   v-if="$content.facebookUrl"
                   :href="$content.facebookUrl"
                   @click="addLogMessage('Customer clicked on the Facebook platform for rating.')"
                   target="_blank"
                   rel="noopener">
                    <img src="/assets/Belfix/images/facebook_logo_white.png" alt="Facebook Logo">
                    <p>{{ $t('like_us_on') }} Facebook</p>
                </a>
                <a class="platform trustpilot text-dark"
                   v-if="$content.trustpilotProfileUrl"
                   :href="$content.trustpilotProfileUrl"
                   target="_blank"
                   rel="noopener">
                    <p>{{ $t('review_us_on') }}</p>
                    <img src="/assets/Belfix/images/trustpilot_logo.png" alt="Trustpilo Logo">
                </a>
                <!--<div v-if="$content.trustpilotProfileUrl"
                     class="trustpilot-widget"
                     :data-locale="$content.languageCode.replace('_', '-')"
                     data-template-id="5419b6a8b0d04a076446a9ad"
                     data-businessunit-id="601adc925975e400013e70f8"
                     data-style-height="24px"
                     data-style-width="100%"
                     data-theme="light">
                    <a href="https://trustpilot.com/review/belfix.be" target="_blank" rel="noopener">Trustpilot</a>
                </div>-->
            </div>
        </div>
        <div class="customer-feedback-fail col-md-8"
             v-show="!sendingCustomerFeedback && !customerFeedbackSentSuccessfully && customerFeedbackSentWithErrors">
            <p><i class="fas fa-times-circle fa-7x"></i></p>
            <p>{{ $t('feedback_sent_fail') }}</p>
            <button class="btn btn-custom-blue btn-lg btn-block mt-2"
                    @click="customerFeedbackSentWithErrors = false">
                {{ $t('click_here_to_try_again') }}
            </button>
        </div>
        <div class="job-type-info col-md-4">
            <template v-if="$content.jobTypeCheckoutImage">
                <img :src="$content.jobTypeCheckoutImage" :alt="$content.jobTypePathTitle">
            </template>
            <div class="job-type-appointment-details text-left p-3">
                <a :href="jobTypePath" target="_blank">
                    <strong>{{ $content.jobTypePathTitle }}</strong>
                </a>
                <br>
                {{ $t('on') }} {{ $content.appointmentInfo.dateFormatted }}
                {{ $moment($content.appointmentInfo.date).format('D MMMM YYYY').toLowerCase() }}:
                <br><br>
                {{ $t('between') }} {{ $content.appointmentInfo.timeFormatted }} {{ $t('and') }} {{ $content.appointmentInfo.twoHoursLaterFormatted }}
            </div>
        </div>
    </div>
</template>
<script>
    import Api from '../../modules/Api';
    import Routing from '../../modules/BackendRouter';

    export default {
        props: {
        },
        data() {
            return {
                formData: {
                    servicePrice: { type: 'rating', value: 0, hoverValue: 0, error: false, required: true },
                    serviceQuality: { type: 'rating', value: 0, hoverValue: 0, error: false, required: true },
                    workerPunctuality: { type:'rating', value: 0, hoverValue: 0, error: false, required: true },
                    workerPersonality: { type: 'rating', value: 0, hoverValue: 0, error: false, required: true },
                    comments: { type: 'textarea', value: '', error: false, required: false },
                    belfixPlatform: { type: 'rating', value: 0, hoverValue: 0, error: false, require: true },
                },
                jobTypePath: this.$content.schemeAndHttpHost + Routing.generate('job_type.' + this.$locale, {slug: this.$content.jobTypeSlug}),
                formDataHasErrors: false,
                sendingCustomerFeedback: false,
                customerFeedbackSentSuccessfully: false,
                customerFeedbackSentWithErrors: false,
            }
        },
        async created() {
            // Send customer information to Trustpilot for auto review reminder
            if (typeof tp === "function" && (this.$content.numberOfStars === 4 || this.$content.numberOfStars === 5)) {
                const trustpilot_invitation = {
                    recipientEmail: this.$content.customerInfo.emailAddress,
                    recipientName: this.$content.customerInfo.firstName,
                    referenceId: this.$content.jobId,
                    source: 'InvitationScript',
                };
                // eslint-disable-next-line no-undef
                tp('createInvitation', trustpilot_invitation);
            }
        },
        methods: {
            async submitFeedback()
            {
                this.sendingCustomerFeedback = true;

                let response = await Api.submitCustomerFeedback(
                    this.$content.appointmentInfo.token
                    , {
                        servicePrice: this.formData.servicePrice.value,
                        serviceQuality: this.formData.serviceQuality.value,
                        workerPunctuality: this.formData.workerPunctuality.value,
                        workerPersonality: this.formData.workerPersonality.value,
                    }
                    , this.formData.comments.value
                    , this.formData.belfixPlatform.value
                    , this.$content.numberOfStars
                    , this.$content.jobId
                    , this.$content.customerInfo.emailAddress
                    , this.$locale);

                this.sendingCustomerFeedback = false;
                if (response.success) {
                    this.customerFeedbackSentSuccessfully = true;
                } else {
                    this.customerFeedbackSentWithErrors = true;
                }
            },
            async addLogMessage(logMessage)
            {
                await Api.addCustomerFeedbackLog(this.$content.appointmentInfo.token, logMessage);
            },
        },
        components: {
        }
    }
</script>
