<template>
    <div>
        <template v-if="!applicationSent">
            <div class="form-description col-md-12">{{ $t('apply_or_spontaneous') }}:</div>
            <div class="form-fields col-md-12">
                <div class="form-row p-2">
                    <div class="col">
                        <input v-model="formData.formName.value"
                               :class="['form-control', 'form-control-lg', {'is-invalid': formData.formName.error}]"
                               type="text"
                               :placeholder="$t('your_first_and_last_name') + ((formData.formName.required) ? ' *' : '')">
                    </div>
                </div>

                <div class="form-row p-2">
                    <div class="col">
                        <input v-model="formData.formEmail.value"
                               :class="['form-control', 'form-control-lg', {'is-invalid': formData.formEmail.error}]"
                               type="email"
                               :placeholder="$t('your_email') + ((formData.formEmail.required) ? ' *' : '')">
                    </div>
                </div>

                <div class="form-row p-2">
                    <div class="col-md-4 p-1">
                        <multiselect v-model="multiSelectCountryPhonePrefixHolder"
                                     :options="$content.countries"
                                     :searchable="false"
                                     :show-labels="false"
                                     :class="['countries']"
                                     :allowEmpty="false"
                                     @select="setPhonePrefix">
                            <template slot="singleLabel" slot-scope="props">
                                <ul class="f32"><li :class="['flag', props.option.iso]"></li></ul>
                                <span class="option__desc">
                                        <span class="option__title">+{{ props.option.phonePrefix }}</span>
                                    </span>
                            </template>
                            <template slot="option" slot-scope="props">
                                <ul class="f32"><li :class="['flag', props.option.iso]"></li></ul>
                                <div class="option__desc">
                                    <span class="option__title">+ {{ props.option.phonePrefix }}</span>
                                </div>
                            </template>
                        </multiselect>
                    </div>
                    <div class="col-md-8 p-1">
                        <input v-model="formData.formPhoneNumber.value"
                               :class="['form-control', 'form-control-lg', {'is-invalid': formData.formPhoneNumber.error}]"
                               type="number"
                               :placeholder="$t('your_phone_number') + ((formData.formPhoneNumber.required) ? ' *' : '')">
                    </div>
                </div>

                <div class="form-row p-2">
                    <div class="col">
                        <input v-model="formData.formJobTitle.value"
                               :class="['form-control', 'form-control-lg', {'is-invalid': formData.formJobTitle.error}]"
                               type="text"
                               :placeholder="$t('job_title') + ((formData.formJobTitle.required) ? ' *' : '')">
                    </div>
                </div>

                <div class="form-row p-2">
                    <div class="col">
                        <label for="CVFile">{{ $t('your_cv') + ((formData.formCV.required) ? ' *' : '') }}:</label>
                        <input id="CVFile"
                               @change="handleFileSelection"
                               :class="['form-control', 'form-control-lg', 'form-control-file', {'is-invalid': formData.formCV.error}]"
                               type="file"
                               accept="application/pdf">
                        <em>{{ $t('allowed_file_extension') }}: <strong>.pdf</strong> | {{ $t('maximum_file_size') }}: <strong>{{ maximumFileSizeInMb }}MB</strong></em>
                    </div>
                </div>

                <div class="form-row p-2">
                    <div class="col">
                        <textarea v-model="formData.formMessage.value"
                                  :class="['form-control', 'form-control-lg', {'is-invalid': formData.formMessage.error}]"
                                  :placeholder="$t('your_message') + ((formData.formMessage.required) ? ' *' : '')">
                        </textarea>
                    </div>
                </div>

                <div class="form-row p-2">
                    <button @click="submitForm"
                            v-if="!sendingApplication"
                            type="button"
                            class="btn btn-custom-blue btn-lg btn-block mt-2">
                        {{ $t('submit_application') }}
                    </button>
                    <button v-if="sendingApplication"
                            type="button"
                            class="btn btn-default btn-lg btn-block mt-2"
                            disabled>
                        {{ $t('sending_your_application') }} <i class="fas fa-circle-notch fa-spin"></i>
                    </button>
                </div>
            </div>
        </template>
        <template v-if="applicationSent">
            <div class="application-sent text-center">
                <i class="fas fa-check-circle"></i>
                <h3>{{ $t('your_application_was_received') }}</h3>
                <button @click="applicationSent = false"
                        class="btn btn-custom with-shadow mt-5 btn-lg btn-block">
                    <i class="fas fa-chevron-left"></i> {{ $t('go_back') }}
                </button>
            </div>
        </template>
    </div>
</template>

<script>
    import Api from '../../modules/Api';

    export default {
        props: {
        },
        data() {
            return {
                formData: {
                    formName: { type: 'text', value: '', error: false, required: true },
                    formEmail: { type: 'email', value: '', error: false, required: true },
                    formPhoneNumberPrefix: { type:'text', value: this.$content.defaultCountry.phonePrefix, error: false, required: true },
                    formPhoneNumber: { type: 'number', value: '', error: false, required: true },
                    formJobTitle: { type: 'text', value: '', error: false, required: true },
                    formCV: { type: 'file', value: '', error: false, required: true },
                    formMessage: { type: 'text', value: '', error: false, required: false },
                },
                formDataHasErrors: false,
                maximumFileSizeInMb: 2,
                sendingApplication: false,
                applicationSent: false,
                multiSelectCountryPhonePrefixHolder: this.$content.defaultCountry,
            }
        },
        async created() {
        },
        methods: {
            setPhonePrefix(country)
            {
                this.formData.formPhoneNumberPrefix.value = country.phonePrefix;
            },
            getFileSizeInMb(file)
            {
                return Math.round((file.size / Math.pow(1024, 2)) * 100) / 100;
            },
            handleFileSelection(event) {
                this.formData.formCV.value = event.target.files[0];
                const fileSizeInMb = this.getFileSizeInMb(this.formData.formCV.value);
                if (fileSizeInMb > this.maximumFileSizeInMb || this.formData.formCV.value.type !== 'application/pdf') {
                    if (fileSizeInMb > this.maximumFileSizeInMb) {
                        alert(this.$t('invalid_file_size', { maximumFileSizeInMb : this.maximumFileSizeInMb, fileSizeInMb : fileSizeInMb }));
                    }
                    if (this.formData.formCV.value.type !== 'application/pdf') {
                        alert(this.$t('invalid_file_extension', { fileExtension : (this.formData.formCV.value.type ? this.formData.formCV.value.type : this.$t('unknown')) }));
                    }
                    let htmlFileForm = document.getElementById('CVFile');
                    htmlFileForm.value = htmlFileForm.defaultValue;
                    this.formData.formCV.value = null;
                }
            },
            emailValidator(email)
            {
                let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },
            validatePhoneNumber(phoneNumber)
            {
                let testOne,
                    testTwo;
                this.$content.countries.forEach((country) => {
                    testOne = '+' + country.phonePrefix;
                    testTwo = '00' + country.phonePrefix;
                    if (phoneNumber.substring(0, testOne.length) === testOne) {
                        phoneNumber = phoneNumber.substring(testOne.length, phoneNumber.length);
                    }
                    if (phoneNumber.substring(0, testTwo.length) === testTwo) {
                        phoneNumber = phoneNumber.substring(testTwo.length, phoneNumber.length);
                    }
                });
                if (phoneNumber.substring(0, 1) === '0') {
                    phoneNumber = phoneNumber.substring(1, phoneNumber.length);
                }
                return phoneNumber;
            },
            async submitForm()
            {
                // Let's validate all required fields
                this.formDataHasErrors = false; // Reset the error flag
                Object.keys(this.formData).forEach((key) => {
                    if (this.formData[key].required) {
                        switch (this.formData[key].type) {
                            case 'text':
                                this.formData[key].error = !this.formData[key].value.length;
                                break;
                            case 'number':
                                this.formData[key].error = !this.formData[key].value.length;
                                if (!this.formData[key].error && key === 'formPhoneNumber') {
                                    this.formData[key].value = this.validatePhoneNumber(this.formData[key].value);
                                }
                                break;
                            case 'email':
                                this.formData[key].error = !this.emailValidator(this.formData[key].value);
                                break;
                            case 'checkbox': // boolean
                                this.formData[key].error = !this.formData[key].value;
                                break;
                            case 'file':
                                this.formData[key].error = !this.formData[key].value;
                                break;
                        }
                        if (this.formData[key].error) {
                            this.formDataHasErrors = true;
                        }
                    }
                });

                if (!this.formDataHasErrors) {
                    this.sendingApplication = true;
                    const formData = new FormData();
                    formData.append('recruitment-name', this.formData.formName.value);
                    formData.append('recruitment-email', this.formData.formEmail.value);
                    formData.append('recruitment-phone-number', '00' + this.formData.formPhoneNumberPrefix.value + this.formData.formPhoneNumber.value);
                    formData.append('recruitment-job-title', this.formData.formJobTitle.value);
                    formData.append('recruitment-cv-file', this.formData.formCV.value, this.formData.formCV.value.name);
                    formData.append('recruitment-message', this.formData.formMessage.value);
                    formData.append('recruitment-language', this.$locale);
                    await Api.submitRecruitment(formData);
                    this.sendingApplication = false;
                    this.applicationSent = true;
                }
            },
        },
        components: {
        }
    }
</script>
