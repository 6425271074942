// Little hack to get the current user selected language and communicate it with the API
//window.EasyFixLanguage = document.head.querySelector("[name~=EasyFixUserLanguage][content]").content;

// Vue
import Vue from 'vue';
import App from './App.vue';

// Bootstrap Vue
import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

// Vue Cookies
import VueCookies from 'vue-cookies';
Vue.use(VueCookies);

// Vue ScrollTo
import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo);

// Lodash
import lodash from 'lodash';
Object.defineProperty(Vue.prototype, '$lodash', { value: lodash });

// Multiselect
import Multiselect from 'vue-multiselect';
Vue.component('multiselect', Multiselect);

// Vue I18n
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

// Vue I18n
import LocalForage from 'localforage';
Vue.use(LocalForage);

// Vue Filter Number Format
import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';
Vue.filter('numFormat', numFormat(numeral));

// Vue Slider Component
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
Vue.component('VueSlider', VueSlider)

// Replace Filter
Vue.filter('replace', (value, find, replace) => {
    return value.replace(find, replace);
});

Vue.config.productionTip = false;

// Create a scroll directive (v-scroll)
Vue.directive('scroll', {
    inserted: function (el, binding) {
        let f = function (evt) {
            if (binding.value(evt, el)) {
                window.removeEventListener('scroll', f)
            }
        };
        window.addEventListener('scroll', f)
    }
});

window.initializeVue = function (component, containerId, route, locale, content, containerMinHeight, websiteDomain, currentTheme, translationFile)
{
    Vue.prototype.$component = component;
    Vue.prototype.$route = route;
    Vue.prototype.$content = content;
    Vue.prototype.$locale = locale;
    Vue.prototype.$currentTheme = currentTheme;
    Vue.prototype.$websiteDomain = websiteDomain;
    Vue.prototype.$translationFile = translationFile;

    // Vue Moment
    const moment = require('moment');
    require('moment/locale/' + ((locale === 'en') ? 'en-gb' : locale));
    Vue.use(require('vue-moment'), { moment });

    // Get the windows inner with and height for the options div and the video element
    Vue.prototype.$innerWindowWidth = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;
    Vue.prototype.$innerWindowHeight = window.innerHeight
        || document.documentElement.clientHeight
        || document.body.clientHeight;

    // Translations
    let translations = {};
    translations[locale] = require('../../src/EasyFixBundle/Resources/translations/' + Vue.prototype.$translationFile + '.' + locale + '.json');
    // Let's check if the user is in a mobile phone
    Vue.prototype.$isMobile = false;
    (function(a){
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substr(0,4))) Vue.prototype.$isMobile = true;
    })(navigator.userAgent||navigator.vendor||window.opera);
    Vue.prototype.$isIos = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    Vue.prototype.$isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    // Prepares the translation environment
    const i18n = new VueI18n({
        locale: locale,
        messages: translations
    });

    // Mounts the Vue JS APP
    new Vue({
        render: h => h(App),
        i18n
    }).$mount('#' + containerId);

    // Set's a min-height for the app container
    if (containerMinHeight) {
        const headingContainer = document.getElementById('heading');
        let headingContainerHeight = (headingContainer) ? headingContainer.offsetHeight : 0;
        document.getElementById('app').style.minHeight = (window.innerHeight  - headingContainerHeight -  document.getElementById('colorlib-footer').offsetHeight) + 'px';
    }
};
