export const getDefaultValue = (questionModel) => {
    switch (questionModel.type) {
        case 'slider':
            return questionModel.default;
        case 'choice':
            for (let i = 0; i < questionModel.choices.length; i++) {
                const choice = questionModel.choices[i];
                if (choice.isDefault !== undefined && choice.isDefault === true) {
                    return i;
                }
            }
            throw new Error(`no default in choices [${JSON.stringify(questionModel.choices)}]`);
        default:
            throw new Error(`unknown question type [${questionModel.type}]`);
    }
}

export const getRangeForValue = (questionModel, value) => {
    switch (questionModel.type) {
        case 'slider':
            if (questionModel.valueToRange !== undefined) {
                if (questionModel.valueToRange[value] === undefined) {
                    throw new Error(`no range for value [${value}]`);
                }
                return questionModel.valueToRange[value];
            }
            return {min: value, max: value};
        case 'choice':
            return {
                min: questionModel.choices[value].min,
                max: questionModel.choices[value].max,
            };
        default:
            throw new Error(`unknown question type [${questionModel.type}]`);
    }
}
