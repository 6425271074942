import Routing from './BackendRouter';
import GenericApi from './GenericApi';

import axios from 'axios';
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.withCredentials = true;

const genericApi = new GenericApi(axios);

export default {
    getPelicanSuggestSlotsByZoneId(pelicanLeafJobTypeId, zoneId, locale, workerId = null, httpReferrer = null)
    {
        return genericApi.request(
            {
                method: 'post',
                url: Routing.generate('get_suggest_slots'),
                postData: {
                    pelicanLeafJobTypeId: pelicanLeafJobTypeId,
                    zoneId: zoneId,
                    language: locale,
                    workerId: workerId,
                    httpReferrer: httpReferrer,
                },
                responseDataSelector: (responseData) => responseData
            }
        )
    },
    searchPostCode(searchQuery, locale)
    {
        return genericApi.request(
            {
                method: 'post',
                url: Routing.generate('search_post_code'),
                postData: {
                    searchQuery: searchQuery,
                    language: locale,
                },
                responseDataSelector: (responseData) => responseData.results
            }
        )
    },
    searchStreet(streetName, postCode, locale)
    {
        return genericApi.request(
            {
                method: 'post',
                url: Routing.generate('search_street'),
                postData: {
                    streetName: streetName,
                    postCode: postCode,
                    language: locale,
                },
                responseDataSelector: (responseData) => responseData.streets
            }
        )
    },
    checkoutSubmitAppointment(appointmentInfo, customerInfo, jobTypeInfo, createUserTask, locale, appointmentPayloadToken = null)
    {
        return genericApi.request(
            {
                method: 'post',
                url: Routing.generate('checkout_submit_appointment'),
                postData: {
                    appointmentInfo: appointmentInfo,
                    customerInfo: customerInfo,
                    jobTypeInfo: jobTypeInfo,
                    createUserTask: createUserTask,
                    language: locale,
                    appointmentPayloadToken: appointmentPayloadToken,
                },
                responseDataSelector: (responseData) => responseData
            }
        )
    },
    submitRecruitment(formData)
    {
        return genericApi.request(
            {
                method: 'post',
                url: Routing.generate('submit_recruitment'),
                postData: formData,
                responseDataSelector: (responseData) => responseData
            }
        )
    },
    submitRecruitmentPro(formData)
    {
        return genericApi.request(
            {
                method: 'post',
                url: Routing.generate('submit_recruitment_pro'),
                postData: formData,
                responseDataSelector: (responseData) => responseData
            }
        )
    },
    pelicanPresenceConnect(customUri = null)
    {
        return genericApi.request(
            {
                method: 'post',
                url: Routing.generate('pelican_presence_connect'),
                postData: {
                    customUri : customUri
                },
                responseDataSelector: (responseData) => responseData
            }
        )
    },
    submitCustomerFeedback(jobToken, formData, comments, platformRating, numberOfStars, jobId, customerEmail, language)
    {
        return genericApi.request(
            {
                method: 'post',
                url: Routing.generate('customer_submit_feedback'),
                postData: {
                    jobToken : jobToken,
                    formData : formData,
                    comments: comments,
                    platformRating: platformRating,
                    numberOfStars: numberOfStars,
                    jobId: jobId,
                    customerEmail: customerEmail,
                    language : language,
                },
                responseDataSelector: (responseData) => responseData
            }
        )
    },
    addCustomerFeedbackLog(jobToken, logMessage)
    {
        return genericApi.request(
            {
                method: 'post',
                url: Routing.generate('add_customer_feedback_log'),
                postData: {
                    jobToken : jobToken,
                    logMessage : logMessage,
                },
                responseDataSelector: (responseData) => responseData
            }
        )
    },
    submitEnterpriseRating(enterpriseNumber, rating)
    {
        return genericApi.request(
            {
                method: 'post',
                url: Routing.generate('submit_enterprise_rating'),
                postData: {
                    enterpriseNumber : enterpriseNumber,
                    rating : rating,
                },
                responseDataSelector: (responseData) => responseData
            }
        )
    },
    checkCouponCode(couponCode)
    {
        return genericApi.request(
            {
                method: 'post',
                url: Routing.generate('check_coupon_code'),
                postData: {
                    couponCode : couponCode,
                },
                responseDataSelector: (responseData) => responseData
            }
        )
    },
    checkIfNewAddress(street, streetNumber, postCode, regionSlug, communeNameSlug, zoneId, locale)
    {
        return genericApi.request(
            {
                method: 'post',
                url: Routing.generate('check_if_new_address'),
                postData: {
                    street : street,
                    streetNumber: streetNumber,
                    postCode: postCode,
                    regionSlug: regionSlug,
                    communeNameSlug: communeNameSlug,
                    zoneId: zoneId,
                    language: locale
                },
                responseDataSelector: (responseData) => responseData
            }
        )
    },
    sendEmailPdfQuotation(currentLang, jobTypePath, quotationModel, quotationDatas, evaluatedFormulas, userEmail)
    {
        return genericApi.request(
            {
                method: 'post',
                url: Routing.generate('generate_pdf_for_quotation'),
                postData: {
                    currentLang : currentLang,
                    jobTypePath : jobTypePath,
                    quotationModel : quotationModel,
                    quotationDatas : quotationDatas,
                    evaluatedFormulas : evaluatedFormulas,
                    userEmail: userEmail,
                },
                responseDataSelector: (responseData) => responseData
            }
        )
    },
    getQuotationPermalink(jobTypePathUrl, quotationDatas)
    {
        return genericApi.request(
            {
                method: 'post',
                url: Routing.generate('get_quotation_permalink'),
                postData: {
                    jobTypePathUrl : jobTypePathUrl,
                    quotationDatas : quotationDatas,
                },
                responseDataSelector: (responseData) => responseData
            }
        )
    },
    saveEvaluatedFormulaOnSession(min, max, jobTypePathUrl, quotationDatas)
    {
        return genericApi.request(
            {
                method: 'post',
                url: Routing.generate('save_evaluated_formula_quotation'),
                postData: {
                    evaluated_min : min,
                    evaluated_max : max,
                    jobTypePathUrl : jobTypePathUrl,
                    quotationDatas : quotationDatas,
                },
                responseDataSelector: (responseData) => responseData
            }
        )
    },
    reportCheckoutScheduleNoEmailReceived(customerEmail, locale, appointmentPayloadId = null, jobId = null)
    {
        return genericApi.request(
            {
                method: 'post',
                url: Routing.generate('report_checkout_schedule_no_email_received'),
                postData: {
                    appointmentPayloadId    : appointmentPayloadId,
                    jobId                   : jobId,
                    customerEmail           : customerEmail,
                    customerLanguage        : locale
                },
                responseDataSelector: (responseData) => responseData
            }
        )
    },
    reportCheckoutScheduleEmailUpdate(newEmailAddress, locale, appointmentPayloadId = null, jobId = null)
    {
        return genericApi.request(
            {
                method: 'post',
                url: Routing.generate('report_checkout_schedule_email_update'),
                postData: {
                    appointmentPayloadId    : appointmentPayloadId,
                    jobId                   : jobId,
                    newEmailAddress         : newEmailAddress,
                    customerLanguage        : locale
                },
                responseDataSelector: (responseData) => responseData
            }
        )
    },
    updateJobTypeNameTranslation(languageCode, jobTypeId, name, jobTypeAutoCreateBrokenRoute)
    {
        return genericApi.request(
            {
                method: 'post',
                url: Routing.generate('update_job_type_name_translation'),
                postData: {
                    languageCode                : languageCode,
                    jobTypeId                   : jobTypeId,
                    name                        : name,
                    jobTypeAutoCreateBrokenRoute: jobTypeAutoCreateBrokenRoute,
                },
                responseDataSelector: (responseData) => responseData
            }
        )
    },
    updateJobTypeAuxiliariesTranslation(languageCode, originalValue, newValue)
    {
        return genericApi.request(
            {
                method: 'post',
                url: Routing.generate('update_job_type_auxiliaries_translation'),
                postData: {
                    languageCode    : languageCode,
                    originalValue   : originalValue,
                    newValue        : newValue,
                },
                responseDataSelector: (responseData) => responseData
            }
        )
    },
    updateJobTypeLevelTranslation(languageCode, jobTypeLevelId, newLabel)
    {
        return genericApi.request(
            {
                method: 'post',
                url: Routing.generate('update_job_type_level_translation'),
                postData: {
                    languageCode    : languageCode,
                    jobTypeLevelId  : jobTypeLevelId,
                    newLabel        : newLabel,
                },
                responseDataSelector: (responseData) => responseData
            }
        )
    },
    updateServiceTranslation(languageCode, serviceId, newName)
    {
        return genericApi.request(
            {
                method: 'post',
                url: Routing.generate('update_service_translation'),
                postData: {
                    languageCode    : languageCode,
                    serviceId       : serviceId,
                    newName         : newName,
                },
                responseDataSelector: (responseData) => responseData
            }
        )
    },
    updateRecruitmentProSkillTranslation(languageCode, recruitmentProSkillId, newName)
    {
        return genericApi.request(
            {
                method: 'post',
                url: Routing.generate('update_recruitment_pro_skill_translation'),
                postData: {
                    languageCode            : languageCode,
                    recruitmentProSkillId   : recruitmentProSkillId,
                    newName                 : newName,
                },
                responseDataSelector: (responseData) => responseData
            }
        )
    },
    updateYmlFileTranslations(languageCode, fileContents)
    {
        return genericApi.request(
            {
                method: 'post',
                url: Routing.generate('update_yml_file_translations'),
                postData: {
                    languageCode    : languageCode,
                    fileContents    : fileContents,
                },
                responseDataSelector: (responseData) => responseData
            }
        )
    },
    updateLegalTranslations(languageCode, fileContents)
    {
        return genericApi.request(
            {
                method: 'post',
                url: Routing.generate('update_legal_translations'),
                postData: {
                    languageCode    : languageCode,
                    fileContents    : fileContents,
                },
                responseDataSelector: (responseData) => responseData
            }
        )
    },
    updatePrivacyPolicyTranslations(languageCode, fileContents)
    {
        return genericApi.request(
            {
                method: 'post',
                url: Routing.generate('update_privacy_policy_translations'),
                postData: {
                    languageCode    : languageCode,
                    fileContents    : fileContents,
                },
                responseDataSelector: (responseData) => responseData
            }
        )
    },
    clearAllCaches()
    {
        return genericApi.request(
            {
                method: 'post',
                url: Routing.generate('api_clear_all_caches'),
                postData: {},
                responseDataSelector: (responseData) => responseData
            }
        )
    }
}

