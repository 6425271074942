<template>
    <div class="col-12 p-0 pb-3">
        <div id="header" class="col-12 pt-3 pb-3">
            <button
                class="btn"
                :class="activeLanguage === language.code ? 'btn-primary' : 'btn-secondary'"
                v-for="language in content.languages"
                @click="activeLanguage = language.code"
                :key="language.code">
                <i class="fas fa-flag"></i> {{ language.name }} ({{ language.code }})
            </button>
            <div class="d-inline m-0 p-0 pl-2">
                <em><small>Total Translations word count:</small></em><code class="ml-1">{{ getWordCount() }}</code>
            </div>
            <button
                class="btn float-lg-right btn-secondary"
                :disabled="clearingCaches"
                @click="visitWebsite(activeLanguage)">
                <i class="fas fa-globe"></i> Visit website
            </button>
            <button
                class="btn float-right btn-secondary"
                :disabled="clearingCaches || cachesCleared"
                @click="clearAllCaches">
                <template v-if="!clearingCaches && !cachesCleared">
                    <i class="fas fa-broom"></i> Clear all caches
                </template>
                <template v-if="clearingCaches && !cachesCleared">
                    <i class="fas fa-spinner fa-spin"></i> Working ...
                </template>
                <template v-if="!clearingCaches && cachesCleared">
                    <i class="fas fa-check"></i> All caches cleared!
                </template>
            </button>
        </div>
        <div id="body" class="col-12 p-3">
            <div class="sections col-12 p-0">
                <button
                    class="btn"
                    :class="activeTab === 'job_type_translations' ? 'btn-primary' : 'btn-secondary'"
                    @click="activeTab = 'job_type_translations'">
                    JobType Translations</button>
                <button
                    class="btn"
                    :class="activeTab === 'job_type_auxiliaries_translations' ? 'btn-primary' : 'btn-secondary'"
                    @click="activeTab = 'job_type_auxiliaries_translations'">
                    JobType Auxiliaries Translations</button>
                <button
                    class="btn"
                    :class="activeTab === 'job_type_level_translations' ? 'btn-primary' : 'btn-secondary'"
                    @click="activeTab = 'job_type_level_translations'">
                    JobType Level Translations</button>
                <button
                    class="btn"
                    :class="activeTab === 'service_translations' ? 'btn-primary' : 'btn-secondary'"
                    @click="activeTab = 'service_translations'">
                    Service Translations</button>
                <button
                    class="btn"
                    :class="activeTab === 'recruitment_pro_skill_translations' ? 'btn-primary' : 'btn-secondary'"
                    @click="activeTab = 'recruitment_pro_skill_translations'">
                    Recruitment Pro Skill Translations</button>
                <button
                    class="btn"
                    :class="activeTab === 'yml_file_translations' ? 'btn-primary' : 'btn-secondary'"
                    @click="activeTab = 'yml_file_translations'">
                    YML File Translations</button>
                <button
                    class="btn"
                    :class="activeTab === 'legal_translations' ? 'btn-primary' : 'btn-secondary'"
                    @click="activeTab = 'legal_translations'">
                    Legal</button>
                <button
                    class="btn"
                    :class="activeTab === 'privacy_policy_translations' ? 'btn-primary' : 'btn-secondary'"
                    @click="activeTab = 'privacy_policy_translations'">
                    Privacy Policy</button>
            </div>

            <!-- JobType Translations -->
            <div v-show="activeTab === 'job_type_translations'" class="alert alert-warning mt-2" role="alert">
                The JobType translations represents the name of all categories and sub-categories of main services Belfix provides. You will find it throughout the website.
                <br>Example: <a href="https://belfix.be/en/heating-engineer" target="_blank">https://belfix.be/en/heating-engineer</a> [<a href="/assets/Belfix/images/editor/job_type_translations_example_image_1.png" target="_blank"><i class="fas fa-image"></i> see example image</a>]
            </div>
            <div v-show="activeTab === 'job_type_translations'" class="m-0 p-0 text-right">
                <em><small>JobType Translations total word count:</small></em><code class="ml-1">{{ getWordCount('jobTypeTranslations') }}</code>
            </div>
            <div v-show="activeTab === 'job_type_translations'"
                 class="job_type translation_contents form-group col-12 mt-3 mb-3 pt-2 pb-2 pl-0 pr-0"
                 v-for="(jobTypeTranslation) in content.languages[activeLanguage].jobTypeTranslations" :key="jobTypeTranslation.id">
                <div class="col-12 template_value">
                    <div class="col-8 d-inline-block pl-0"><strong class="d-inline-block">EN:</strong><span class="d-inline-block">{{ content.languages[activeLanguage].jobTypeTranslations[jobTypeTranslation.id].nameTemplate }}</span></div>
                    <div class="col-4 d-inline-block float-right text-right translation_content_id">JobType ID: <code>{{ content.languages[activeLanguage].jobTypeTranslations[jobTypeTranslation.id].id }}</code></div>
                </div>
                <div class="col-12 translation_context" v-if="content.languages[activeLanguage].jobTypeTranslations[jobTypeTranslation.id].jobTypePathTemplate">
                    <em><strong>Context:</strong> {{ content.languages[activeLanguage].jobTypeTranslations[jobTypeTranslation.id].jobTypePathTemplate }} > {{ content.languages[activeLanguage].jobTypeTranslations[jobTypeTranslation.id].nameTemplate }}</em>
                </div>
                <div class="col-12">
                    <label class="text-uppercase d-inline-block" :for="'job_type_translation_name_' + activeLanguage + '_' + jobTypeTranslation.id">{{ activeLanguage }}:</label>
                    <input
                        :name="'job_type_translation_name_' + activeLanguage + '_' + jobTypeTranslation.id"
                        type="text"
                        class="form-control d-inline-block"
                        @blur="updateTranslation('jobTypeTranslations', activeLanguage, jobTypeTranslation.id, content.languages[activeLanguage].jobTypeTranslations[jobTypeTranslation.id].name)"
                        :disabled="content.languages[activeLanguage].jobTypeTranslations[jobTypeTranslation.id].saving"
                        v-model="content.languages[activeLanguage].jobTypeTranslations[jobTypeTranslation.id].name">
                </div>
                <div class="col-12 row">
                    <div class="col-8">
                        <div class="field_messages pt-2">
                            <div class="field_message saving" v-show="content.languages[activeLanguage].jobTypeTranslations[jobTypeTranslation.id].saving">
                                <i class="fas fa-spinner fa-spin"></i> Saving JobType name ...
                            </div>
                            <div class="field_message success" v-show="content.languages[activeLanguage].jobTypeTranslations[jobTypeTranslation.id].success">
                                <i class="fas fa-check"></i> JobType saved successfully!
                            </div>
                            <div class="field_message error" v-show="content.languages[activeLanguage].jobTypeTranslations[jobTypeTranslation.id].error">
                                <i class="fas fa-times"></i> Something went wrong while saving this JobType. Please try again or contact the developer.
                            </div>
                        </div>
                    </div>
                    <div class="col-4 text-right pr-0">
                        <small>Translation word count: <code>{{ getWordCount('jobTypeTranslations', content.languages[activeLanguage].jobTypeTranslations[jobTypeTranslation.id].name) }}</code></small>
                    </div>
                </div>
            </div>

            <!-- JobType Auxiliaries Translations -->
            <div v-show="activeTab === 'job_type_auxiliaries_translations'" class="alert alert-warning mt-2" role="alert">
                The JobType Auxiliaries translations represents important information about certain Belfix main services, related to its price or the service itself.
                <br>Example: <a href="https://belfix.be/en/plumbing-installation-renovation-quote-piping" target="_blank">https://belfix.be/en/plumbing-installation-renovation-quote-piping</a> [<a href="/assets/Belfix/images/editor/job_type_auxiliaries_translations_example_image_1.png" target="_blank"><i class="fas fa-image"></i> see example image 1</a>] [<a href="/assets/Belfix/images/editor/job_type_auxiliaries_translations_example_image_2.png" target="_blank"><i class="fas fa-image"></i> see example image 2</a>]
            </div>
            <div v-show="activeTab === 'job_type_auxiliaries_translations'" class="m-0 p-0 text-right">
                <em><small>JobType Auxiliaries Translations total word count:</small></em><code class="ml-1">{{ getWordCount('jobTypeAuxiliariesTranslations') }}</code>
            </div>
            <div v-show="activeTab === 'job_type_auxiliaries_translations'"
                 class="job_type_auxiliary translation_contents form-group col-12 mt-3 mb-3 p-3"
                 v-for="(jobTypeAuxiliaryTranslation, index) in content.languages[activeLanguage].jobTypeAuxiliariesTranslations" :key="'job_type_auxiliaries_translation_' + index">
                <div class="col-12 template_value">
                    <div class="col-12 d-inline-block pl-0"><strong class="d-inline-block">EN:</strong><span class="d-inline-block">{{ content.languages[activeLanguage].jobTypeAuxiliariesTranslations[index].templateTranslationValue }}</span></div>
                </div>
                <div class="col-12">
                    <label class="text-uppercase d-inline-block" :for="'job_type_auxiliaries_translation_name_' + activeLanguage + '_' + index">{{ activeLanguage }}:</label>
                    <input
                        :name="'job_type_auxiliaries_translation_name_' + activeLanguage + '_' + index"
                        type="text"
                        class="form-control d-inline-block"
                        @blur="updateTranslation('jobTypeAuxiliariesTranslations', activeLanguage, index, content.languages[activeLanguage].jobTypeAuxiliariesTranslations[index].translationValue)"
                        v-model="content.languages[activeLanguage].jobTypeAuxiliariesTranslations[index].translationValue">
                </div>
                <div class="col-12 row">
                    <div class="col-8">
                        <div class="field_messages pt-2">
                            <div class="field_message saving" v-show="content.languages[activeLanguage].jobTypeAuxiliariesTranslations[index].saving">
                                <i class="fas fa-spinner fa-spin"></i> Saving JobType Auxiliary value ...
                            </div>
                            <div class="field_message success" v-show="content.languages[activeLanguage].jobTypeAuxiliariesTranslations[index].success">
                                <i class="fas fa-check"></i> JobType Auxiliary saved successfully!
                            </div>
                            <div class="field_message error" v-show="content.languages[activeLanguage].jobTypeAuxiliariesTranslations[index].error">
                                <i class="fas fa-times"></i> Something went wrong while saving this JobType Auxiliary. Please try again or contact the developer.
                            </div>
                        </div>
                    </div>
                    <div class="col-4 text-right pr-0">
                        <small>Translation word count: <code>{{ getWordCount('jobTypeAuxiliariesTranslations', content.languages[activeLanguage].jobTypeAuxiliariesTranslations[index].translationValue) }}</code></small>
                    </div>
                </div>
            </div>

            <!-- JobType Level Translations -->
            <div v-show="activeTab === 'job_type_level_translations'" class="alert alert-warning mt-2" role="alert">
                The JobType Level translations represents the title for each category level of Belfix main services.
                <br>Example: <a href="https://belfix.be/en/heating-engineer" target="_blank">https://belfix.be/en/heating-engineer</a> [<a href="/assets/Belfix/images/editor/job_type_level_translations_example_image_1.png" target="_blank"><i class="fas fa-image"></i> see example image</a>]
            </div>
            <div v-show="activeTab === 'job_type_level_translations'" class="m-0 p-0 text-right">
                <em><small>JobType Level Translations total word count:</small></em><code class="ml-1">{{ getWordCount('jobTypeLevelTranslations') }}</code>
            </div>
            <div v-show="activeTab === 'job_type_level_translations'"
                 class="job_type_level translation_contents form-group col-12 mt-3 mb-3 p-3"
                 v-for="jobTypeLevelTranslation in content.languages[activeLanguage].jobTypeLevelTranslations" :key="'job_type_level_translation_' + jobTypeLevelTranslation.id">
                <div class="col-12 template_value">
                    <div class="col-8 d-inline-block pl-0"><strong class="d-inline-block">EN:</strong><span class="d-inline-block">{{ content.languages[activeLanguage].jobTypeLevelTranslations[jobTypeLevelTranslation.id].labelTemplate }}</span></div>
                    <div class="col-4 d-inline-block float-right text-right translation_content_id">Level: <code>{{ jobTypeLevelTranslation.id }}</code></div>
                </div>
                <div class="col-12">
                    <label class="text-uppercase d-inline-block" :for="'job_type_level_translation_label_' + activeLanguage + '_' + jobTypeLevelTranslation.id">{{ activeLanguage }}:</label>
                    <input
                        :name="'job_type_level_translation_label_' + activeLanguage + '_' + jobTypeLevelTranslation.id"
                        type="text"
                        class="form-control d-inline-block"
                        @blur="updateTranslation('jobTypeLevelTranslations', activeLanguage, jobTypeLevelTranslation.id, content.languages[activeLanguage].jobTypeLevelTranslations[jobTypeLevelTranslation.id].label)"
                        v-model="content.languages[activeLanguage].jobTypeLevelTranslations[jobTypeLevelTranslation.id].label">
                </div>
                <div class="col-12 row">
                    <div class="col-8">
                        <div class="field_messages pt-2">
                            <div class="field_message saving" v-show="content.languages[activeLanguage].jobTypeLevelTranslations[jobTypeLevelTranslation.id].saving">
                                <i class="fas fa-spinner fa-spin"></i> Saving JobType Level label ...
                            </div>
                            <div class="field_message success" v-show="content.languages[activeLanguage].jobTypeLevelTranslations[jobTypeLevelTranslation.id].success">
                                <i class="fas fa-check"></i> JobType Level saved successfully!
                            </div>
                            <div class="field_message error" v-show="content.languages[activeLanguage].jobTypeLevelTranslations[jobTypeLevelTranslation.id].error">
                                <i class="fas fa-times"></i> Something went wrong while saving this JobType Level. Please try again or contact the developer.
                            </div>
                        </div>
                    </div>
                    <div class="col-4 text-right pr-0">
                        <small>Translation word count: <code>{{ getWordCount('jobTypeLevelTranslations', content.languages[activeLanguage].jobTypeLevelTranslations[jobTypeLevelTranslation.id].label) }}</code></small>
                    </div>
                </div>
            </div>

            <!-- Service Translations -->
            <div v-show="activeTab === 'service_translations'" class="alert alert-warning mt-2" role="alert">
                The Service translations represents the name of all services included in each main service provided by Belfix.
                <br>Example: <a href="https://belfix.be/en/heating-system-maintenance-oil-stove" target="_blank">https://belfix.be/en/heating-system-maintenance-oil-stove</a> [<a href="/assets/Belfix/images/editor/service_translations_example_image_1.png" target="_blank"><i class="fas fa-image"></i> see example image</a>]
            </div>
            <div v-show="activeTab === 'service_translations'" class="m-0 p-0 text-right">
                <em><small>Service Translations total word count:</small></em><code class="ml-1">{{ getWordCount('serviceTranslations') }}</code>
            </div>
            <div v-show="activeTab === 'service_translations'"
                 class="service translation_contents form-group col-12 mt-3 mb-3 p-3"
                 v-for="serviceTranslation in content.languages[activeLanguage].serviceTranslations" :key="'service_translation_' + serviceTranslation.id">
                <div class="col-12 template_value">
                    <div class="col-8 d-inline-block pl-0"><strong class="d-inline-block">EN:</strong><span class="d-inline-block">{{ content.languages[activeLanguage].serviceTranslations[serviceTranslation.id].nameTemplate }}</span></div>
                    <div class="col-4 d-inline-block float-right text-right translation_content_id">Service ID: <code>{{ serviceTranslation.id }}</code></div>
                </div>
                <div class="col-12">
                    <label class="text-uppercase d-inline-block" :for="'service_translation_name_' + activeLanguage + '_' + serviceTranslation.id">{{ activeLanguage }}:</label>
                    <input
                        :name="'service_translation_name_' + activeLanguage + '_' + serviceTranslation.id"
                        type="text"
                        class="form-control d-inline-block"
                        @blur="updateTranslation('serviceTranslations', activeLanguage, serviceTranslation.id, content.languages[activeLanguage].serviceTranslations[serviceTranslation.id].name)"
                        v-model="content.languages[activeLanguage].serviceTranslations[serviceTranslation.id].name">
                </div>
                <div class="col-12 row">
                    <div class="col-8">
                        <div class="field_messages pt-2">
                            <div class="field_message saving" v-show="content.languages[activeLanguage].serviceTranslations[serviceTranslation.id].saving">
                                <i class="fas fa-spinner fa-spin"></i> Saving Service name ...
                            </div>
                            <div class="field_message success" v-show="content.languages[activeLanguage].serviceTranslations[serviceTranslation.id].success">
                                <i class="fas fa-check"></i> Service saved successfully!
                            </div>
                            <div class="field_message error" v-show="content.languages[activeLanguage].serviceTranslations[serviceTranslation.id].error">
                                <i class="fas fa-times"></i> Something went wrong while saving this Service. Please try again or contact the developer.
                            </div>
                        </div>
                    </div>
                    <div class="col-4 text-right pr-0">
                        <small>Translation word count: <code>{{ getWordCount('serviceTranslations', content.languages[activeLanguage].serviceTranslations[serviceTranslation.id].name) }}</code></small>
                    </div>
                </div>
            </div>

            <!-- Recruitment Pro Skill Translations -->
            <div v-show="activeTab === 'recruitment_pro_skill_translations'" class="alert alert-warning mt-2" role="alert">
                The Recruitment Pro Skill translations represents the name of each type of skill that a person may select when filling our form to join our team of workers.
                <br>Page: <a :href="recruitmentProUrl" target="_blank">{{ recruitmentProUrl }}</a> [<a href="/assets/Belfix/images/editor/recruitment_pro_skill_translations_example_image_1.png" target="_blank"><i class="fas fa-image"></i> see example image</a>]
            </div>
            <div v-show="activeTab === 'recruitment_pro_skill_translations'" class="m-0 p-0 text-right">
                <em><small>Recruitment Pro Skill Translations total word count:</small></em><code class="ml-1">{{ getWordCount('recruitmentProSkillTranslations') }}</code>
            </div>
            <div v-show="activeTab === 'recruitment_pro_skill_translations'"
                 class="recruitment_pro_skill translation_contents form-group col-12 mt-3 mb-3 p-3"
                 v-for="recruitmentProSkillTranslation in content.languages[activeLanguage].recruitmentProSkillTranslations" :key="'recruitment_pro_skill_translation_' + recruitmentProSkillTranslation.id">
                <div class="col-12 template_value">
                    <div class="col-8 d-inline-block pl-0"><strong class="d-inline-block">EN:</strong><span class="d-inline-block">{{ content.languages[activeLanguage].recruitmentProSkillTranslations[recruitmentProSkillTranslation.id].nameTemplate }}</span></div>
                    <div class="col-4 d-inline-block float-right text-right translation_content_id">Recruitment Pro Skill ID: <code>{{ recruitmentProSkillTranslation.id }}</code></div>
                </div>
                <div class="col-12">
                    <label class="text-uppercase d-inline-block" :for="'recruitment_pro_skill_translation_name_' + activeLanguage + '_' + recruitmentProSkillTranslation.id">{{ activeLanguage }}:</label>
                    <input
                        :name="'recruitment_pro_skill_translation_name_' + activeLanguage + '_' + recruitmentProSkillTranslation.id"
                        type="text"
                        class="form-control d-inline-block"
                        @blur="updateTranslation('recruitmentProSkillTranslations', activeLanguage, recruitmentProSkillTranslation.id, content.languages[activeLanguage].recruitmentProSkillTranslations[recruitmentProSkillTranslation.id].name)"
                        v-model="content.languages[activeLanguage].recruitmentProSkillTranslations[recruitmentProSkillTranslation.id].name">
                </div>
                <div class="col-12 row">
                    <div class="col-8">
                        <div class="field_messages pt-2">
                            <div class="field_message saving" v-show="content.languages[activeLanguage].recruitmentProSkillTranslations[recruitmentProSkillTranslation.id].saving">
                                <i class="fas fa-spinner fa-spin"></i> Saving Recruitment Skill Pro name ...
                            </div>
                            <div class="field_message success" v-show="content.languages[activeLanguage].recruitmentProSkillTranslations[recruitmentProSkillTranslation.id].success">
                                <i class="fas fa-check"></i> Recruitment Skill Pro saved successfully!
                            </div>
                            <div class="field_message error" v-show="content.languages[activeLanguage].recruitmentProSkillTranslations[recruitmentProSkillTranslation.id].error">
                                <i class="fas fa-times"></i> Something went wrong while saving this Recruitment Skill Pro. Please try again or contact the developer.
                            </div>
                        </div>
                    </div>
                    <div class="col-4 text-right pr-0">
                        <small>Translation word count: <code>{{ getWordCount('recruitmentProSkillTranslations', content.languages[activeLanguage].recruitmentProSkillTranslations[recruitmentProSkillTranslation.id].name) }}</code></small>
                    </div>
                </div>
            </div>

            <!-- YML File Translations -->
            <div v-show="activeTab === 'yml_file_translations'" class="col-12 mt-3" role="alert">
                Current YML translation filename: <code>{{ content.websiteTranslationDomain }}.{{ activeLanguage }}.yml</code>
            </div>
            <div v-show="activeTab === 'yml_file_translations'" class="alert alert-warning mt-2" role="alert">
                <strong>VERY IMPORTANT:</strong> Do not translate anything inside the <strong>{ }</strong>, keep it original. Same thing with HTML tags. Keep the quotation marks <strong>"</strong>.
            </div>
            <div v-show="activeTab === 'yml_file_translations'" class="m-0 p-0 text-right">
                <em><small>YML File Translations total word count:</small></em><code class="ml-1">{{ getWordCount('ymlFileTranslations') }}</code>
            </div>
            <div v-show="activeTab === 'yml_file_translations'"
                 class="yml_file translation_contents form-group col-12 mt-3 mb-3 p-3"
                 :class="[{ 'comment' : typeof ymlFileTranslationValue !== 'object' }]"
                 v-for="(ymlFileTranslationValue, index) in content.languages[activeLanguage].ymlFileTranslations" :key="'yml_file_translation_' + index">
                <template v-if="typeof ymlFileTranslationValue === 'object'">
                    <div class="col-12 template_value">
                        <div class="col-8 d-inline-block pl-0"><strong class="d-inline-block">EN:</strong><span class="d-inline-block">{{ content.ymlTemplateFileTranslationsByKey[ymlFileTranslationValue.key] }}</span></div>
                        <div class="col-4 d-inline-block float-right text-right translation_content_id"><code>{{ ymlFileTranslationValue.key }}</code></div>
                    </div>
                    <div class="col-12">
                        <label class="text-uppercase d-inline-block" :for="'yml_file_translation_' + activeLanguage + '_' + ymlFileTranslationValue.key">{{ activeLanguage }}:</label>
                        <input
                            :name="'yml_file_translation_' + activeLanguage + '_' + ymlFileTranslationValue.key"
                            type="text"
                            class="form-control d-inline-block"
                            @blur="updateTranslation('ymlFileTranslations', activeLanguage, index, content.languages[activeLanguage].ymlFileTranslations)"
                            v-model="content.languages[activeLanguage].ymlFileTranslations[index].value">
                    </div>
                    <div class="col-12 row">
                        <div class="col-8">
                            <div class="field_messages pt-2">
                                <div class="field_message saving" v-show="content.languages[activeLanguage].ymlFileTranslations[index].saving">
                                    <i class="fas fa-spinner fa-spin"></i> Saving Yml File translations ...
                                </div>
                                <div class="field_message success" v-show="content.languages[activeLanguage].ymlFileTranslations[index].success">
                                    <i class="fas fa-check"></i> Yml File saved successfully!
                                </div>
                                <div class="field_message error" v-show="content.languages[activeLanguage].ymlFileTranslations[index].error">
                                    <i class="fas fa-times"></i> Something went wrong while saving the Yml File. Please try again or contact the developer.
                                </div>
                            </div>
                        </div>
                        <div class="col-4 text-right pr-0">
                            <small>Translation word count: <code>{{ getWordCount('ymlFileTranslations', content.languages[activeLanguage].ymlFileTranslations[index].value) }}</code></small>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <h4 v-html="getYmlTitle(ymlFileTranslationValue)"></h4>
                </template>
            </div>

            <!-- Legal Content Translations -->
            <div v-show="activeTab === 'legal_translations'" class="alert alert-warning mt-2" role="alert">
                Here you can translate the legal page content. Don't change the HTML tags, unless strictly necessary.
                <br>Page: <a :href="legalUrl" target="_blank">{{ legalUrl }}</a>
            </div>
            <div v-show="activeTab === 'legal_translations'" class="m-0 p-0 text-right">
                <em><small>Legal page translation total word count:</small></em><code class="ml-1">{{ getWordCount('legalTranslation') }}</code>
            </div>
            <div v-show="activeTab === 'legal_translations'"
                 class="legal_content translation_contents form-group col-12 mt-3 mb-3 pt-2 pb-2 pl-0 pr-0">
                <div class="col-12">
                    <textarea
                        :name="'legal_content_translation_' + activeLanguage"
                        class="form-control d-inline-block"
                        rows="25"
                        @blur="updateTranslation('legalTranslation', activeLanguage, null, content.languages[activeLanguage].legalTranslation.value)"
                        :disabled="content.languages[activeLanguage].legalTranslation.saving"
                        v-model="content.languages[activeLanguage].legalTranslation.value"></textarea>
                </div>
                <div class="col-12">
                    <div class="field_messages pt-2">
                        <div class="field_message saving" v-show="content.languages[activeLanguage].legalTranslation.saving">
                            <i class="fas fa-spinner fa-spin"></i> Saving the Legal content ...
                        </div>
                        <div class="field_message success" v-show="content.languages[activeLanguage].legalTranslation.success">
                            <i class="fas fa-check"></i> Legal content saved successfully!
                        </div>
                        <div class="field_message error" v-show="content.languages[activeLanguage].legalTranslation.error">
                            <i class="fas fa-times"></i> Something went wrong while saving this Legal content. Please try again or contact the developer.
                        </div>
                    </div>
                </div>
            </div>

            <!-- Privacy Policy Content Translations -->
            <div v-show="activeTab === 'privacy_policy_translations'" class="alert alert-warning mt-2" role="alert">
                Here you can translate the privacy policy page content. Don't change the HTML tags, unless strictly necessary.
                <br>Page: <a :href="privacyPolicyUrl" target="_blank">{{ privacyPolicyUrl }}</a>
            </div>
            <div v-show="activeTab === 'privacy_policy_translations'" class="m-0 p-0 text-right">
                <em><small>Privacy Policy page translation total word count:</small></em><code class="ml-1">{{ getWordCount('privacyPolicyTranslation') }}</code>
            </div>
            <div v-show="activeTab === 'privacy_policy_translations'"
                 class="privacy_policy_content translation_contents form-group col-12 mt-3 mb-3 pt-2 pb-2 pl-0 pr-0">
                <div class="col-12">
                    <textarea
                        :name="'privacy_policy_content_translation_' + activeLanguage"
                        class="form-control d-inline-block"
                        rows="25"
                        @blur="updateTranslation('privacyPolicyTranslation', activeLanguage, null, content.languages[activeLanguage].privacyPolicyTranslation.value)"
                        :disabled="content.languages[activeLanguage].privacyPolicyTranslation.saving"
                        v-model="content.languages[activeLanguage].privacyPolicyTranslation.value"></textarea>
                </div>
                <div class="col-12">
                    <div class="field_messages pt-2">
                        <div class="field_message saving" v-show="content.languages[activeLanguage].privacyPolicyTranslation.saving">
                            <i class="fas fa-spinner fa-spin"></i> Saving the Privacy Policy content ...
                        </div>
                        <div class="field_message success" v-show="content.languages[activeLanguage].privacyPolicyTranslation.success">
                            <i class="fas fa-check"></i> Privacy Policy content saved successfully!
                        </div>
                        <div class="field_message error" v-show="content.languages[activeLanguage].privacyPolicyTranslation.error">
                            <i class="fas fa-times"></i> Something went wrong while saving this Privacy Policy content. Please try again or contact the developer.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from '../../modules/Api';
import Routing from '../../modules/BackendRouter';

export default {
    props: {
    },
    data() {
        return {
            activeLanguage: this.$content.defaultLanguage,
            activeTab: 'job_type_translations',
            content: this.$content,
            clearingCaches: false,
            cachesCleared: false,
            jobTypeAutoCreateBrokenRoute: true,
        }
    },
    async created() {
    },
    mounted() {
    },
    methods: {
        async updateTranslation(type, languageCode, id, newValue)
        {
            if (id === null) {
                this.content.languages[languageCode][type].saving = true;
                clearTimeout(this.content.languages[languageCode][type].error);
                clearTimeout(this.content.languages[languageCode][type].success);
                this.content.languages[languageCode][type].error = false;
                this.content.languages[languageCode][type].success = false;
            } else {
                this.content.languages[languageCode][type][id].saving = true;
                clearTimeout(this.content.languages[languageCode][type][id].error);
                clearTimeout(this.content.languages[languageCode][type][id].success);
                this.content.languages[languageCode][type][id].error = false;
                this.content.languages[languageCode][type][id].success = false;
            }

            let error = false;

            try {
                switch (type) {
                    case 'jobTypeTranslations':
                        await Api.updateJobTypeNameTranslation(languageCode, id, newValue, this.jobTypeAutoCreateBrokenRoute);
                        break;
                    case 'jobTypeAuxiliariesTranslations':
                        await Api.updateJobTypeAuxiliariesTranslation(languageCode, this.content.languages[languageCode].jobTypeAuxiliariesTranslations[id].originalValue, newValue);
                        break;
                    case 'jobTypeLevelTranslations':
                        await Api.updateJobTypeLevelTranslation(languageCode, id, newValue);
                        break;
                    case 'serviceTranslations':
                        await Api.updateServiceTranslation(languageCode, id, newValue);
                        break;
                    case 'recruitmentProSkillTranslations':
                        await Api.updateRecruitmentProSkillTranslation(languageCode, id, newValue);
                        break;
                    case 'ymlFileTranslations':
                        await Api.updateYmlFileTranslations(languageCode, newValue);
                        break;
                    case 'legalTranslation':
                        await Api.updateLegalTranslations(languageCode, newValue);
                        break;
                    case 'privacyPolicyTranslation':
                        await Api.updatePrivacyPolicyTranslations(languageCode, newValue);
                        break;
                }
            } catch {
                error = true;
                if (id === null) {
                    this.content.languages[languageCode][type].saving = false;
                    this.content.languages[languageCode][type].error = setTimeout(() =>{
                        this.content.languages[languageCode][type].error = false;
                    }, 10000);
                } else {
                    this.content.languages[languageCode][type][id].saving = false;
                    this.content.languages[languageCode][type][id].error = setTimeout(() =>{
                        this.content.languages[languageCode][type][id].error = false;
                    }, 10000);
                }
            }

            if (!error) {
                if (type === 'jobTypeAuxiliariesTranslations') {
                    this.content.languages[languageCode].jobTypeAuxiliariesTranslations[id].originalValue = newValue;
                }
                if (id === null) {
                    this.content.languages[languageCode][type].saving = false;
                    this.content.languages[languageCode][type].success = setTimeout(() =>{
                        this.content.languages[languageCode][type].success = false;
                    }, 5000);
                } else {
                    this.content.languages[languageCode][type][id].saving = false;
                    this.content.languages[languageCode][type][id].success = setTimeout(() =>{
                        this.content.languages[languageCode][type][id].success = false;
                    }, 5000);
                }
            }
        },
        async clearAllCaches()
        {
            let confirmMessage = 'Are you sure you want to clear all the website caches? This action may take a while.';
            if (!this.clearingCaches && !this.cachesCleared && confirm(confirmMessage)) {
                this.clearingCaches = true;
                await Api.clearAllCaches();
                this.clearingCaches = false;
                this.cachesCleared = true;
                setTimeout(() => {
                    this.cachesCleared = false;
                }, 5000);
            }
        },
        getYmlTitle(ymlFileTranslationValue)
        {
            // Checks if a [url] tag is present, formatting it into an html <a>
            if (ymlFileTranslationValue.indexOf('http') !== -1) {
                let url = ymlFileTranslationValue.substring(
                    ymlFileTranslationValue.lastIndexOf('[url]') + 5,
                    ymlFileTranslationValue.lastIndexOf('[/url]')
                );
                ymlFileTranslationValue = ymlFileTranslationValue
                    .replace('[url]', '<a href="' + url + '" target="_blank">')
                    .replace('[/url]', '</a>')
                ;
            }

            return ymlFileTranslationValue;
        },
        visitWebsite(languageCode)
        {
            window.open(this.content.currentWebsiteUrl + '/' + languageCode + '/','_blank');
        },
        getWordCount(section = null, string = null) {
            let count = 0;

            if (string) {
                switch (true) {
                    case (section === 'jobTypeTranslations' || section === 'jobTypeLevelTranslations' || section === 'serviceTranslations' || section === 'recruitmentProSkillTranslations'):
                        count = string.replace(/\s+/g, ' ').trim().split(" ").length;
                        break;
                    case (section === 'legalTranslation' || section === 'privacyPolicyTranslation' || section === null):
                        count = string.replace( /(<([^>]+)>)/ig, '').replace(/\s+/g, ' ').trim().split(" ").length;
                        break;
                    case (section === 'jobTypeAuxiliariesTranslations' || section === 'ymlFileTranslations'):
                        count = string.replace( /(<([^>]+)>)/ig, '').replace(/{.*}/, '').replace(/\s+/g, ' ').trim().split(" ").length;
                        break;
                }
            } else {
                if (section === 'jobTypeTranslations' || section === null) {
                    for (let translation in this.content.languages[this.activeLanguage].jobTypeTranslations) {
                        count += this.content.languages[this.activeLanguage].jobTypeTranslations[translation].name.replace(/\s+/g, ' ').trim().split(" ").length;
                    }
                }

                if (section === 'jobTypeAuxiliariesTranslations' || section === null) {
                    for (let translation in this.content.languages[this.activeLanguage].jobTypeAuxiliariesTranslations) {
                        count += this.content.languages[this.activeLanguage].jobTypeAuxiliariesTranslations[translation].translationValue.replace( /(<([^>]+)>)/ig, '').replace(/{.*}/, '').replace(/\s+/g, ' ').trim().split(" ").length;
                    }
                }

                if (section === 'jobTypeLevelTranslations' || section === null) {
                    for (let translation in this.content.languages[this.activeLanguage].jobTypeLevelTranslations) {
                        count += this.content.languages[this.activeLanguage].jobTypeLevelTranslations[translation].label.replace(/\s+/g, ' ').trim().split(" ").length;
                    }
                }

                if (section === 'serviceTranslations' || section === null) {
                    for (let translation in this.content.languages[this.activeLanguage].serviceTranslations) {
                        count += this.content.languages[this.activeLanguage].serviceTranslations[translation].name.replace(/\s+/g, ' ').trim().split(" ").length;
                    }
                }

                if (section === 'recruitmentProSkillTranslations' || section === null) {
                    for (let translation in this.content.languages[this.activeLanguage].recruitmentProSkillTranslations) {
                        count += this.content.languages[this.activeLanguage].recruitmentProSkillTranslations[translation].name.replace(/\s+/g, ' ').trim().split(" ").length;
                    }
                }

                if (section === 'ymlFileTranslations' || section === null) {
                    for (let translation in this.content.languages[this.activeLanguage].ymlFileTranslations) {
                        if (typeof this.content.languages[this.activeLanguage].ymlFileTranslations[translation] === 'object') {
                            count += this.content.languages[this.activeLanguage].ymlFileTranslations[translation].value.replace( /(<([^>]+)>)/ig, '').replace(/{.*}/, '').replace(/\s+/g, ' ').trim().split(" ").length;
                        }
                    }
                }

                if (section === 'legalTranslation' || section === null) {
                    count += this.content.languages[this.activeLanguage].legalTranslation.value.replace( /(<([^>]+)>)/ig, '').replace(/\s+/g, ' ').trim().split(" ").length;
                }

                if (section === 'privacyPolicyTranslation' || section === null) {
                    count += this.content.languages[this.activeLanguage].privacyPolicyTranslation.value.replace( /(<([^>]+)>)/ig, '').replace(/\s+/g, ' ').trim().split(" ").length;
                }
            }

            return count;
        }
    },
    computed: {
        recruitmentProUrl()
        {
            return this.content.currentWebsiteUrl + Routing.generate('recruitment_pro.' + this.activeLanguage);
        },
        legalUrl()
        {
            return this.content.currentWebsiteUrl + Routing.generate('legal.' + this.activeLanguage);
        },
        privacyPolicyUrl()
        {
            return this.content.currentWebsiteUrl + Routing.generate('privacy_policy.' + this.activeLanguage);
        },
    }

}
</script>
