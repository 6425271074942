export default class
{
    requestTokenSource = {};

    constructor(axios) {
        this.axios = axios;
    }

    /*
     * parameters:
     *    method,
     *    url,
     *    postData,
     *    responseDataValidator,
     *    responseDataSelector,
     *    axiosOptions,
     *    cancelingPrevious,
     *    requestName
     */
    async request(parameters)
    {
        // -- MAIN OPTIONS
        // TODO PIERRE: better parameters management...
        const axiosOptions = {
            method: parameters.method,
            url: parameters.url,
            data: parameters.postData,
        };

        // -- EXTRA OPTIONS
        // Fuses any extra axios options set in the parameters
        if (typeof parameters.axiosOptions !== 'undefined') {
            Object.assign(axiosOptions, parameters.axiosOptions);
        }

        // -- CANCELING PREVIOUS (SAME REQUEST)
        // If set as true in the parameters, cancels any ongoing axios request with the same requestName
        // Note: If no requestName is set in the parameters, then c'est la vie...
        if (parameters.cancelingPrevious === true) {
            const requestTokenSource = this.cancelOngoingRequest(parameters.requestName);
            axiosOptions.cancelToken = requestTokenSource.token;
        }

        // -- REQUEST
        const response = await this.axios(axiosOptions);

        // -- VALIDATION
        // If no responseValidator is set in the parameters, by default it's made a check for the success
        // parameter in the response data
        // If there's a responseValidator set in the parameters, and if yes it executes the custom validator
        let responseDataValidator = parameters.responseDataValidator;
        if (typeof responseDataValidator === 'undefined') {
            responseDataValidator = (responseData) => responseData.success === true;
        }
        if (!responseDataValidator(response.data)) {
            throw new Error('invalid api response: ' + JSON.stringify(response)); // Not valid
        }

        // RESPONSE DATA
        // Returns the response data to a defined responseDataSelector in the parameters
        // If no responseDataSelector is defined in the parameters, it does not return the response data
        if (typeof parameters.responseDataSelector !== 'undefined') {
            return parameters.responseDataSelector(response.data);
        }
    }

    cancelOngoingRequest(requestName)
    {
        if (this.requestTokenSource[requestName] !== undefined) {
            this.requestTokenSource[requestName].cancel('request canceled programmatically');
        }
        const source = this.axios.CancelToken.source();
        this.requestTokenSource[requestName] = source;
        return source;
    }

    isCancel(error)
    {
        return this.axios.isCancel(error);
    }
}