<template>
    <div>
        <div id="checkout-schedule-cart" :class="['text-left', { 'open' : showCartInfo }]">
            <div class="book-online-pay-on-the-spot-top col-md-12 text-right text-white">
                {{ $t('book_online_pay_on_the_spot') }}
            </div>
            <div id="checkout-schedule-cart-wrapper" class="col-md-12">
                <button @click="openCart">
                    <i :class="['fas', { 'fa-angle-down' : !showCartInfo, 'fa-angle-up': showCartInfo }]"></i>
                </button>
                <h3>{{ $content.jobTypePathTitle }}</h3>
                <p>
                    <span id="cart_price_caption">
                        <template v-if="$content.jobTypePriceCaption">
                            {{ $content.jobTypePriceCaption }}
                        </template>
                        <template v-else>
                            {{ $t('from') }}
                        </template>
                    </span>
                    <span id="cart_price_value">
                        {{ getPriceCurrencyFormatted($content.jobTypePrice) }}
                    </span>
                    <span id="cart_selected_price_value"></span>
                    <span id="cart_supplement" class="mr-2"></span>
                    <span id="cart_discount" class="mr-2"></span>
                    <span id="cart_special_discount" class="mr-2"></span>
                </p>
                <div class="cart-icon">
                    <i class="fas fa-shopping-basket"></i>
                </div>
            </div>
        </div>
        <div id="checkout-schedule-cart-info" v-if="showCartInfo">
            <div class="job-type-services p-0">
                <h5>{{ $t('the_price_includes')}}:</h5>
                <ul>
                    <li v-for="service in $content.services" :key="service.serviceId">
                        <img :src="service.serviceIcon" :alt="service.serviceName"> <p>{{ service.serviceName }}</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="book-online-pay-on-the-spot-bottom col-md-12 text-right text-white">
            {{ $t('book_online_pay_on_the_spot') }}
        </div>
    </div>
</template>
<script>
    export default {
        props: {},
        data() {
            return {
                showCartInfo: false,
                appElem: null,
            }
        },
        created() {
        },
        methods: {
            openCart()
            {
                this.showCartInfo = !this.showCartInfo
                if (this.appElem === null) {
                    this.appElem = document.querySelector('#checkout_schedule > #app');
                }
                this.appElem.style.zIndex = (this.showCartInfo) ? 9 : 999;
            },
            getPriceCurrencyFormatted(priceValue) {
                if (this.$content.websiteCurrencyPosition === 'left') {
                    return this.$content.websiteCurrency + priceValue;
                }
                return priceValue + this.$content.websiteCurrency;
            },
        }
    }
</script>
