<template>
    <div id="content-container" class="colorlib-services pt-2">
        <div class="container">
            <div class="row row-pb-lg">
                <div :class="['post-code-wrapper', 'col-md-12', 'animated', 'fast', 'fadeInUp', 'p-3', {'with-slots' : suggestSlots.length}]">
                    <template v-if="!showSuccessMessage && !showAppointmentLoading && !showFailMessage && !showRequireEmailValidation">
                        <transition class="col-md-12" name="fade" tag="div">
                            <div v-if="selectedSlot === null" class="container" v-show="!$content.editMode">
                                <div class="zip_code row">
                                    <div class="col-md-12 text-center pb-2" v-show="suggestSlots.length === 0">{{ $t('postal_code_input_description') }}</div>
                                    <div class="col-sm-12 col-md-6">
                                        <input v-model="postCodeFieldQuery"
                                               @input="searchPostCodeDebounced"
                                               @focus="postCodeFieldFocus = true"
                                               @keyup.enter="selectPostCode()"
                                               @keyup.up="moveThroughSuggestedPostCodes"
                                               @keyup.down="moveThroughSuggestedPostCodes"
                                               @blur="focusOutPostCodeField"
                                               ref="zipCodeInputField"
                                               class="form-control form-control-lg float-sm-none float-md-right"
                                               type="text"
                                               :disabled="loadingSlots === true"
                                               :placeholder="$t('postal_code_input_placeholder')">
                                        <i class="post-code-loader fas fa-spinner fa-spin" v-show="searchPostCodeLoading"></i>
                                        <div v-show="postCodeSuggestions.length && postCodeFieldFocus"
                                             ref="postCodeSuggestionsContainer"
                                             class="post_code_suggestions text-dark animated fast fadeInUp">
                                            <div v-for="(postCodeSuggestion, index) in postCodeSuggestions"
                                                 :key="postCodeSuggestion.id"
                                                 @click="selectPostCode(postCodeSuggestion, (index + 1))"
                                                 :class="{'selected' : (selectedSuggestedPostCodeIndex === (index + 1))}"
                                                 class="post_code_suggestion p-2">
                                                {{ postCodeSuggestion.post_code }} {{ postCodeSuggestion.commune_name }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-6">
                                        <button @click="selectPostCode()"
                                                :disabled="disablePostCodeBtn || loadingSlots"
                                                type="button"
                                                class="btn btn-custom-blue btn-lg btn-block">{{ $t('find_an_appointment') }}</button>
                                    </div>
                                    <div v-show="badZipCode"
                                         class="col-md-12 mt-3 alert alert-warning"
                                         role="alert">
                                        {{ $t('incorrect_post_code') }}
                                    </div>
                                    <div v-show="zipCodeSearchUnknownError"
                                         class="col-md-12 mt-3 alert alert-warning"
                                         role="alert">
                                        {{ $t('find_an_appointment_error') }}
                                    </div>
                                </div>
                            </div>
                        </transition>

                        <div v-if="$content.editMode && (($content.jobInfo.continuationOrWarrantyJob && !selectedSlot) || !$content.jobInfo.continuationOrWarrantyJob)"
                             v-show="suggestSlots.length"
                             class="col-md-12 alert alert-info"
                             role="contentinfo">
                            <i class="fas fa-info-circle mr-1"></i>
                            <template v-if="$content.jobInfo.continuationOrWarrantyJob || !$content.specialDiscountValue">
                                {{ $t('dear_customer', { customer_name: customerInfo.firstname.value}) }}, {{ $t('please_choose_a_timing_below').toLowerCase() }}:
                            </template>
                            <template v-else>
                                <span v-html="$t('edit_impossible_appointment_intro', { customer_name: customerInfo.firstname.value, special_discount_value: $content.specialDiscountValue })" class="mr-1"></span>
                                <template v-if="selectedSlot === null">{{ $t('please_choose_a_timing_below') }}:</template>
                            </template>
                        </div>

                        <div v-if="winners.price"
                             v-show="!cheapestPriceIsKept && selectedSlot === null"
                             class="col-md-12 alert alert-warning"
                             role="alert"
                        >
                            <span v-html="$t('cheapest_price_not_available', { post_code_and_commune: selectedPostCodeFieldQuery, agreed_price : winners.price.agreed_price })"></span>
                        </div>

                        <div v-if="$content.jobTypeWarning"
                             class="col-md-12 alert alert-warning fast fadeInUp p-3 animated"
                             role="alert"
                        >
                            <span v-html="$content.jobTypeWarning"></span>
                        </div>

                        <div v-show="loadingSlots"
                             class="loading row">
                            <div class="col-md-12 text-center">
                                <i class="fas fa-spinner fa-spin"></i>
                            </div>
                        </div>

                        <div class="schedules row">
                            <div v-show="noSlotsFound"
                                 class="col-md-12 alert alert-warning"
                                 role="alert">
                                {{ $t('no_appointments_found') }}
                            </div>
                                <div :class="['slots-header-order', 'col-md-12', 'mb-2', 'text-center','padding-0', {'backgoundWhiteSmoke' : selectedSlot === null }]"
                                     :key="'slots-header-order'"
                                     v-show="selectedSlot === null && suggestSlots.length">

                                    <div class="col-md-12">
                                        <div class="row backgroundWhiteSmoke">
                                        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-7 p_header_triplet">
                                            <div class="row container_schedule_header">
                                                <div
                                                        id="timestamp-title"
                                                        :class="['slots-header-order-time', 'col-4','text-left', 'ptb_5', {'border_r_whitesmoke' :  suggestSlotsOrderSelected === 'price'}, {'cell_schedule_header_active' : suggestSlotsOrderSelected === 'timestamp'}, 'border-active']"
                                                        @click="suggestSlotsOrderSelected = 'timestamp'">

                                                    <span class="fs_14">
                                                        <img v-if="suggestSlotsOrderSelected === 'timestamp'" src="/assets/Belfix/images/truck-white.png" class="f-left l--5px icon_truck_schedule icon_triplet_schedule d-inline-block d-md-none d-lg-inline-block" alt="Belfix Truck">
                                                        <img v-else src="/assets/Belfix/images/truck-blue.png" class="f-left l--5px icon_truck_schedule d-inline-block d-md-none icon_triplet_schedule d-lg-inline-block" alt="Belfix Truck">
                                                        {{ $t('the_faster') }}
                                                    </span>
                                                    <span class="schedule_header_price" v-if="winners.timestamp"> {{ getPriceCurrencyFormatted(winners.timestamp.agreed_price) }}</span>
                                                    <span class="schedule_header_desc" v-if="winners.timestamp">
                                                        <span class="d-none d-lg-inline-block">
                                                            <template v-if="winners.timestamp.isToday">{{ $t('today') }} </template>
                                                            <template v-else-if="winners.timestamp.isTomorrow">{{ $t('tomorrow') }} </template>
                                                            <template v-else>{{ $moment(winners.timestamp.date).format('dddd') }} </template>
                                                            <span class="d-xs-none d-sm-none d-md-inline-block d-lg-inline-block">{{ $moment(winners.timestamp.date).format('D MMMM').toLowerCase() }}</span>
                                                        </span>
                                                        <span class="d-inline d-lg-none">{{ $moment(winners.timestamp.date).format('D MMMM').toLowerCase().replace('.', '') }}</span>
                                                    </span>
                                                </div>
                                                <div
                                                        id="rating-title"
                                                        :class="['slots-header-order-date', 'col-4', 'text-left', 'ptb_5', {'border_r_whitesmoke' :  suggestSlotsOrderSelected !== 'price'}, {'cell_schedule_header_active' : suggestSlotsOrderSelected === 'score'}]"
                                                        @click="suggestSlotsOrderSelected = 'score'">
                                                    <span class="fs_14"><i class="fas fa-medal schedule_header_title d-inline-block d-md-none icon_triplet_schedule d-lg-inline-block"></i> {{ $t('the_best') }}</span>
                                                    <span class="schedule_header_price" v-if="winners.rating"> {{ getPriceCurrencyFormatted(winners.rating.agreed_price) }}</span>
                                                    <span class="schedule_header_desc" v-if="winners.rating">
                                                        <span class="d-none d-lg-inline-block">
                                                            <template v-if="winners.rating.isToday">{{ $t('today') }} </template>
                                                            <template v-else-if="winners.rating.isTomorrow">{{ $t('tomorrow') }} </template>
                                                            <template v-else>{{ $moment(winners.rating.date).format('dddd') }} </template>
                                                            <span class="d-xs-none d-sm-none d-md-inline-block d-lg-inline-block">{{ $moment(winners.rating.date).format('D MMMM').toLowerCase() }}</span>
                                                        </span>
                                                        <span class="d-inline d-lg-none">{{ $moment(winners.rating.date).format('D MMMM').toLowerCase().replace('.', '') }}</span>
                                                    </span>
                                                </div>
                                                <div id="price-title"
                                                     class=""
                                                     :class="['slots-header-order-score', 'col-4','text-left', 'ptb_5', {'cell_schedule_header_active' : suggestSlotsOrderSelected === 'price'}, 'border-r-active']"
                                                     @click="suggestSlotsOrderSelected = 'price'"
                                                     @mouseenter="closeTooltip" >
                                                    <span class="fs_14"><i class="fas fa-euro-sign schedule_header_title d-inline-block d-md-none icon_triplet_schedule d-lg-inline-block"></i>  {{ $t('the_cheapest') }}</span>
                                                    <span class="schedule_header_price" v-if="winners.price"> {{ getPriceCurrencyFormatted(winners.price.agreed_price) }}</span>
                                                    <span class="schedule_header_desc" v-if="winners.price">
                                                        <span class="d-none d-lg-inline-block">
                                                            <template v-if="winners.price.isToday">{{ $t('today') }} </template>
                                                            <template v-else-if="winners.price.isTomorrow">{{ $t('tomorrow') }} </template>
                                                            <template v-else>{{ $moment(winners.price.date).format('dddd') }} </template>
                                                            <span class="d-xs-none d-sm-none d-md-inline-block d-lg-inline-block">{{ $moment(winners.price.date).format('D MMMM').toLowerCase() }}</span>
                                                        </span>
                                                        <span class="d-inline d-lg-none">{{ $moment(winners.rating.date).format('D MMMM').toLowerCase().replace('.', '') }}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xs-12 col-sm-12 offset-md-2 col-md-2 offset-lg-3 container_header_selecteur">
                                            <div class="row">
                                                <div id="order-title"
                                                     :class="['container_schedule_header', 'col-12', 'slots-header-order-date', 'text-left', 'ptb_5', {'border_r_whitesmoke' :  suggestSlotsOrderSelected === 'price'}, 'pb-0']"
                                                     >
                                                    <span class="fs_14 schedule_header_title w-100 container_multiselect">
                                                        <i class="fas fa-sort-amount-down sort_icon"></i>

                                                        <multiselect
                                                                :value="suggestSlotsOrders.find(order => order.value === suggestSlotsOrderSelected)"
                                                                @input="suggestSlotsOrderSelected = $event.value"
                                                                :options="suggestSlotsOrders"
                                                                :searchable="false"
                                                                :allowEmpty="false"
                                                                :placeholder="'Trier par'"
                                                                :allow-empty="false"
                                                                :show-labels="false"
                                                                :preselectFirst="false"
                                                                open-direction="bottom"
                                                                :showPointer="true"
                                                                track-by="name"
                                                                label="name"
                                                                :class="['multiselect-custom-wrapper-class']">
                                                            <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                                                        </multiselect>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12 text-center"
                                     v-show="(isSlotsDisplayed && (currentMaxNumScheduleDisplay - totalScheduleDisplay) > 0) && selectedSlot === null"
                                     :key="'seePreviousAppointments'">
                                    <button @click="showPreviousSlots"
                                            id="see-previous-appointments"
                                            type="button"
                                            class="col-md-6 d-inline-block btn btn-custom-blue btn-lg btn-block mb-2">{{ $t('see_previous_appointments') }}</button>
                                </div>

                                <div class="col-md-12 slots-fader-down"
                                     v-show="(isSlotsDisplayed && (currentMaxNumScheduleDisplay - totalScheduleDisplay) > 0) && selectedSlot === null && suggestSlots.length > 5"
                                     :key="'slotsTopFader'"></div>
                            <transition-group :class="['col-md-12', {'d-none': suggestSlots.length === 0, 'minHeight': selectedSlot === null}]" name="schedule-transition" tag="div">
                                <div v-for="(schedule, index) in suggestSlots" :key="schedule.timestamp + '-' + (schedule.worker_id ? schedule.worker_id : 0)">
                                    <div v-if="isSlotsDisplayed && canSlotBeDisplayed(schedule, index)"
                                         class="slot col-md-12 mb-2 text-center"
                                         :class="[{'selected' : (selectedSlot === index), 'reversed' : reversedSlotsAnimation}]"
                                         @click="selectSlot(index)">
                                        <div class="row">
                                            <div class="slot-worker-photo text-center">
                                                <template v-if="schedule.worker_id && workersInfo[schedule.worker_id].companyLogo !== '' ">
                                                    <img :src="'/images/worker_logo/' + workersInfo[schedule.worker_id].companyLogo" alt="Worker Photo">
                                                </template>
                                                <template v-else>
                                                    <img src="/assets/Belfix/images/worker_placeholder.png" alt="Worker Photo">
                                                </template>
                                                <strong class="worker-name d-inline-block d-md-none">
                                                    <template v-if="schedule.isSlotPureContact">
                                                        <span class="nobr">{{ workersInfo[schedule.worker_id].companyName | trimStringByXLength(18, true) }}</span>
                                                    </template>
                                                    <template v-else>
                                                        <span class="nobr">{{ schedule.worker_pseudo }}</span>
                                                    </template>
                                                </strong>
                                                <span class="worker-review-score d-inline d-md-none">{{ getReviewScore(schedule) }}</span>
                                            </div>
                                            <div class="slot-worker-info text-center text-md-left d-none d-md-table-cell">
                                                <strong class="worker-name d-table-cell">
                                                    <template v-if="schedule.isSlotPureContact">
                                                        <span class="nobr">{{ workersInfo[schedule.worker_id].companyName | trimStringByXLength(18, true) }}</span>
                                                    </template>
                                                    <template v-else>
                                                        <span class="nobr">{{ schedule.worker_pseudo }}</span>
                                                    </template>
                                                </strong>
                                                <span class="worker-review-score">{{ getReviewScore(schedule) }}</span>
                                                <span class="worker-review-label d-none d-md-inline">
                                                            <span class="nobr">
                                                                <template v-if="getReviewScore(schedule) > 9">
                                                                    {{ $t('checkout_excellent') }}
                                                                </template>
                                                                <template v-else-if="getReviewScore(schedule) > 7.5">
                                                                    {{ $t('checkout_very_good') }}
                                                                </template>
                                                                <template v-else-if="getReviewScore(schedule) > 5">
                                                                    {{ $t('checkout_good') }}
                                                                </template>
                                                            </span>
                                                        </span>
                                                <template v-if="schedule.worker_id">
                                                    <span :id="'worker-has-language-conflict-slot-' + index"
                                                          class="worker-has-language-conflict d-none d-md-block"
                                                          v-if="workersInfo[schedule.worker_id].workerLanguages.indexOf($locale) === -1">
                                                            <i class="fas fa-exclamation-circle"></i> {{ $t('language_conflict') }}
                                                            <b-tooltip placement="top"
                                                                       ref="worker-has-language-conflict-tooltip"
                                                                       :target="'worker-has-language-conflict-slot-' + index"
                                                                       triggers="hover"
                                                                       :delay="{ 'hide': 50 }">
                                                            <div class="help_price_tooltip-container language-conflict-tooltip-container text-left">
                                                                <p></p>
                                                                <p>{{ $t('language_conflict_description') }}: <strong>{{ retrieveNiceTranslatedLanguages(workersInfo[schedule.worker_id].workerLanguages) }}</strong></p>
                                                            </div>
                                                        </b-tooltip>
                                                </span>
                                                </template>
                                            </div>
                                            <div class="slot-date">
                                                <template v-if="schedule.isToday"><span class="day today">{{ $t('today') }}</span></template>
                                                <template v-else-if="schedule.isTomorrow"><span class="day tomorrow">{{ $t('tomorrow') }}</span></template>
                                                <template v-else><span class="day">{{ $moment(schedule.date).format('dddd') }}</span></template>
                                                <br>
                                                <span class="d-none d-md-inline">{{ $moment(schedule.date).format('D MMMM').toLowerCase() }}</span>
                                                <span class="d-inline d-md-none">{{ $moment(schedule.date).format('D MMM').toLowerCase().replace('.', '') }}</span>
                                            </div>
                                            <div class="slot-time pl-md-0 text-center">
                                                {{ schedule.proper_time_format }}
                                                <template v-if="selectedSlot === index">
                                                    <span class="d-none d-md-inline"><i class="fas fa-caret-right"></i> {{ schedule.two_hours_later }}</span>
                                                </template>
                                            </div>

                                            <div class="slot-price text-center">
                                                <div :id="'slot-price-' + index">
                                                    <span class="price nobr" :class="schedule.supplement_class">
                                                        {{ getPriceCurrencyFormatted(schedule.agreed_price) }}
                                                        <i class="fa fa-question-circle tooltip_cursor_help_price tooltip_price_desktop"
                                                           :class="[schedule.supplement_class, {'selected' : (selectedSlot === index) }]"
                                                           :id="'help_price_tooltip_'+index"
                                                           ></i>
                                                             <!-- Copied Tooltip -->
                                                                <b-tooltip
                                                                        placement="top"
                                                                        :ref="'help_price_tooltip_'+index"
                                                                        :target="'help_price_tooltip_'+index"
                                                                        :delay="bTooltipDelay"
                                                                        :boundary="'scrollParent'"
                                                                        :triggers="'hover'"
                                                                        :container="'help_price_tooltip_'+index"
                                                                        :fallback-placement="['flip']"
                                                                >
                                                                    <div class="help_price_tooltip-container text-center belfixBounceInUp" ref="help_price_tooltip_inner_content">
                                                                        <p class="tooltip_cross_container tooltip_cursor_help_mobile">
                                                                            <i class="fas fa-times tooltip_cursor_pointer" @click="closeTooltip"></i>
                                                                        </p>
                                                                        <div class="row">
                                                                            <div class="col-12">
                                                                                <p class="tooltip_text_left tooltip_main_title tooltip_cross_container">
                                                                                    <strong>{{ $t('the_price_include') }}</strong>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div v-for="service in servicesDatas" :key="service.serviceId" class="row tooltip_text_left">
                                                                            <div class="col-1">
                                                                                <span class="fas fa-circle tooltip_dot"></span>
                                                                            </div>
                                                                            <div class="col-10 tooltip_no_padding">
                                                                                <span style="">{{ service.serviceName }}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row tooltip_payment_container">
                                                                            <div class="col-12">
                                                                                <p>{{ $t('payment_on_spot') }}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                            </b-tooltip>
                                                        <!-- Copied Tooltip -->
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="slot-choose text-center">
                                                <button>
                                                    <template v-if="selectedSlot === index">
                                                        <span class="d-none d-md-inline">{{ $t('checkout_change') }}</span>
                                                        <span class="d-inline d-md-none"><i class="fas fa-times"></i></span>
                                                    </template>
                                                    <template v-else>
                                                        <span class="d-none d-md-inline">{{ $t('checkout_book') }}</span>
                                                        <span class="d-inline d-md-none"><i class="fas fa-angle-right"></i></span>
                                                    </template>
                                                </button>
                                            </div>
                                        </div>
                                        <template v-if="schedule.worker_id">
                                            <span class="worker-has-language-conflict d-block d-md-none text-left"
                                                  v-if="workersInfo[schedule.worker_id].workerLanguages.indexOf($locale) === -1">
                                            <i class="fas fa-exclamation-circle"></i> <span>{{ $t('language_conflict_description') }}: <strong>{{ retrieveNiceTranslatedLanguages(workersInfo[schedule.worker_id].workerLanguages) }}</strong></span>
                                        </span>
                                        </template>
                                    </div>
                                </div>
                            </transition-group>
                        </div>

                        <div class="col-md-12 slots-fader" v-show="(isSlotsDisplayed && currentMaxNumScheduleDisplay < suggestSlots.length || isSlotsDisplayed && firstSlotDisplay) && selectedSlot === null && suggestSlots.length > 5"></div>

                        <div class="col-md-12 text-center"
                             v-show="(isSlotsDisplayed && currentMaxNumScheduleDisplay < suggestSlots.length || isSlotsDisplayed && firstSlotDisplay) && selectedSlot === null">
                            <button @click="showMoreSlots"
                                    id="see-more-appointments"
                                    type="button"
                                    class="col-md-6 d-inline-block btn btn-custom-blue btn-lg btn-block mt-2">{{ $t('see_more_appointments') }}</button>
                        </div>

                        <div v-show="(!isCustomAppointmentUnAuthorized && suggestSlots.length && (selectedSlot !== null || (currentMaxNumScheduleDisplay >= suggestSlots.length && !firstSlotDisplay))) || (!suggestSlots.length && noSlotsFound && !isCustomAppointmentUnAuthorized )"
                             id="customer_info"
                             ref="customer_info"
                             class="animated fast fadeInUp row mt-2 ml-0 mr-0 text-center">
                            <!--SUMMARY-->
                            <div v-if="formStep > 1" class="col-md-12 animated fadeIn">
                                <div class="customer_info_message col-md-12 text-white">
                                    <strong>{{ $content.jobTypePath }}</strong>
                                    <br>{{ $t('on_date') }} <span class="nobr"><strong><i class="fas fa-calendar-alt"></i> {{ suggestSlots[selectedSlot].dayEur }}</strong></span>
                                    <span class="nobr"><strong><i class="fas fa-clock"></i> {{ suggestSlots[selectedSlot].proper_time_format }}</strong> <i class="fas fa-caret-right"></i> <strong>{{ suggestSlots[selectedSlot].two_hours_later }}</strong></span>
                                </div>
                                <hr>
                            </div>

                            <!--FORM STEP 2-->
                            <div v-if="formStep === 2" class="form-step col-md-12 animated fadeIn">
                                <div class="form-step-description col-md-12 text-white mb-2">{{ $t('your_intervention_address') }}</div>
                                <div class="form-row">
                                    <div class="col-md-8">
                                        <input v-model="customerInfo.street.value"
                                               @input="searchStreetDebounced"
                                               @focus="streetFieldFocus = true"
                                               @blur="focusOutStreetField"
                                               @keyup.enter="focusOutStreetField(true)"
                                               @keyup.up="moveThroughSuggestStreets"
                                               @keyup.down="moveThroughSuggestStreets"
                                               ref="streetField"
                                               id="searchStreetField"
                                               :class="['form-control', 'form-control-lg', {'is-invalid': customerInfo.street.error}]"
                                               type="text"
                                               autocomplete="off"
                                               :placeholder="$t('your_address')">
                                        <i class="street-loader fas fa-spinner fa-spin" v-show="searchStreetLoading"></i>
                                        <div v-show="streetSuggestions.length && streetFieldFocus"
                                             ref="streetSuggestionsContainer"
                                             class="street_suggestions text-dark animated fadeIn fast">
                                            <div v-for="(street, index) in streetSuggestions"
                                                 :key="street.id"
                                                 @click="selectStreet(street.street_name)"
                                                 :class="{'selected' : (selectedSuggestedStreetIndex === (index + 1))}"
                                                 class="street_suggestion p-2">
                                                {{ street.street_name }}
                                            </div>
                                        </div>
                                        <div class="" v-show="streetSuggestions.length === 0 && customerInfo.street.value.length >= 2 && !searchStreetLoading && !streetFound">
                                            <div class="col-12 container_not_found">
                                                <div class="col-12 container_no_street_found">
                                                    <i class="fas fa-exclamation-circle"></i> {{ $t('no_result_for_zipcode', {street_name: customerInfo.street.value}) }} : {{ postCode }}
                                                </div>
                                                <div class="col-12">
                                                    <label :class="['form-check-label', 'form-control', 'pl-5', 'label_confirm_address_not_found']" id="label_confirm_address_not_found" for="confirmExistingNotFoundAddress">
                                                        {{ $t('i_confirm_existing_address') }}
                                                        <input class="form-check-input ml-2 mt-2" type="checkbox" value="" id="confirmExistingNotFoundAddress" v-model="customerInfo.confirmExistingNotFoundStreet.value">
                                                        <span class="form-check-input-check-mark left_0"></span>
                                                    </label>
                                                </div>
                                                <div class="col-12 link_change_zipcode">
                                                    <a :href="currentRoute">{{ $t('change_zipcode') }}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <input v-model="customerInfo.streetNumber.value"
                                               :class="['form-control', 'form-control-lg', {'is-invalid': customerInfo.streetNumber.error}]"
                                               ref="addressNumber"
                                               type="text"
                                               maxlength="10"
                                               :placeholder="$t('your_address_number')">
                                    </div>
                                </div>
                            </div>

                            <!--FORM STEP 3-->
                            <div v-if="formStep === 3" class="form-step col-md-12 animated fadeIn">
                                <div class="form-step-description col-md-12 text-white mb-2">{{ $t('your_personal_data') }}</div>
                                <div class="form-row">
                                    <div class="col-xs-12 col-sm-12 col-md-4">
                                        <input v-model="customerInfo.emailAddress.value"
                                               :class="['form-control', 'form-control-lg', {'is-invalid': customerInfo.emailAddress.error}]"
                                               ref="emailAddressField"
                                               type="email"
                                               name="email"
                                               @focus="disablePhoneNumberField = true"
                                               @blur="disablePhoneNumberField = false"
                                               :placeholder="$t('your_email_address')">
                                    </div>

                                    <div class="col-xs-6 col-sm-6 col-md-4">
                                        <input v-model="customerInfo.firstname.value"
                                               :class="['form-control', 'form-control-lg', {'is-invalid': customerInfo.firstname.error}]"
                                               type="text"
                                               name="name"
                                               @focus="disablePhoneNumberField = true"
                                               @blur="disablePhoneNumberField = false"
                                               :placeholder="$t('your_first_name')">
                                    </div>

                                    <div class="col-xs-6 col-sm-6 col-md-4">
                                        <input v-model="customerInfo.lastname.value"
                                               :class="['form-control', 'form-control-lg', {'is-invalid': customerInfo.lastname.error}]"
                                               type="text"
                                               name="name"
                                               @focus="disablePhoneNumberField = true"
                                               @blur="disablePhoneNumberField = false"
                                               :placeholder="$t('your_last_name')">
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="col-5 col-md-2 p-1">
                                        <multiselect v-model="multiSelectCountryPhonePrefixHolder"
                                                     :options="$content.countries"
                                                     :searchable="false"
                                                     :show-labels="false"
                                                     :class="['countries']"
                                                     :allowEmpty="false"
                                                     :disabled="disablePhoneNumberField"
                                                     :max-height="275"
                                                     @select="setPhonePrefix">
                                            <template slot="singleLabel" slot-scope="props">
                                                <ul class="f32"><li :class="['flag', props.option.iso]"></li></ul>
                                                <span class="option__desc">
                                                        <span class="option__title">+{{ props.option.phonePrefix }}</span>
                                                    </span>
                                            </template>
                                            <template slot="option" slot-scope="props">
                                                <ul class="f32"><li :class="['flag', props.option.iso]"></li></ul>
                                                <div class="option__desc">
                                                    <span class="option__title">+ {{ props.option.phonePrefix }}</span>
                                                </div>
                                            </template>
                                        </multiselect>
                                    </div>
                                    <div class="col-7 col-md-10 p-1">
                                        <input v-model="customerInfo.phoneNumber.value"
                                               :class="['form-control', 'form-control-lg', {'is-invalid': customerInfo.phoneNumber.error}]"
                                               type="number"
                                               name="phone"
                                               @focus="disablePhoneNumberField = true"
                                               @blur="disablePhoneNumberField = false"
                                               :placeholder="$t('your_phone_number')">
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-12 error-message" v-show="disallowEmailProvider" v-if="$content.blockedEmailProviders">
                                        <span v-html="$t('email_provider_error_message', {email_providers : $content.blockedEmailProviders.join(', ')})"></span>
                                    </div>
                                </div>
                            </div>

                            <!--FORM STEP 4-->
                            <div v-if="formStep === 4" class="form-step col-md-12 animated fadeIn">
                                <div class="form-step-description col-md-12 text-white mb-2">{{ $t('service_details') }}</div>
                                <div class="form-row">
                                    <div class="col">
                                        <textarea v-model="customerInfo.comments.value"
                                                  ref="commentsField"
                                                  :class="['form-control', 'form-control-lg', {'is-invalid': customerInfo.comments.error}]"
                                                  :placeholder="$t('comments_field_placeholder')">
                                        </textarea>
                                    </div>
                                </div>
                                <div class="form-row text-left">
                                    <div class="col">
                                        <label :class="['form-check-label', 'form-control', 'pl-5', {'is-invalid': customerInfo.callCustomerBeforeAppointment.error}]" for="callCustomerBeforeAppointment">
                                            {{ $t('call_me_later_field') }}
                                            <input class="form-check-input ml-2 mt-2" type="checkbox" value="" id="callCustomerBeforeAppointment" v-model="customerInfo.callCustomerBeforeAppointment.value">
                                            <span class="form-check-input-check-mark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <!--FORM STEP 5-->
                            <div v-if="formStep === 5" class="form-step col-md-12 animated fadeIn">
                                <div class="form-step-description col-md-12 text-white mb-2">{{ $t('legal') }}</div>
                                <div class="form-row text-left" v-if="!$content.editMode">
                                    <div class="col">
                                        <label :class="['form-check-label', 'form-control', 'pl-5', {'is-invalid': customerInfo.receivePromotions.error}]" for="receivePromotionsCheckbox">
                                            {{ $t('commercial_offers_checkbox') }}
                                            <input class="form-check-input ml-2 mt-2" type="checkbox" value="" id="receivePromotionsCheckbox" v-model="customerInfo.receivePromotions.value">
                                            <span class="form-check-input-check-mark"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="form-row text-left">
                                    <div class="col">
                                        <label :class="['form-check-label', 'form-control', 'pl-5', {'is-invalid': customerInfo.generalConditions.error}]" for="generalConditionsCheckbox">
                                            {{ $t('terms_of_service_checkbox') }}
                                            <input class="form-check-input ml-2 mt-2" type="checkbox" value="" id="generalConditionsCheckbox" v-model="customerInfo.generalConditions.value">
                                            <span class="form-check-input-check-mark"></span>
                                        </label>
                                        <a class="read_our_terms_of_service mt-2" @click="openTermsOfService" :title="$t('read_our_terms_of_service')">
                                            <i class="fas fa-info-circle mr-1"></i> {{ $t('read_our_terms_of_service') }}
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <!-- COUPONS -->
                            <div id="coupon-container" class="col-md-12 mt-2 text-right" v-if="!$content.editMode">
                                <template v-if="showCouponForm">
                                    <input class="form-control form-control-sm mr-3 col-md-3 d-inline"
                                           type="text"
                                           ref="couponCodeField"
                                           v-model="couponCode"
                                           :placeholder="$t('coupon_code')">
                                    <button class="btn btn-custom mr-1 mt-2 mt-md-0" @click="submitCouponCode">
                                        <i class="fas fa-paper-plane mr-1"
                                           :class="{'fa-paper-plane' : !validatingCoupon, 'fa-spinner fa-spin' : validatingCoupon}"></i> {{ $t('submit') }}
                                    </button>
                                    <button class="btn btn-custom mr-1 mt-2 mt-md-0"
                                            v-if="couponValid !== null"
                                            @click="clearCoupon">
                                        <i class="fas fa-trash mr-1"></i> {{ $t('clear') }}
                                    </button>
                                    <button class="btn btn-custom mt-2 mt-md-0"
                                            @click="showCouponForm = false">
                                        <i class="fas fa-times-circle mr-1"></i> {{ $t('return') }}
                                    </button>
                                </template>
                                <template v-else>
                                    <button class="btn"
                                            :class="{'btn-custom btn-coupon' : couponValid === null, 'btn-custom-green' : couponValid === true, 'btn-custom-red' : couponValid === false}"
                                            @click="displayCouponForm">
                                        <i class="fas fa-ticket-alt mr-1"></i>
                                        <template v-if="couponValid === null">
                                            {{ $t('apply_coupon') }}
                                        </template>
                                        <template v-if="couponValid === true">
                                            <i class="fas fa-check-circle mr-1"></i>
                                            {{ couponValidDescription }}
                                        </template>
                                        <template v-if="couponValid === false">
                                            <i class="fas fa-times-circle mr-1"></i>
                                            <template v-if="couponNewAddressRequired">
                                                {{ $t('invalid_address') }}
                                            </template>
                                            <template v-else>
                                                {{ $t('invalid_coupon') }}
                                            </template>
                                        </template>
                                    </button>
                                    <button class="btn btn-custom ml-1"
                                            v-if="couponValid !== null"
                                            @click="displayCouponForm">
                                        <i class="fas fa-pencil-alt"></i>
                                    </button>
                                </template>
                            </div>
                            <!--FORM BUTTONS-->
                            <div class="form-buttons col-md-12">
                                <hr>
                                <div class="row">
                                    <div class="col-md-6 d-md-block">
                                        <button v-if="!$content.editMode && ((formStep === 2) ? selectedSlot === null : formStep > 1)"
                                                @click="previousStep()"
                                                type="button"
                                                class="btn btn-custom btn-lg btn-block mt-2 text-white">
                                            <i class="fas fa-chevron-left"></i> {{ $t('previous') }}
                                        </button>
                                    </div>
                                    <div :class="[{'col-md-12' : (formStep === 1 || (formStep === 2 && selectedSlot !== null) || $content.editMode), 'col-md-6' : (!$content.editMode && ((formStep === 2) ? selectedSlot === null : formStep > 1))}]">
                                        <span  @click="openAddressTooltip" id="btn_not_found_address_tooltip" tabindex="0" data-toggle="btn_not_found_address_tooltip" v-if="(streetSuggestions.length === 0 && (!streetFound && !customerInfo.confirmExistingNotFoundStreet.value) )">
                                            <button
                                                disabled
                                                type="button"
                                                class="btn btn-custom-yellow btn-lg btn-block mt-2">
                                                <template v-if="formStep < 5">
                                                    {{ $t('next') }} <i class="fas fa-chevron-right"></i>
                                                </template>
                                                <template v-else>
                                                    {{ $t('make_an_appointment') }}
                                                </template>
                                            </button>
                                            <b-tooltip
                                                       placement="top"
                                                       ref="btn_not_found_address_tooltip"
                                                       :target="'btn_not_found_address_tooltip'"
                                                       :delay="{ 'hide': 50 }">
                                                    <div class="btn-not-found-address-tooltip-container text-left">
                                                        <p></p>
                                                        <p v-if="this.customerInfo.street.value.length >= 2">{{ $t('please_confirm_not_found_address') }}</p>
                                                        <p v-else>{{ $t('please_add_valid_address') }}</p>
                                                    </div>
                                            </b-tooltip>
                                        </span>
                                        <button v-else @click="(formStep < 5) ? validateStep() : requestAppointment()"
                                                type="button"
                                                class="btn btn-custom-yellow btn-lg btn-block mt-2">
                                            <template v-if="formStep < 5">
                                                {{ $t('next') }} <i class="fas fa-chevron-right"></i>
                                            </template>
                                            <template v-else>
                                                {{ $t('make_an_appointment') }}
                                            </template>
                                        </button>
                                    </div>
                                    <div class="col-md-6 d-md-none">
                                        <button v-if="!$content.editMode && ((formStep === 2) ? selectedSlot === null : formStep > 1)"
                                                @click="previousStep()"
                                                type="button"
                                                class="btn btn-custom btn-lg btn-block mt-2 text-white">
                                            <i class="fas fa-chevron-left"></i> {{ $t('previous') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-if="!showSuccessMessage && showAppointmentLoading && !showFailMessage && !showRequireEmailValidation">
                        <div id="appointment-loading" class="animated fast fadeIn">
                            <div class="col-md-12 text-center">
                                <i class="fas fa-spinner fa-spin fa-7x"></i>
                                <br><br> {{ $t('preparing_your_appointment') }}
                            </div>
                        </div>
                    </template>

                    <template v-if="showSuccessMessage && !showAppointmentLoading && !showFailMessage && !showRequireEmailValidation">
                        <div id="success-message" class="animated fast fadeIn">
                            <div class="col-md-12 text-center">
                                <i class="fas fa-thumbs-up fa-5x"></i>
                                <br><br>
                                <strong>{{ $t('your_appointment_is_locked_and_secured') }}</strong>
                                <br> {{ $t('your_confirmation_number_is') }}
                                <br> <span class="confirmation-number btn-primary mb-2">{{ jobConfirmationNumber }}</span>
                                <br> <i class="far fa-envelope"></i> {{ $t('confirmation_email_sent') }}
                            </div>
                        </div>
                    </template>

                    <template v-if="!showSuccessMessage && !showAppointmentLoading && !showRequireEmailValidation && showFailMessage">
                        <div id="fail-message" class="animated fast fadeIn">
                            <div class="col-md-12 text-center">
                                <i class="fas fa-times fa-5x"></i>
                                <br><br>

                                <strong v-if="!emailValidationExpired && !emailValidationAlreadyProcessed && !emailValidationJobInThePast">{{ $t('failed_appointment_title') }}</strong>
                                <strong v-if="emailValidationExpired && !emailValidationAlreadyProcessed && !emailValidationJobInThePast">{{ $t('failed_appointment_title_email_validation_expired') }}</strong>
                                <strong v-if="!emailValidationExpired && emailValidationAlreadyProcessed && !emailValidationJobInThePast">{{ $t('failed_appointment_title_email_validation_already_processed') }}</strong>
                                <strong v-if="!emailValidationExpired && !emailValidationAlreadyProcessed && emailValidationJobInThePast">{{ $t('failed_appointment_title_email_validation_job_in_the_past') }}</strong>

                                <template v-if="!emailValidationAlreadyProcessed">
                                    <br> <button class="btn btn-primary mt-2 mb-2" @click="findAppointmentAgain">{{ $t('failed_appointment_button') }}</button>
                                    <br> <span>({{ $t('failed_appointment_note') }})</span>
                                    <br> {{ $t('failed_appointment_persists_initial') }} <a :href="contactRoute" target="_blank" :title="$t('failed_appointment_persists_final')">{{ $t('failed_appointment_persists_final') }}</a>.
                                </template>
                            </div>
                        </div>
                    </template>
                    <template v-if="!showSuccessMessage && !showAppointmentLoading && !showFailMessage && showRequireEmailValidation">
                        <div id="email-validation-message" class="animated fast fadeIn">
                            <div class="col-md-12 text-center">
                                <i class="fas fa-envelope fa-5x"></i>
                                <br><br> <strong>{{ $t('email_validation_intro') }}</strong>
                                <br> <span v-html="$t('email_validation_text', { customer_email : customerInfo.emailAddress.value })"></span>
                            </div>
                        </div>
                    </template>

                    <template v-if="!showAppointmentLoading && !showFailMessage && !showSuccessMessage && showRequireEmailValidation">
                        <div id="report-no-email-received" class="animated fast fadeIn">
                            <div class="col-md-12 text-center">
                                <template v-if="emailNotReceivedLoading">
                                    <i class="fas fa-spinner fa-spin fa-2x mt-3"></i>
                                </template>
                                <template v-else>
                                    <template v-if="!emailNotReceivedSuccessfullyReported">
                                        <button class="btn btn-primary mt-2" @click="reportEmailNotReceived">{{ showRequireEmailValidation ? $t('report_no_validation_email') : $t('report_no_confirmation_email') }}</button>
                                        <small>{{ $t('report_no_email_note') }}</small>
                                    </template>
                                    <template v-else>
                                        <br> <small>{{ $t('report_no_email_thank_you') }}</small>
                                        <br> {{ $t('report_no_email_update_email_text', { emailAddressDomain : this.customerInfo.emailAddress.value.substring(this.customerInfo.emailAddress.value.lastIndexOf("@") +1) }) }}
                                        <br> <input v-model="newEmailAddress"
                                                    :class="[{'is-invalid': emailUpdateError}]"
                                                    class="form-control form-control-lg col-md-4 ml-auto mr-auto"
                                                    ref="changeEmailAddressField"
                                                    type="email"
                                                    name="email"
                                                    :placeholder="$t('your_email_address')">
                                        <button class="btn btn-primary mt-2" @click="updateCustomerEmailAddress">{{ $t('report_no_email_update_email_button') }}</button>
                                    </template>
                                </template>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Api from '../../modules/Api';
    import Routing from '../../modules/BackendRouter';
    import bTooltipDirective from 'bootstrap-vue/es/directives/tooltip/tooltip';
    import numeral from 'numeral';

    export default {
        props: {
        },
        data() {
            return {
                isSlotsDisplayed: false,
                postCode: null,
                selectedPostCode: null,
                postCodeFieldQuery: '',
                selectedPostCodeFieldQuery: '',
                selectedCity: null,
                selectedRegionSlug: null,
                selectedCommuneNameSlug: null,
                postCodeFieldFocus: false,
                postCodeCaretPos: 0,
                selectedSuggestedPostCodeIndex: 0,
                disablePostCodeBtn: true,
                postCodeSuggestions: [],
                searchPostCodeDebounced: null,
                searchPostCodeLoading: false,
                badZipCode: false,
                zipCodeSearchUnknownError: false,
                suggestSlotsUnordered: [],
                suggestSlotsOrderSelected: null,
                selectedSlot: null,
                noSlotsFound: false,
                workersInfo: [],
                loadingSlots: false,
                firstSlotDisplay: false,
                totalScheduleDisplay: 999,
                stepNumScheduleDisplay: 12,
                currentMaxNumScheduleDisplay: 12,
                slotsDisplayNotYetExtended: true,
                slotsDisplayNotYetReduced: true,
                customerInfo: {
                    street: { type:'text', value: '', error: false, required: true, formStep: 2 },
                    confirmExistingNotFoundStreet: { type:'checkbox', value: false, error: false, required: false, formStep: 2 },
                    streetNumber: { type:'number', value: '', error: false, required: true, formStep: 2 },
                    emailAddress: { type:'email', value: '', error: false, required: true, formStep: 3 },
                    lastname: { type:'text', value: '', error: false, required: true, formStep: 3 },
                    firstname: { type:'text', value: '', error: false, required: true, formStep: 3 },
                    phoneNumberPrefix: { type:'text', value: this.$content.defaultCountry.phonePrefix, error: false, required: true, formStep: 3 },
                    phoneNumber: { type:'number', value: '', error: false, required: true, formStep: 3 },
                    comments: { type:'text', value: '', error: false, required: this.$content.jobTypeScheduleCommentRequired, minLength: 3, formStep: 4 },
                    callCustomerBeforeAppointment: { type:'checkbox', value: false, error: false, required: false, formStep: 4 },
                    receivePromotions: { type:'checkbox', value: false, error: false, required: false, formStep: 5 },
                    generalConditions: { type:'checkbox', value: false, error: false, required: true, formStep: 5 },
                },
                streetFieldFocus: false,
                streetSuggestions: [],
                searchStreetDebounced: null,
                searchStreetLoading: false,
                streetFieldCaretPos: 0,
                selectedSuggestedStreetIndex: 0,
                formStep: 1,
                lastFormStep: 5,
                customerInfoFormHasError: false,
                showAppointmentLoading: false,
                showSuccessMessage: false,
                showFailMessage: false,
                showRequireEmailValidation: false,
                emailNotReceivedLoading: false,
                emailNotReceivedSuccessfullyReported: false,
                newEmailAddress: null,
                emailUpdateError: false,
                jobConfirmationNumber: null,
                disablePhoneNumberField: true,
                multiSelectCountryPhonePrefixHolder: this.$content.defaultCountry,
                bodyElem: null,
                contactRoute: null,
                chooseOptionElem: null,
                termsOfServiceElem: null,
                termsOfServiceCloseBynElem: null,
                cartSupplementElem: null,
                cartPriceCaptionElem: null,
                cartPriceValueElem: null,
                cartSelectedPriceValueElem: null,
                $innerWindowWidth: 0,
                showSupplementTooltipExplanation: false,
                currentRoute: window.location.href.toString().split(window.location.host)[1],
                showCouponForm: false,
                couponCode: null,
                validatingCoupon: false,
                couponValid: null,
                couponValidDescription: null,
                couponErrorMessageKey: null,
                couponInfo: null,
                cartDiscountElem: null,
                couponNewAddressRequired: false,
                cartSpecialDiscountElem: null,
                isCustomAppointmentUnAuthorized: true,
                isTooltipPriceHovered: false,
                servicesDatas: [],
                bTooltipDelay: { "show": 400, "hide": 100 },
                disallowEmailProvider: false,
                streetFound: true,
                cookieCampaignId: null,
                cheapestPriceIsKept: true,
                emailAlreadyValidated: false,
                emailValidationExpired: false,
                emailValidationJobInThePast: false,
                emailValidationAlreadyProcessed: false,
                lockScroll: false,
                lockScrollTopPosition: 0,
                reversedSlotsAnimation: false,
                appointmentPayloadId: null,
                jobId: null,
                lastSlotFetchPostCode: null,
                zoneId: null,
            }
        },
        async created() {
            this.servicesDatas = this.$content.services;

            // Debounce the post code search function
            this.searchPostCodeDebounced = this.$lodash.debounce(this.searchPostCode, 500, {maxWait: 500});

            // Debounce the street search function
            this.searchStreetDebounced = this.$lodash.debounce(this.searchStreet, 500, {maxWait: 500});

            // Gets the body element for use when the Terms of Service modal is open
            this.bodyElem = document.getElementsByTagName("BODY")[0];


            // Gets the termsOfService and termsOfServiceClose btn elements for use when the Terms of Service modal is open
            this.termsOfServiceElem = document.getElementById("termsOfService");
            this.termsOfServiceCloseBtnElem = document.getElementById("termsOfServiceCloseBtn");

            // Adds an event listener to the termsOfService modal close button
            this.termsOfServiceCloseBtnElem.addEventListener('click', () => {
                this.closeTermsOfService();
            });

            // Gets the Contact route in case the schedule appointment fails and the user intends to contact us
            this.contactRoute = Routing.generate('contact.' + this.$locale, {});

            // Adds the lock scroll functionality
            window.addEventListener('scroll', () => {
                if (this.lockScroll) {
                    if ((document.documentElement.scrollTop || document.body.scrollTop) !== this.lockScrollTopPosition) {
                        window.scrollTo(0, this.lockScrollTopPosition);
                    }
                }
            });

            // Informs which view mode is the user at (AB test)
            if (typeof ga === "function") {
                // eslint-disable-next-line no-undef
                ga('set', 'page', this.currentRoute + '#view-mode-a');
                // eslint-disable-next-line no-undef
                ga('send', 'pageview');
                Api.pelicanPresenceConnect(this.currentRoute + '#view-mode-a');
            }
        },
        mounted() { // After page load
            if (!this.$content.appointmentPayload) {
                // Autofocus on the zip code input field
                setTimeout(async () => {
                    if (this.$content.selectedCommunePostCode) {
                        this.$refs.zipCodeInputField.disabled = true;
                        await this.selectPostCode({
                            id : this.$content.selectedZoneId,
                            post_code : this.$content.selectedCommunePostCode,
                            commune_name : this.$content.selectedCommune.commune_name
                        });
                        this.selectedPostCode = this.postCode;
                        this.selectedPostCodeFieldQuery = this.postCodeFieldQuery;
                        this.selectedCity = this.$content.selectedCommune.commune_name;
                        this.selectedRegionSlug = this.$content.selectedRegionSlug;
                        this.selectedCommuneNameSlug = this.$content.selectedCommune.commune_name_slug;
                        if (this.$refs.zipCodeInputField) {
                            this.$refs.zipCodeInputField.disabled = false;
                        }
                        this.postCodeSuggestions[0] = {
                            city: this.$content.selectedCommune.commune_name,
                            commune_name: this.$content.selectedCommune.commune_name,
                            id: this.$content.selectedCommune.id,
                            post_code: this.$content.selectedCommune.post_code
                        }
                    }

                    if (!this.$isMobile) {
                        this.$refs.zipCodeInputField.focus();
                    }

                    this.$refs.zipCodeInputField.addEventListener('keydown', (e) => {
                        this.avoidArrowUpAndDownCaretNavigation(e);
                    });
                }, 500);
            }

            if (this.$content.customerInfo || this.$content.appointmentPayload) {
                let customerInfoData = (this.$content.customerInfo) ? this.$content.customerInfo : this.$content.appointmentPayload.customerInfo;
                if (this.$content.appointmentPayload) {
                    customerInfoData.comments = this.$content.appointmentPayload.appointmentInfo.comments
                    customerInfoData.callCustomerBeforeAppointment = this.$content.appointmentPayload.appointmentInfo.callCustomerBeforeAppointment
                }
                this.customerInfo.street.value = customerInfoData.street;
                this.customerInfo.streetNumber.value = String(customerInfoData.streetNumber);
                this.customerInfo.emailAddress.value = customerInfoData.emailAddress;
                this.customerInfo.firstname.value = customerInfoData.firstName;
                this.customerInfo.lastname.value = customerInfoData.lastName;
                this.customerInfo.phoneNumber.value = String(customerInfoData.phoneNumber);
                this.customerInfo.phoneNumberPrefix.value = String(customerInfoData.phoneNumberPrefix);
                this.customerInfo.comments.value = customerInfoData.comments;
                this.customerInfo.callCustomerBeforeAppointment.value = customerInfoData.callCustomerBeforeAppointment;
                this.customerInfo.receivePromotions.value = customerInfoData.receivePromotions;
            }

            if (this.$content.discountCouponInfo ||  this.$content.appointmentPayload) {
                let discountCouponInfoData = (this.$content.discountCouponInfo) ? this.$content.discountCouponInfo : this.$content.appointmentPayload.appointmentInfo.discountCouponInfo;
                if (discountCouponInfoData) {
                    this.couponCode = discountCouponInfoData.code;
                    this.couponInfo = {
                        type: discountCouponInfoData.type,
                        value: discountCouponInfoData.value,
                        code: this.couponCode,
                    };
                    this.couponDescriptionSetter();
                    this.couponValid = true;
                    setTimeout(() => { this.setCouponDiscountOnCheckoutCart() }, 150);
                }
            }

            if (this.$content.specialDiscountValue) {
                setTimeout(() => { this.setSpecialDiscountOnCheckoutCart() }, 150);
            }

            this.cookieCampaignId = this.getCookie('http-referrer-campaign-id');

            if (this.$content.appointmentPayload) {
                window.__belfix_no_auto_load_pelican_presence = true; // Disables the default pelican presence on load
                this.emailAlreadyValidated = true;
                this.$content.appointmentPayload.customerInfo.emailAlreadyValidated = true;

                if (this.$content.appointmentPayloadError) {
                    this.showFailMessage = true;
                    switch (this.$content.appointmentPayloadErrorCause) {
                        case 'emailValidationAlreadyProcessed':
                            this.emailValidationAlreadyProcessed = true;
                            // Google Analytics
                            if (typeof ga === "function") {
                                setTimeout(() => {
                                    // eslint-disable-next-line no-undef
                                    ga('set', 'page', this.currentRoute + '#request-appointment-fail-already-processed');
                                    // eslint-disable-next-line no-undef
                                    ga('send', 'pageview');
                                    Api.pelicanPresenceConnect(this.currentRoute + '#request-appointment-fail-already-processed');
                                }, 650);
                            }
                            break;

                        case 'emailValidationExpired':
                            this.emailValidationExpired = true;
                            // Google Analytics
                            if (typeof ga === "function") {
                                setTimeout(() => {
                                    // eslint-disable-next-line no-undef
                                    ga('set', 'page', this.currentRoute + '#request-appointment-fail-email-validation-expired');
                                    // eslint-disable-next-line no-undef
                                    ga('send', 'pageview');
                                    Api.pelicanPresenceConnect(this.currentRoute + '#request-appointment-fail-email-validation-expired');
                                }, 650);
                            }
                            break;

                        case 'emailValidationJobInThePast':
                            this.emailValidationJobInThePast = true;
                            // Google Analytics
                            if (typeof ga === "function") {
                                setTimeout(() => {
                                    // eslint-disable-next-line no-undef
                                    ga('set', 'page', this.currentRoute + '#request-appointment-fail-email-validation-job-in-the-past');
                                    // eslint-disable-next-line no-undef
                                    ga('send', 'pageview');
                                    Api.pelicanPresenceConnect(this.currentRoute + '#request-appointment-fail-email-validation-job-in-the-past');
                                }, 650);
                            }
                            break;
                    }
                } else {
                    this.processCheckoutSubmitResponse(this.$content.checkoutSubmitAppointmentResponse)
                }
            }

            document.getElementById('app').style.zIndex = 999;
            document.body.style.height = 'auto';
            document.documentElement.style.scrollBehavior = 'unset';
        },
        methods: {
            getCookie(name) {
                let parts = ("; " + document.cookie).split("; " + name + "=");
                return (parts.length === 2) ? parts.pop().split(";").shift() : null;
            },
            closeTooltip(){
                if (this.$refs.help_price_tooltip_0) {
                    if(this.$refs.help_price_tooltip_0[0]){
                        this.$refs.help_price_tooltip_0[0].$emit('close');
                    }
                }
            },
            openTooltip(){
                if(this.$refs.help_price_tooltip_0){
                    if(this.$refs.help_price_tooltip_0[0]){
                        this.$refs.help_price_tooltip_0[0].$emit('open');
                    }
                }
            },
            openAddressTooltip(){
                if(this.$refs.btn_not_found_address_tooltip){
                    this.$refs.btn_not_found_address_tooltip.$emit('open');
                }
            },
            getReviewScore(slot) {
                return slot.rating;
            },
            displayCouponForm()
            {
                this.showCouponForm = true;
                setTimeout(() => {
                    this.$refs.couponCodeField.focus();
                }, 150);
            },
            getPriceCurrencyFormatted(priceValue) {
                if (this.$content.websiteCurrencyPosition === 'left') {
                    return this.$content.websiteCurrency + priceValue;
                }
                return priceValue + this.$content.websiteCurrency;
            },
            async couponNewAddressChecker(couponInfo)
            {
                // Returning true in this function doesn't necessarily mean that the coupon requires a new address
                // it could also mean that the coupon does not require a new address and therefore we validate this process

                // Let's check if this coupon requires the address to be new on Pelican
                if (couponInfo.onlyNewAddress && this.formStep >= 2) {
                    // It does, now let's check if an address was already submitted by the user
                    if (
                        this.customerInfo.street.value.length
                        && !this.customerInfo.street.error
                        && this.customerInfo.streetNumber.value.length
                        && !this.customerInfo.streetNumber.error
                    ) {
                        // It has been, so now it's time to connect to Pelican and check if this address already exists
                        const newAddressCheckerResponse = await Api.checkIfNewAddress(
                            this.customerInfo.street.value,
                            this.customerInfo.streetNumber.value,
                            this.postCode,
                            this.selectedRegionSlug,
                            this.selectedCommuneNameSlug,
                            this.zoneId,
                            this.$locale,
                        );
                        this.couponNewAddressAlreadyChecked = true;
                        if (newAddressCheckerResponse.error === true) {
                            // Log to bugsnag
                            window.bugsnagClient.notify(new Error('Something went wrong while checking if an Address is new on Pelican'), function (event) {
                                event.addMetadata('VueJs Data', this.getCurrentVueJsPayload());
                                event.addMetadata('Request Data', {
                                    street: this.customerInfo.street.value,
                                    streetNumber: this.customerInfo.streetNumber.value,
                                    postCode: this.postCode,
                                    selectedRegionSlug: this.selectedRegionSlug,
                                    selectedCommuneNameSlug: this.selectedCommuneNameSlug,
                                    zoneId: this.zoneId,
                                    language: this.$locale,
                                });
                            });
                            return false;
                        }

                        return newAddressCheckerResponse.addressIsNew;
                    }
                    // It hasn't been yet, so let's not validate this process until an address is input
                    return false;
                }
                return true;
            },
            couponDescriptionSetter()
            {
                switch (this.couponInfo.type) {
                    case 'subtraction':
                        this.couponValidDescription = this.getPriceCurrencyFormatted(this.couponInfo.value) + ' ' + this.$t('discount') + '!';
                        break;
                    case 'percentage':
                        this.couponValidDescription = this.couponInfo.value + '% ' + this.$t('discount') + '!';
                        break;
                    case 'free':
                        this.couponValidDescription = this.$t('free_service') + '!';
                        break;
                }
            },
            async submitCouponCode()
            {
                if (this.couponCode) {
                    this.validatingCoupon = true;
                    try {
                        let couponCheckErrorOrInvalid = false;
                        const couponCheckResponse = await Api.checkCouponCode(this.couponCode);
                        if (couponCheckResponse.error) {
                            couponCheckErrorOrInvalid = true;
                            this.couponNewAddressRequired = false;
                        } else {
                            const isAddressNew = await this.couponNewAddressChecker(couponCheckResponse.coupon);
                            if (isAddressNew === true) { // Validates
                                this.couponValid = true;
                                this.couponNewAddressRequired = false;
                                switch (couponCheckResponse.coupon.type) {
                                    case 'subtraction':
                                        this.couponValidDescription = this.getPriceCurrencyFormatted(couponCheckResponse.coupon.value) + ' ' + this.$t('discount') + '!';
                                        break;
                                    case 'percentage':
                                        this.couponValidDescription = couponCheckResponse.coupon.value + '% ' + this.$t('discount') + '!';
                                        break;
                                    case 'free':
                                        this.couponValidDescription = this.$t('free_service') + '!';
                                        break;
                                }
                                this.couponInfo = couponCheckResponse.coupon;
                            } else { // Not valid, new address required
                                couponCheckErrorOrInvalid = true;
                                this.couponNewAddressRequired = true;
                            }
                        }

                        if (couponCheckErrorOrInvalid === true) {
                            this.couponValid = false;
                            this.couponValidDescription = null;
                            this.couponInfo = null;
                        }

                        this.validatingCoupon = false;
                        this.showCouponForm = false;
                        this.setCouponDiscountOnCheckoutCart();
                    }
                    catch (error) {
                        this.couponValid = false;
                        this.couponValidDescription = null;
                        this.couponInfo = null;
                        this.couponNewAddressRequired = false;
                        throw "Something went wrong while trying to validate the inserted coupon code: " + this.couponCode;
                    }
                }
            },
            clearCoupon()
            {
                this.showCouponForm = false;
                this.couponCode = null;
                this.validatingCoupon = false;
                this.couponValid = null;
                this.couponValidDescription = null;
                this.couponErrorMessageKey = null;
                this.setCouponDiscountOnCheckoutCart();
            },
            setCouponDiscountOnCheckoutCart()
            {
                if (this.cartDiscountElem === null) {
                    this.cartDiscountElem = document.getElementById('cart_discount');
                }

                this.cartDiscountElem.innerHTML = '';

                if (this.couponValid && this.couponInfo) {
                    let content = '';
                    switch (this.couponInfo.type) {
                        case 'subtraction':
                            content = this.getPriceCurrencyFormatted(this.couponInfo.value);
                            break;
                        case 'percentage':
                            content = this.couponInfo.value + '%';
                            break;
                        case 'free':
                            content = '100%';
                            break;
                    }
                    this.cartDiscountElem.innerHTML = '<span class="discount nobr">- ' + content + '</span>';
                }
            },
            setSpecialDiscountOnCheckoutCart()
            {
                if (this.$content.jobInfo.continuationOrWarrantyJob !== true) {
                    if (this.cartSpecialDiscountElem === null) {
                        this.cartSpecialDiscountElem = document.getElementById('cart_special_discount');
                    }
                    this.cartSpecialDiscountElem.innerHTML = '<span class="discount nobr">- ' + this.getPriceCurrencyFormatted(this.$content.specialDiscountValue) + '</span>';
                }
            },
            seeMoreAppointmentsOnScroll(evt, el)
            {
                if (null === this.selectedSlot) {
                    const offsetY = 125; // px
                    let rect = el.getBoundingClientRect();
                    if (
                        (rect.bottom + offsetY) <= (window.innerHeight || document.documentElement.clientHeight) /*or $(window).height() */
                        && this.suggestSlots.length
                        && this.currentMaxNumScheduleDisplay < this.suggestSlots.length
                    ) {
                        el.click();
                    }
                }
            },
            avoidArrowUpAndDownCaretNavigation(event, field)
            {
                if (event.keyCode === 38 || event.keyCode === 40) {
                    event.preventDefault();
                    switch (field) {
                        case 'zipCodeInputField':
                            this.$refs.zipCodeInputField.selectionStart = this.postCodeCaretPos;
                            this.$refs.zipCodeInputField.selectionEnd = this.postCodeCaretPos;
                            break;
                        case 'streetField':

                            break;
                    }
                }
            },
            closeSupplementTooltip()
            {
                this.showSupplementTooltipExplanation = false;
                this.$refs['supplement-tooltip'].$emit('close');
            },
            scrollToTop(scrollDuration) {
                const scrollHeight = window.scrollY,
                    scrollStep = Math.PI / ( scrollDuration / 15 ),
                    cosParameter = scrollHeight / 2;
                var scrollCount = 0,
                    scrollMargin,
                    scrollInterval = setInterval( function() {
                        if ( window.scrollY !== 0 ) {
                            scrollCount = scrollCount + 1;
                            scrollMargin = cosParameter - cosParameter * Math.cos( scrollCount * scrollStep );
                            window.scrollTo( 0, ( scrollHeight - scrollMargin ) );
                        }
                        else clearInterval(scrollInterval);
                    }, 15 );
            },
            openTermsOfService()
            {
                this.bodyElem.classList.add('no-scroll');
                this.termsOfServiceElem.classList.add('open');
            },
            closeTermsOfService()
            {
                this.bodyElem.classList.remove('no-scroll');
                this.termsOfServiceElem.classList.remove('open');
            },
            isFloat(n) {
                return Number(n) === n && n % 1 !== 0;
            },
            setPhonePrefix(country)
            {
                this.customerInfo.phoneNumberPrefix.value = country.phonePrefix;
            },
            validatePostCodeFieldDisable()
            {
                if (this.postCodeFieldQuery) {
                    if (this.postCodeFieldQuery.length === 4
                        && !isNaN(this.postCodeFieldQuery)
                        && this.postCodeSuggestions.length > 0) {
                        this.postCode = this.postCodeFieldQuery;
                        this.disablePostCodeBtn = false;
                        return;
                    }
                }
                this.disablePostCodeBtn = true;
            },
            async fetchSlotsByZoneId(zoneId)
            {
                if (zoneId) {
                    // Reset default values
                    this.loadingSlots = true;
                    this.noSlotsFound = false;
                    this.suggestSlotsUnordered = [];
                    this.selectedSlot = null;
                    this.badZipCode = false;
                    this.zipCodeSearchUnknownError = false;
                    this.currentMaxNumScheduleDisplay = 12;
                    this.formStep = 1;
                    this.firstSlotDisplay = false;
                    this.isSlotsDisplayed = false;
                    this.slotsDisplayNotYetExtended = true;
                    this.slotsDisplayNotYetReduced = true;
                    this.cheapestPriceIsKept = true;
                    this.updateCheckoutCheapestPrice();

                    const workerId = (typeof this.$content.editMode !== 'undefined') ? (((this.$content.jobInfo.continuationOrWarrantyJob)) ? this.$content.jobInfo.workerId : null) : null;
                    const httpReferrer = this.getHttpReferrerCookie();

                    try {
                        const slotsResponse = await Api.getPelicanSuggestSlotsByZoneId(
                            this.$content.pelicanLeafJobTypeId,
                            zoneId,
                            this.$locale,
                            workerId,
                            httpReferrer
                        );
                        if (slotsResponse.error) {
                            if (slotsResponse.error_type === 'invalid_post_code') {
                                this.badZipCode = true;
                            } else {
                                // Log to Bugsnag
                                window.bugsnagClient.notify(new Error('Something went wrong while fetching slots from Pelican'), function (event) {
                                    event.addMetadata('VueJs Data', this.getCurrentVueJsPayload());
                                    event.addMetadata('Request Data', {
                                        pelicanLeafJobTypeId: this.$content.pelicanLeafJobTypeId,
                                        zoneId: zoneId,
                                        language: this.$locale,
                                        workerId: workerId,
                                        httpReferrer: httpReferrer
                                    });
                                });
                                this.zipCodeSearchUnknownError = true;
                            }
                        } else {
                            if (slotsResponse.slots.length > 0) {
                                this.zoneId = zoneId;
                                this.lastSlotFetchPostCode = this.postCode;
                                this.suggestSlotsUnordered = slotsResponse.slots;
                                this.workersInfo = slotsResponse.workersInfo;
                                this.firstSlotDisplay = true;
                                // Let's make sure it's, by default, ordered by timestamp ASC
                                if(this.$content.quotationDatas){
                                    this.suggestSlotsOrderSelected = 'price';
                                } else {
                                    this.suggestSlotsOrderSelected = 'timestamp';
                                }
                                window.setTimeout(() => {
                                    this.openTooltip()
                                }, 700);
                                window.setTimeout(() => {
                                    this.closeTooltip()
                                }, 3700);

                                this.isSlotsDisplayed = true;
                            } else {
                                this.noSlotsFound = true;
                            }

                            if (this.postCodeSuggestions.length > 0) {
                                let index = this.selectedSuggestedPostCodeIndex !== 0 ? this.selectedSuggestedPostCodeIndex - 1 : 0;
                                this.postCodeFieldQuery = this.postCodeSuggestions[index].post_code + ' ' + this.postCodeSuggestions[index].commune_name;
                                this.selectedPostCode = this.postCode;
                                this.selectedCity = this.postCodeSuggestions[index].commune_name;
                                this.selectedRegionSlug = this.postCodeSuggestions[index].region_slug;
                                this.selectedCommuneNameSlug = this.postCodeSuggestions[index].commune_name_slug;
                                this.selectedPostCodeFieldQuery = this.postCodeFieldQuery;
                                this.selectedSuggestedPostCodeIndex = 0;
                            }

                            this.cheapestPriceIsKept = slotsResponse.jobTypeAveragePriceIsKept;
                            this.updateCheckoutCheapestPrice();
                        }
                    } catch (error) {
                        // Log to Bugsnag
                        window.bugsnagClient.notify(new Error('Something went wrong while fetching slots from Pelican'), function (event) {
                            event.addMetadata('VueJs Data', this.getCurrentVueJsPayload());
                            event.addMetadata('Request Data', {
                                pelicanLeafJobTypeId: this.$content.pelicanLeafJobTypeId,
                                zoneId: zoneId,
                                language: this.$locale,
                                workerId: workerId,
                                httpReferrer: httpReferrer
                            });
                        });
                        this.zipCodeSearchUnknownError = true;
                    }
                    this.loadingSlots = false;
                }
            },
            async searchPostCode()
            {
                this.postCodeFieldQuery = this.$refs.zipCodeInputField.value;
                this.postCodeCaretPos = this.$refs.zipCodeInputField.selectionStart;
                this.selectedSuggestedPostCodeIndex = 0;
                this.postCode = null;
                this.searchPostCodeLoading = true;
                this.postCodeSuggestions = await Api.searchPostCode(this.postCodeFieldQuery, this.$locale);
                this.searchPostCodeLoading = false;
                this.validatePostCodeFieldDisable();
            },
            focusOutPostCodeField()
            {
                setTimeout(() => {
                    this.postCodeFieldFocus = false;
                    if (this.selectedPostCodeFieldQuery.length > 0 && !this.loadingSlots) {
                        this.postCodeFieldQuery = this.selectedPostCodeFieldQuery;
                    }
                }, 250);
            },
            async selectPostCode(postCodeSuggestion = null, postCodeSuggestionIndex = null)
            {
                let redirect = false;
                let zoneId = null;

                if (!postCodeSuggestion) {
                    if (this.selectedSuggestedPostCodeIndex !== 0) {
                        postCodeSuggestion = this.postCodeSuggestions[this.selectedSuggestedPostCodeIndex - 1];
                    }
                }

                if (postCodeSuggestion) {
                    if (postCodeSuggestionIndex) {
                        this.selectedSuggestedPostCodeIndex = postCodeSuggestionIndex;
                    }
                    this.postCode = postCodeSuggestion.post_code;
                    zoneId = postCodeSuggestion.id;
                    this.postCodeFieldQuery = postCodeSuggestion.post_code + ' ' + postCodeSuggestion.commune_name;
                    redirect = true;
                } else {
                    if (this.postCodeSuggestions.length) {
                        let index = this.selectedSuggestedPostCodeIndex !== 0 ? this.selectedSuggestedPostCodeIndex - 1 : 0;
                        this.postCode = this.postCodeSuggestions[index].post_code;
                        zoneId = this.postCodeSuggestions[index].id;
                        this.postCodeFieldQuery = this.postCodeSuggestions[index].post_code + ' ' + this.postCodeSuggestions[index].commune_name;
                        redirect = true;
                    } else {
                        this.postCode = null;
                    }
                }

                if (redirect) {
                    await this.fetchSlotsByZoneId(zoneId);
                    this.$refs.zipCodeInputField.blur();
                    // Google Analytics
                    if (typeof ga === "function") {
                        if (this.suggestSlotsUnordered.length) {
                            // eslint-disable-next-line no-undef
                            ga('set', 'page', this.currentRoute + '#slots-list-zone-id-' + zoneId);
                            // eslint-disable-next-line no-undef
                            ga('send', 'pageview');
                            Api.pelicanPresenceConnect(this.currentRoute + '#slots-list-zone-id-' + zoneId);
                        } else {
                            // eslint-disable-next-line no-undef
                            ga('set', 'page', this.currentRoute + '#no-slots');
                            // eslint-disable-next-line no-undef
                            ga('send', 'pageview');
                            Api.pelicanPresenceConnect(this.currentRoute + '#no-slots');
                        }
                    }
                }
            },
            moveThroughSuggestedPostCodes(event)
            {
                switch (event.keyCode) {
                    case 38: // Arrow UP
                        this.selectedSuggestedPostCodeIndex --;
                        if (this.selectedSuggestedPostCodeIndex <= 0) {
                            this.selectedSuggestedPostCodeIndex = this.postCodeSuggestions.length;
                        }
                        break;
                    case 40: // Arrow DOWN
                        this.selectedSuggestedPostCodeIndex ++;
                        if (this.selectedSuggestedPostCodeIndex > this.postCodeSuggestions.length) {
                            this.selectedSuggestedPostCodeIndex = 1;
                        }
                        break;
                }

                if (this.selectedSuggestedPostCodeIndex > 4) {
                    this.$refs.postCodeSuggestionsContainer.scrollTop = this.$refs.postCodeSuggestionsContainer.scrollHeight;
                } else {
                    this.$refs.postCodeSuggestionsContainer.scrollTop = 0;
                }

                this.postCodeFieldQuery = this.postCodeSuggestions[this.selectedSuggestedPostCodeIndex - 1].post_code + ' ' + this.postCodeSuggestions[this.selectedSuggestedPostCodeIndex - 1].commune_name;
                this.postCodeCaretPos = this.postCodeFieldQuery.length;
            },
            getElementFullHeight(el, noMargin = false)
            {
                let marginHeight = 0;
                if (noMargin === false) {
                    let elStyles = window.getComputedStyle(el);
                    marginHeight = parseFloat(elStyles['marginTop']) + parseFloat(elStyles['marginBottom']);
                }

                return Math.ceil(el.offsetHeight + marginHeight);
            },
            showMoreSlots()
            {
                let topScrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
                let removedSlotsHeight = 0;
                let firstTimeShowPreviousAppointmentsBtn = false;

                // If we're passed the total schedule display amount of slots, get the height of the removed slots
                // to help us later keep the user scroll position
                if ((this.currentMaxNumScheduleDisplay + this.stepNumScheduleDisplay) > this.totalScheduleDisplay) {
                    firstTimeShowPreviousAppointmentsBtn = (this.currentMaxNumScheduleDisplay < this.totalScheduleDisplay);
                    let slotsEls = document.querySelectorAll('.slot');
                    let numOfRemovedSlots = Math.min((this.currentMaxNumScheduleDisplay + this.stepNumScheduleDisplay - this.totalScheduleDisplay), this.stepNumScheduleDisplay);
                    for (let i = 0; i < numOfRemovedSlots; i ++) {
                        removedSlotsHeight += this.getElementFullHeight(slotsEls[i]);
                    }

                    // Take in consideration the first time the show previous appointments buttons shows up
                    if (firstTimeShowPreviousAppointmentsBtn) {
                        topScrollPosition += 67; // See previous appointments fixed height
                    }
                    topScrollPosition -= removedSlotsHeight;

                    this.$nextTick().then( () => {
                        // Keeps the user in the previously saved scroll position to help visually understand than new slots
                        // were added
                        window.scrollTo(0, topScrollPosition);
                        this.lockScrollTopPosition = topScrollPosition;
                        this.lockScroll = true;
                    });
                } else {
                    // Keeps the user in the previously saved scroll position to help visually understand than new slots
                    // were added
                    window.scrollTo(0, topScrollPosition);
                    this.lockScrollTopPosition = topScrollPosition;
                    this.lockScroll = true;
                }

                // Add the amount of slots
                this.currentMaxNumScheduleDisplay += this.stepNumScheduleDisplay;
                this.firstSlotDisplay = false;


                setTimeout(() => {
                    this.lockScroll = false;
                }, 100);

                // Google Analytics
                if (typeof ga === "function") {
                    if (this.slotsDisplayNotYetExtended) {
                        // eslint-disable-next-line no-undef
                        ga('set', 'page', this.currentRoute + '#slots-list-extended');
                        // eslint-disable-next-line no-undef
                        ga('send', 'pageview');
                        Api.pelicanPresenceConnect(this.currentRoute + '#slots-list-extended');
                        this.slotsDisplayNotYetExtended = false;
                    }
                }
            },
            showPreviousSlots()
            {
                let topScrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
                let addedSlotsHeight = 0;
                let numOfAddedSlots = Math.min((this.currentMaxNumScheduleDisplay - this.totalScheduleDisplay), this.stepNumScheduleDisplay);

                // Take in consideration the first time the show previous appointments buttons disappears
                if ((this.currentMaxNumScheduleDisplay - this.stepNumScheduleDisplay) <= this.totalScheduleDisplay) {
                    let seePreviousAppointmentsBtnEl = document.getElementById('see-previous-appointments');
                    topScrollPosition += this.getElementFullHeight(seePreviousAppointmentsBtnEl, true) - 15;
                }

                // Remove the amount of slots
                this.reversedSlotsAnimation = true;
                this.currentMaxNumScheduleDisplay -= this.stepNumScheduleDisplay;
                if ((this.currentMaxNumScheduleDisplay - this.totalScheduleDisplay) <= 0) {
                    this.firstSlotDisplay = true;
                }

                // Checks the total height of the new slots
                let slotsEls = document.querySelectorAll('.slot');
                for (let i = 0; i < numOfAddedSlots; i ++) {
                    addedSlotsHeight += this.getElementFullHeight(slotsEls[i]);
                }

                // Keeps the user in the previously saved scroll position to help visually understand than new slots
                // were added
                topScrollPosition += addedSlotsHeight;
                window.scrollTo(0, topScrollPosition);

                this.lockScrollTopPosition = topScrollPosition;
                this.lockScroll = true;

                // Unblocks the scroll
                setTimeout(() => {
                    this.lockScroll = false;
                }, 100);


                setTimeout(() => {
                    this.reversedSlotsAnimation = false;
                }, 1500); // Total animation duration for the reversed slots


                // Google Analytics
                if (typeof ga === "function") {
                    if (this.slotsDisplayNotYetReduced) {
                        // eslint-disable-next-line no-undef
                        ga('set', 'page', this.currentRoute + '#slots-list-reduced');
                        // eslint-disable-next-line no-undef
                        ga('send', 'pageview');
                        Api.pelicanPresenceConnect(this.currentRoute + '#slots-list-reduced');
                        this.slotsDisplayNotYetReduced = false;
                    }
                }
            },
            stripAndGetNumberFromStreetInput(streetInput)
            {
                let number              = null,
                    streetInputExploded = streetInput.split(' ');

                if (!isNaN(streetInputExploded[0])) {
                    number = streetInputExploded[0];
                    streetInputExploded.splice(0, 1);

                }
                if (!isNaN(streetInputExploded[streetInputExploded.length - 1])) {
                    number = streetInputExploded[streetInputExploded.length - 1];
                    streetInputExploded.splice(streetInputExploded.length - 1, 1);
                }
                return { 'number' : number, 'street' : streetInputExploded.join(' ') }

            },
            async searchStreet()
            {
                if (this.customerInfo.street.value.trim().length === 0) {
                    this.customerInfo.street.value = this.$refs.streetField.value;
                }
                this.searchStreetLoading = this.$content.EnableStreetSuggestionsAndValidation;
                this.streetFieldCaretPos = this.$refs.streetField.selectionStart;
                this.selectedSuggestedStreetIndex = 0;
                this.streetSuggestions = await Api.searchStreet(this.stripAndGetNumberFromStreetInput(this.customerInfo.street.value).street, this.selectedPostCode ? this.selectedPostCode : this.lastSlotFetchPostCode, this.$locale);
                this.streetFound = this.$content.EnableStreetSuggestionsAndValidation ? (this.streetSuggestions.length > 0 ) : true;
                this.searchStreetLoading = false;
            },
            focusOutStreetField(enter = false)
            {
                setTimeout(() => {
                    let streetAndNumber = this.stripAndGetNumberFromStreetInput(this.customerInfo.street.value);
                    if (streetAndNumber.number !== null) {
                        this.customerInfo.streetNumber.value = streetAndNumber.number;
                        this.customerInfo.street.value = streetAndNumber.street;
                    }
                    this.streetFieldFocus = false;

                    if (enter && this.$refs.addressNumber) {
                        this.$refs.addressNumber.focus();
                    }
                }, (this.$innerWindowWidth < 768) ? 1 : 250);
            },
            selectStreet(streetName)
            {
                let streetAndNumber = this.stripAndGetNumberFromStreetInput(this.customerInfo.street.value);
                if (streetAndNumber.number !== null) {
                    this.customerInfo.streetNumber.value = streetAndNumber.number;
                }
                this.customerInfo.street.value = streetName;
                this.$refs.streetField.blur();
                this.$refs.addressNumber.focus();
            },
            moveThroughSuggestStreets(event)
            {
                switch (event.keyCode) {
                    case 38: // Arrow UP
                        this.selectedSuggestedStreetIndex --;
                        if (this.selectedSuggestedStreetIndex <= 0) {
                            this.selectedSuggestedStreetIndex = this.streetSuggestions.length;
                        }
                        break;
                    case 40: // Arrow DOWN
                        this.selectedSuggestedStreetIndex ++;
                        if (this.selectedSuggestedStreetIndex > this.streetSuggestions.length) {
                            this.selectedSuggestedStreetIndex = 1;
                        }
                        break;
                }

                if (this.selectedSuggestedStreetIndex > 4) {
                    this.$refs.streetSuggestionsContainer.scrollTop = this.$refs.streetSuggestionsContainer.scrollHeight;
                } else {
                    this.$refs.streetSuggestionsContainer.scrollTop = 0;
                }

                let streetAndNumber = this.stripAndGetNumberFromStreetInput(this.customerInfo.street.value);
                if (streetAndNumber.number !== null) {
                    this.customerInfo.streetNumber.value = streetAndNumber.number;
                }

                this.customerInfo.street.value = this.streetSuggestions[this.selectedSuggestedStreetIndex - 1].street_name;
                this.streetFieldCaretPos = this.customerInfo.street.value.length;
            },
            setSelectedSlotSupplementOnCheckoutCart()
            {
                if (this.cartSupplementElem === null) {
                    this.cartSupplementElem = document.getElementById('cart_supplement');
                }

                this.cartSupplementElem.innerHTML = '';

                if (this.selectedSlot !== null) {
                    if (this.suggestSlots[this.selectedSlot].supplement > 0) {
                        this.cartSupplementElem.innerHTML = '<span class="supplement nobr ' + this.suggestSlots[this.selectedSlot].supplement_class + '">+ ' + this.getPriceCurrencyFormatted(this.suggestSlots[this.selectedSlot].supplement) +'</span>';
                    }
                }
            },
            getSupplementMessage()
            {
                if (this.selectedSlot !== null) {
                    if (this.suggestSlots[this.selectedSlot].supplement > 0) {
                        return ' ' + this.$t('supplement_description', { amount: this.suggestSlots[this.selectedSlot].supplement});
                    }
                }
                return '';
            },
            updateCheckoutCheapestPrice()
            {
                if (this.cartPriceValueElem === null) {
                    this.cartPriceValueElem = document.getElementById('cart_price_value');
                }

                this.cartPriceValueElem.innerHTML = this.getPriceCurrencyFormatted(this.cheapestPriceIsKept ? this.$content.jobTypePrice : this.winners.price.agreed_price);
            },
            setSelectSlotPriceOnCheckoutCart()
            {
                if (this.cartPriceCaptionElem === null) {
                    this.cartPriceCaptionElem = document.getElementById('cart_price_caption');
                }

                if (this.cartPriceValueElem === null) {
                    this.cartPriceValueElem = document.getElementById('cart_price_value');
                }

                if (this.cartSelectedPriceValueElem === null) {
                    this.cartSelectedPriceValueElem = document.getElementById('cart_selected_price_value');
                }

                if (this.selectedSlot === null) {
                    this.cartPriceValueElem.style.display = 'inline';
                    this.cartSelectedPriceValueElem.innerHTML = '';
                    this.cartPriceCaptionElem.style.display = 'inline';
                }

                if (this.selectedSlot !== null) {
                    this.cartPriceValueElem.style.display = 'none';
                    this.cartSelectedPriceValueElem.innerHTML = this.getPriceCurrencyFormatted(numeral(this.suggestSlots[this.selectedSlot].agreed_price)._value);
                    this.cartPriceCaptionElem.style.display = 'none';
                }
            },
            canSlotBeDisplayed(slot, index)
            {
                if (this.selectedSlot === null) { // If no slot is selected
                    if (this.$content.editMode) {
                        // If we are in edit mode, make sure we don't show the slot of the respective job being edited
                        if (slot.worker_id === this.$content.jobInfo.workerId && slot.timestamp === this.$content.jobInfo.dateStartTimestamp) {
                            return false;
                        }
                    }
                    // Show based on how many slots we are currently displaying
                    return (index > (this.currentMaxNumScheduleDisplay - this.totalScheduleDisplay)) && (index <= (this.currentMaxNumScheduleDisplay - 1));
                }
                // A slot is selected, so lets make sure we only show the respective selected slot
                return (this.selectedSlot === index);
            },
            selectSlot(index)
            {
                this.selectedSlot = (this.selectedSlot === index) ? null : index;
                this.setSelectSlotPriceOnCheckoutCart();
                if (this.selectedSlot === null) {
                    this.isCustomAppointmentUnAuthorized = true;
                    this.formStep = 1;
                    // Google Analytics
                    if (typeof ga === "function") {
                        // eslint-disable-next-line no-undef
                        ga('set', 'page', this.currentRoute + '#unselect-slot-show-slots-list');
                        // eslint-disable-next-line no-undef
                        ga('send', 'pageview');
                        Api.pelicanPresenceConnect(this.currentRoute + '#unselect-slot-show-slots-list');
                    }
                } else {
                    if (this.formStep === 1) {
                        if (this.$content.editMode) {
                            this.formStep = this.lastFormStep;
                        } else {
                            this.formStep ++;
                        }
                    }
                    setTimeout(() => {
                        this.$scrollTo(document.getElementById('checkout-schedule-cart'), 500, {offset: -15});
                        // Google Analytics
                        if (typeof ga === "function") {
                            // eslint-disable-next-line no-undef
                            ga('set', 'page', this.currentRoute + '#select-slot');
                            // eslint-disable-next-line no-undef
                            ga('send', 'pageview');
                            Api.pelicanPresenceConnect(this.currentRoute + '#select-slot');
                        }
                        this.autoFocusStep(this.formStep);
                        this.isCustomAppointmentUnAuthorized = false;
                    }, 250); // Désolé Adrian :'<
                }
            },
            emailValidator(email)
            {
                this.disallowEmailProvider = false;
                if (this.$content.blockedEmailProviders) {
                    this.$content.blockedEmailProviders.forEach(emailProvider => {
                        if (email.indexOf('@' + emailProvider) > -1) {
                            this.disallowEmailProvider = true;
                        }
                    });
                    if (this.disallowEmailProvider === true) {
                        return false;
                    }
                }
                let re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                return re.test(email);
            },
            validatePhoneNumber(phoneNumber)
            {
                let testOne,
                    testTwo;
                this.$content.countries.forEach((country) => {
                    testOne = '+' + country.phonePrefix;
                    testTwo = '00' + country.phonePrefix;
                    if (phoneNumber.substring(0, testOne.length) === testOne) {
                        phoneNumber = phoneNumber.substring(testOne.length, phoneNumber.length);
                    }
                    if (phoneNumber.substring(0, testTwo.length) === testTwo) {
                        phoneNumber = phoneNumber.substring(testTwo.length, phoneNumber.length);
                    }
                });
                if (phoneNumber.substring(0, 1) === '0') {
                    phoneNumber = phoneNumber.substring(1, phoneNumber.length);
                }
                return phoneNumber;
            },
            previousStep()
            {
                this.formStep --;
                // Google Analytics
                let googleAnalyticsHashTag = null;
                switch (this.formStep) {
                    case 2:
                        googleAnalyticsHashTag = 'address-details';
                        break;
                    case 3:
                        googleAnalyticsHashTag = 'personal-details';
                        break;
                    case 4:
                        googleAnalyticsHashTag = 'service-details';
                        break;
                }
                if (typeof ga === "function" && googleAnalyticsHashTag) {
                    // eslint-disable-next-line no-undef
                    ga('set', 'page', this.currentRoute + '#' + googleAnalyticsHashTag);
                    // eslint-disable-next-line no-undef
                    ga('send', 'pageview');
                    Api.pelicanPresenceConnect(this.currentRoute + '#' + googleAnalyticsHashTag);
                }

            },
            autoFocusStep(formStep)
            {
                let formStepFocusRef = null;
                let googleAnalyticsHashTag = null;
                switch (formStep) {
                    case 2:
                        formStepFocusRef = 'streetField';
                        googleAnalyticsHashTag = 'address-details';
                        break;
                    case 3:
                        formStepFocusRef = 'emailAddressField';
                        googleAnalyticsHashTag = 'personal-details';
                        break;
                    case 4:
                        formStepFocusRef = 'commentsField';
                        googleAnalyticsHashTag = 'service-details';
                        break;
                    case 5:
                        googleAnalyticsHashTag = 'legal';
                        break;
                }
                if (formStepFocusRef) {
                    setTimeout(() => {
                        if (typeof this.$refs[formStepFocusRef] !== "undefined") {
                            this.$refs[formStepFocusRef].focus();
                        }
                    }, 500);
                }
                // Google Analytics
                if (typeof ga === "function" && googleAnalyticsHashTag !== null) {
                    // eslint-disable-next-line no-undef
                    ga('set', 'page', this.currentRoute + '#' + googleAnalyticsHashTag);
                    // eslint-disable-next-line no-undef
                    ga('send', 'pageview');
                    Api.pelicanPresenceConnect(this.currentRoute + '#' + googleAnalyticsHashTag);
                }
            },
            getHttpReferrerCookie()
            {
                let v = document.cookie.match('(^|;) ?' + this.$content.website + '-http-referrer=([^;]*)(;|$)');
                return v ? v[2] : null;
            },
            validateStep()
            {
                this.customerInfoFormHasError = false;
                // Makes sure specific fields are trimmed
                this.customerInfo.street.value = this.customerInfo.street.value.trim();
                this.customerInfo.streetNumber.value = this.customerInfo.streetNumber.value.trim();
                this.customerInfo.firstname.value = this.customerInfo.firstname.value.trim();
                this.customerInfo.lastname.value = this.customerInfo.lastname.value.trim();
                this.customerInfo.comments.value = this.customerInfo.comments.value.trim();

                if (this.formStep > 1) {
                    Object.keys(this.customerInfo).forEach((key) => {
                        if (this.customerInfo[key].formStep === this.formStep) {
                            if (this.customerInfo[key].required) {
                                switch (this.customerInfo[key].type) {
                                    case 'text':
                                        this.customerInfo[key].error = !this.customerInfo[key].value.length;
                                        if (!this.customerInfo[key].error && 'minLength' in this.customerInfo[key]) {
                                            this.customerInfo[key].error = !(this.customerInfo[key].value.length >= this.customerInfo[key].minLength);
                                        }
                                        break;
                                    case 'number':
                                        this.customerInfo[key].error = !this.customerInfo[key].value.length;
                                        if (!this.customerInfo[key].error && key === 'phoneNumber') {
                                            this.customerInfo[key].value = this.validatePhoneNumber(this.customerInfo[key].value);
                                        }
                                        break;
                                    case 'email':
                                        this.customerInfo[key].error = !this.emailValidator(this.customerInfo[key].value);
                                        break;
                                    case 'checkbox': // boolean
                                        this.customerInfo[key].error = !this.customerInfo[key].value;
                                        break;
                                }
                                if (this.customerInfo[key].error) {
                                    this.customerInfoFormHasError = true;
                                }
                            }
                        }
                    });

                    if (this.formStep === 2) {
                        this.submitCouponCode();
                    }
                }

                if (!this.customerInfoFormHasError) {
                    // All good, let's proceed
                    if (this.$content.editMode) {
                        this.formStep = this.lastFormStep;
                    } else {
                        this.formStep ++;
                    }

                    // Auto focus by formStep
                    this.autoFocusStep(this.formStep);
                }
            },
            async requestAppointment(payload = null)
            {
                // Let's validate the last form step
                if (null === payload) {
                    this.validateStep();
                }

                // And now, if no form errors exist, let's submit the request
                if (!this.customerInfoFormHasError || payload !== null) {
                    this.showAppointmentLoading = true;
                    let checkoutSubmitResponse = null;
                    if (payload === null) {
                        // No errors found, let's submit accordingly
                        let createUserTask = false;
                        const appointmentInfo = {
                            date: null,
                            time: null,
                            score: null,
                            workerId: null,
                            supplementMessage: null,
                            comments: this.customerInfo.comments.value,
                            callCustomerBeforeAppointment: this.customerInfo.callCustomerBeforeAppointment.value,
                            isPureContact: false,
                            hopeOfGain: null,
                            isDummy: false,
                            dummyScheduled: false,
                            discountCouponInfo: null,
                            campaignId: null,
                            jobToken: null,
                            specialDiscountValue: null,
                            phpSessionId: null,
                            editMode: null,
                        };

                        const customerInfo = {
                            zoneId: this.zoneId,
                            postCode: this.selectedPostCode ? this.selectedPostCode : (this.postCode ? this.postCode : this.lastSlotFetchPostCode),
                            city: this.selectedCity,
                            regionSlug: this.selectedRegionSlug,
                            communeNameSlug: this.selectedCommuneNameSlug,
                            street: this.customerInfo.street.value,
                            streetNumber: this.customerInfo.streetNumber.value,
                            emailAddress: this.customerInfo.emailAddress.value,
                            firstName: this.customerInfo.firstname.value,
                            lastName: this.customerInfo.lastname.value,
                            phoneNumber: this.customerInfo.phoneNumberPrefix.value + this.customerInfo.phoneNumber.value,
                            receivePromotions: this.customerInfo.receivePromotions.value,
                            emailAlreadyValidated: this.emailAlreadyValidated,
                        };

                        const jobTypeInfo = {
                            jobTypeId: this.$content.jobTypeId,
                            jobTypeSlug: this.$content.jobTypeSlug,
                            pelicanLeafJobTypeId: this.$content.pelicanLeafJobTypeId,
                            questions: this.$content.questions,
                        };

                        if (this.suggestSlots[this.selectedSlot].isDummy) {
                            createUserTask = true;
                            appointmentInfo.isDummy = true;
                            appointmentInfo.campaignId = this.cookieCampaignId;
                        } else {
                            appointmentInfo.workerPseudo = this.suggestSlots[this.selectedSlot].worker_pseudo;
                            appointmentInfo.workerId = this.suggestSlots[this.selectedSlot].worker_id;
                            appointmentInfo.score = this.suggestSlots[this.selectedSlot].score;
                            appointmentInfo.campaignId = this.suggestSlots[this.selectedSlot].campaignId;
                        }

                        appointmentInfo.supplementMessage = this.getSupplementMessage();
                        appointmentInfo.date = this.suggestSlots[this.selectedSlot].date;
                        appointmentInfo.time = this.suggestSlots[this.selectedSlot].time;
                        appointmentInfo.timeFormatted = this.suggestSlots[this.selectedSlot].proper_time_format;
                        appointmentInfo.twoHoursLaterFormatted = this.suggestSlots[this.selectedSlot].two_hours_later;
                        appointmentInfo.isPureContact = this.suggestSlots[this.selectedSlot].isSlotPureContact;
                        appointmentInfo.hopeOfGain = (this.suggestSlots[this.selectedSlot].hopeOfGain) ? this.suggestSlots[this.selectedSlot].hopeOfGain : null;
                        appointmentInfo.workerLastBrutoOrPartnerPrice = (this.suggestSlots[this.selectedSlot].workerLastBrutoOrPartnerPrice) ? this.suggestSlots[this.selectedSlot].workerLastBrutoOrPartnerPrice : null;
                        appointmentInfo.commission = (this.suggestSlots[this.selectedSlot].commission) ? this.suggestSlots[this.selectedSlot].commission : null;
                        appointmentInfo.selectedSlotPrice = this.suggestSlots[this.selectedSlot].agreed_price;
                        appointmentInfo.basePrice = this.suggestSlots[this.selectedSlot].base_price;
                        appointmentInfo.editMode = (typeof this.$content.editMode !== 'undefined') ? this.$content.editMode : false;

                        if (this.couponValid) {
                            appointmentInfo.discountCouponInfo = this.couponInfo;
                        }

                        if (this.$content.editMode) {
                            appointmentInfo.jobToken = this.$content.jobToken;
                            appointmentInfo.specialDiscountValue = (this.$content.jobInfo.continuationOrWarrantyJob) ? null : this.$content.specialDiscountValue;
                            appointmentInfo.phpSessionId = this.$content.jobInfo.phpSessionId;
                        }
                        checkoutSubmitResponse = await Api.checkoutSubmitAppointment(appointmentInfo, customerInfo, jobTypeInfo, createUserTask, this.$locale);
                    } else {
                        checkoutSubmitResponse = await Api.checkoutSubmitAppointment(payload.appointmentInfo, payload.customerInfo, payload.jobTypeInfo, payload.createUserTask, this.$locale, this.$content.appointmentPayloadToken);
                    }

                    if (checkoutSubmitResponse) {
                        this.processCheckoutSubmitResponse(checkoutSubmitResponse);
                    }
                }
            },
            processCheckoutSubmitResponse(checkoutSubmitResponse)
            {
                this.showAppointmentLoading = false;

                if (checkoutSubmitResponse.jobId) {
                    this.showSuccessMessage = true;
                    this.jobConfirmationNumber = checkoutSubmitResponse.formattedScheduleConfirmationId;
                    this.jobId = checkoutSubmitResponse.jobId;
                    // Google Analytics
                    if (typeof ga === "function") {
                        setTimeout(() => {
                            // eslint-disable-next-line no-undef
                            ga('set', 'page', this.currentRoute + '#request-appointment-success');
                            // eslint-disable-next-line no-undef
                            ga('send', 'pageview');
                            Api.pelicanPresenceConnect(this.currentRoute + '#request-appointment-success');
                        }, 650);
                    }
                }

                if (checkoutSubmitResponse.error) {
                    this.showFailMessage = true;
                    switch (checkoutSubmitResponse.cause) {
                        case 'pelican_crash':
                            // Log to Bugsnag
                            window.bugsnagClient.notify(new Error('Something went wrong while submitting a new appointment into Pelican'), function (event) {
                                event.addMetadata('VueJs Data', this.getCurrentVueJsPayload());
                                event.addMetadata('Checkout Submit Response Data', checkoutSubmitResponse);
                            });
                            // Google Analytics
                            if (typeof ga === "function") {
                                // eslint-disable-next-line no-undef
                                ga('set', 'page', this.currentRoute + '#request-appointment-fail');
                                // eslint-disable-next-line no-undef
                                ga('send', 'pageview');
                                Api.pelicanPresenceConnect(this.currentRoute + '#request-appointment-fail');
                            }
                            break;
                        case 'requires_email_validation':
                            if (checkoutSubmitResponse.data) {
                                if (checkoutSubmitResponse.data.customerInfo.zoneId === null) {
                                    // Log to Bugsnag
                                    window.bugsnagClient.notify(new Error('Appointment Payload created without a Zone ID'), function (event) {
                                        event.addMetadata('VueJs Data', this.getCurrentVueJsPayload());
                                        event.addMetadata('Checkout Submit Response Data', checkoutSubmitResponse);
                                    });
                                }
                            }
                            this.showFailMessage = false;
                            this.showRequireEmailValidation = true;
                            this.appointmentPayloadId = checkoutSubmitResponse.appointmentPayloadId;
                            // Google Analytics
                            if (typeof ga === "function") {
                                // eslint-disable-next-line no-undef
                                ga('set', 'page', this.currentRoute + '#request-appointment-require-email-validation');
                                // eslint-disable-next-line no-undef
                                ga('send', 'pageview');
                                Api.pelicanPresenceConnect(this.currentRoute + '#request-appointment-require-email-validation');
                            }
                            break;
                    }
                }
            },
            findAppointmentAgain()
            {
                // Resets everything, except the customerInfo
                this.noSlotsFound = false;
                this.selectedSlot = null;
                this.badZipCode = false;
                this.zipCodeSearchUnknownError = false;
                this.currentMaxNumScheduleDisplay = this.stepNumScheduleDisplay;
                this.formStep = 1;
                this.suggestSlotsUnordered = [];
                this.firstSlotDisplay = false;
                this.slotsDisplayNotYetExtended = true;
                this.slotsDisplayNotYetReduced = true;
                this.disablePostCodeBtn = false;

                // Hides the fail message
                this.showFailMessage = false;
                this.emailValidationExpired = false;
                this.emailValidationJobInThePast = false;
            },
            retrieveNiceTranslatedLanguages(languages, separator = ', ')
            {
                let languagesText = this.$t(languages[0]);
                for (let i = 1; i < languages.length; i ++) {
                    languagesText = languagesText + separator +  this.$t(languages[i]);
                }
                return languagesText;
            },
            async reportEmailNotReceived()
            {
                this.emailNotReceivedLoading = true;
                let reportResponse = await Api.reportCheckoutScheduleNoEmailReceived(this.customerInfo.emailAddress.value, this.$locale, this.appointmentPayloadId, this.jobId);
                if (reportResponse) {
                    this.emailNotReceivedLoading = false;
                    this.emailNotReceivedSuccessfullyReported = true;
                    this.newEmailAddress = this.customerInfo.emailAddress.value;
                }
            },
            async updateCustomerEmailAddress()
            {
                this.emailNotReceivedLoading = true;
                this.emailUpdateError = !this.emailValidator(this.newEmailAddress) || this.newEmailAddress === this.customerInfo.emailAddress.value;
                if (!this.emailUpdateError) {
                    let updateResponse = await Api.reportCheckoutScheduleEmailUpdate(this.newEmailAddress, this.$locale, this.appointmentPayloadId, this.jobId);
                    if (updateResponse) {
                        this.emailNotReceivedSuccessfullyReported = false;
                        this.customerInfo.emailAddress.value = this.newEmailAddress;
                    } else {
                        this.emailUpdateError = true;
                    }
                }
                this.newEmailAddress = null;
                this.emailNotReceivedLoading = false;
            },
            getCurrentVueJsPayload()
            {
                let VueJsPayload = {};
                for (var key in this) {
                    if (!this.hasOwnProperty(key) || key.includes('$') || typeof this[key] === 'function') continue;
                    VueJsPayload[key] = this[key];
                }
                return VueJsPayload;
            }
        },
        filters: {
            trimStringByXLength(value, length, withEtc = false)
            {
                let addEtc = '';
                if (value.length > length && withEtc === true) {
                    addEtc = '...';
                }
                return value.trim().substring(0, length).trim() + addEtc;
            },
            decodeUtf8(value) {
                return decodeURIComponent(escape(value));
            },
        },
        computed: {
            suggestSlotsOrders()
            {
                return [
                    {name: this.$t('the_faster'), value: 'timestamp'},
                    {name: this.$t('the_best'), value: 'score'},
                    {name: this.$t('the_cheapest'), value: 'price'},
                ];
            },
            suggestSlots()
            {
                switch (this.suggestSlotsOrderSelected) {
                    case 'price':
                        return this.$lodash.orderBy(this.suggestSlotsUnordered, ['agreed_price', 'timestamp'], 'asc');
                    case 'score':
                        return this.$lodash.orderBy(this.suggestSlotsUnordered, ['rating', 'timestamp'], 'desc');
                    default: // by timestamp
                        return this.$lodash.orderBy(this.suggestSlotsUnordered, 'timestamp', 'asc');
                }
            },
            winners()
            {
                return {
                    timestamp: this.$lodash.orderBy(this.suggestSlotsUnordered, 'timestamp', 'asc').shift(),
                    rating: this.$lodash.orderBy(this.suggestSlotsUnordered, ['rating', 'timestamp'],  'desc').shift(),
                    price: this.$lodash.orderBy(this.suggestSlotsUnordered, ['agreed_price', 'timestamp'], 'asc').shift(),
                };
            },
        },
        watch: {
            formStep: function (newValue) {
                if (newValue === 2) {
                    this.$nextTick(() => {
                        this.$refs.streetField.addEventListener('keydown', (e) => {
                            this.avoidArrowUpAndDownCaretNavigation(e);
                        });
                    });
                }
            },
            suggestSlotsOrderSelected: function () {
                this.currentMaxNumScheduleDisplay = this.stepNumScheduleDisplay;
            },
        },
        components: {
        },
        directives: {
            'b-tooltip': bTooltipDirective
        }
    }
</script>
