<template>
    <div id="app">
        <component
                v-if="$component"
                v-bind:is="$component"
                v-bind:key="$component"
        ></component>
    </div>
</template>

<script>
    import CheckoutSchedule from './CheckoutSchedule';
    import CheckoutScheduleB from './CheckoutScheduleB';
    import CheckoutScheduleCart from './CheckoutScheduleCart';
    import Recruitment from './Recruitment';
    import RecruitmentPro from './RecruitmentPro';
    import CustomerFeedbackForm from './CustomerFeedbackForm';
    import DevisCalculator from './DevisCalculator';
    import Editor from "./Editor";

    export default {
        data()
        {
            return {}
        },
        created()
        {
        },
        mounted() {
        },
        components: {
            CheckoutSchedule,
            CheckoutScheduleB,
            CheckoutScheduleCart,
            Recruitment,
            RecruitmentPro,
            CustomerFeedbackForm,
            DevisCalculator,
            Editor,
        }
    }
</script>
