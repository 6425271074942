<template>
    <component
            v-if="$currentTheme + 'Init'"
            v-bind:is="$currentTheme + 'Init'"
            v-bind:key="$currentTheme + 'Init'"
    ></component>
</template>

<script>
  //import DepanoInit from './components/Depano/DepanoInit';
  import BelfixInit from './components/Belfix/BelfixInit';

export default {
    data()
    {
        return {}
    },
    created()
    {
    },
    mounted() {
    },
    components: {
        //DepanoInit,
        BelfixInit,
    }
}
</script>