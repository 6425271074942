<template>
    <div id="min_max">
        <span class="rounded bg-gray-200 inline-block text-blue-800">
            <span class="font-bold d-inline-block price">
                <ICountUp
                    :delay="delay"
                    :endVal="parseFloat(value.min)"
                    :options="options"
                    @ready="onReady">
            </ICountUp><span class="euro_symbol">€</span></span>
        </span>
        <span class="rounded bg-gray-200 block text-blue-800 d-and-minmax">&nbsp;et&nbsp;</span>
        <span class="rounded bg-gray-200 inline-block text-blue-800 ">
            <span class="font-bold d-inline-block price"><ICountUp
                    :delay="delay"
                    :endVal="parseFloat(value.max)"
                    :options="options"
                    @ready="onReady">
            </ICountUp><span class="euro_symbol">€</span></span>
        </span>
    </div>
</template>

<script>
    import ICountUp from 'vue-countup-v2';

    export default {
        components: {ICountUp},
        props: {
            value: Object,
        },
        data() {
            return {
                delay: 1000,
                options: {
                    useEasing: true,
                    useGrouping: true,
                    separator: '',
                    decimal: ',',
                    formattingFn: this.formater
        }
            };
        },
        methods: {
            onReady(instance) {
                instance.start();
            },
            formater(value){
                return String(value).padStart(4,'0');
            }
        },
    }
</script>
