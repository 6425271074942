<template>
    <div id="quotation_slider">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 plr-0 z-index-0">
                <VueSliderComponent
                        ref="slider"
                        v-bind:value="value"
                        v-on:change="updateValue()"
                        :min="model.range.min"
                        :max="model.range.max"
                        :dotSize="28"
                        :lazy="true"
                        :width="'auto'"
                        :contained="true"
                        :interval="model.range.step"
                        :clickable="true"
                        :duration="0.2"
                        :tooltip="focusType"
                        :tooltip-placement="tootltipPosition"
                        :use-keyboard="true"
                        @drag-end="val => fadeOutTooltip()"
                        @drag-start="val => showTooltip()"
                        :tooltip-formatter="formatted"
                        :tooltip-style="{fontWeight: 'bold', fontSize: '15px', padding: '4px 7px', backgroundColor: '#6194e4'}"
                        :process-style="{backgroundColor: '#6194e4'}"
                ></VueSliderComponent>
            </div>
        </div>
    </div>
</template>

<script>
    import VueSliderComponent from 'vue-slider-component'

    export default {
        components: {VueSliderComponent},
        props: {
            value: Number,
            model: Object,
            currentLang: String,
        },
        data(){
            return {
                formatted: '{value}',//+this.model.unit[this.currentLang]
                focusType: 'always',//+this.model.unit[this.currentLang]
                tootltipPosition: ['top']//+this.model.unit[this.currentLang]
            }
        },
        created() {
            if(this.model.unit){
                this.formatted = '{value} '+this.model.unit[this.currentLang]
            }
            if(window.innerWidth < 500){
                this.focusType = 'focus';
                this.tootltipPosition =  ['top'];
            } else {
                this.focusType = 'always';
                this.tootltipPosition =  ['top'];
            }
        },
        methods:{
            updateValue(){
                this.$emit('input', this.$refs.slider.getValue())
            },
            fadeOutTooltip(){
                if(window.innerWidth < 500) {
                    this.fadeOutEffect(this.$el.querySelector('.vue-slider-dot-tooltip'));
                }
            },
            showTooltip(){
                if(window.innerWidth < 500) {
                    this.showEffect(this.$el.querySelector('.vue-slider-dot-tooltip'));
                }
            },
            fadeOutEffect(tooltip){
                let fadeEffect = setInterval(function () {
                    if (!tooltip.style.opacity) {
                        tooltip.style.opacity = 1;
                    }
                    if (tooltip.style.opacity > 0) {
                        tooltip.style.opacity -= 0.1;
                    } else {
                        clearInterval(fadeEffect);
                    }
                }, 80);
            },
            showEffect(tooltip){
                window.setTimeout(function () {
                    tooltip.style.opacity = 1
                }, 100);
            }
        }
    }
</script>
