<template>
    <div id="quotation_choice" class="container_multiselect">
        <multiselect
                v-bind:value="options[value]"
                v-on:input="$emit('input', $event.index)"
                :options="options"
                :searchable="false"
                :allow-empty="false"
                :show-labels="false"
                :min-height="150"
                :preselectFirst="false"
                open-direction="bottom"
                label="label"
                :showPointer="showPointer"
                :class="['multiselect-custom-wrapper-class']"
                :option-height="200"
        ></multiselect>
    </div>
</template>
<script>
    import Multiselect from 'vue-multiselect';
    export default {
        components: {Multiselect},
        props: {
            value: Number,
            model: Object,
            currentLang: String,
        },
        data(){
            return{
                showPointer: true
            }
        },
        computed: {
            options() {
                return this.model.choices.map((choice, index) => ({index, label: choice.label[this.currentLang]}));
            },
        },
    }
</script>
