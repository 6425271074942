<template>
    <div>
      <template v-if="!applicationSent">
            <div class="form-fields col-md-12">
                <div class="form-row p-2">
                    <div class="col">
                        <input v-model="formData.formName.value"
                               :class="['form-control', 'form-control-lg', {'is-invalid': formData.formName.error}]"
                               type="text"
                               :placeholder="$t('your_first_and_last_name') + ((formData.formName.required) ? ' *' : '')">
                    </div>
                </div>

                <div class="form-row p-2">
                    <div class="col">
                        <input v-model="formData.formEmail.value"
                               :class="['form-control', 'form-control-lg', {'is-invalid': formData.formEmail.error}]"
                               type="email"
                               :placeholder="$t('your_email') + ((formData.formEmail.required) ? ' *' : '')">
                    </div>
                </div>

                <div class="form-row p-2">
                    <div class="col-md-4 p-1">
                        <multiselect v-model="multiSelectCountryPhonePrefixHolder"
                                     :options="$content.countries"
                                     :searchable="false"
                                     :show-labels="false"
                                     :class="['countries']"
                                     :allowEmpty="false"
                                     @select="setPhonePrefix">
                            <template slot="singleLabel" slot-scope="props">
                                <ul class="f32"><li :class="['flag', props.option.iso]"></li></ul>
                                <span class="option__desc">
                                        <span class="option__title">+{{ props.option.phonePrefix }}</span>
                                    </span>
                            </template>
                            <template slot="option" slot-scope="props">
                                <ul class="f32"><li :class="['flag', props.option.iso]"></li></ul>
                                <div class="option__desc">
                                    <span class="option__title">+ {{ props.option.phonePrefix }}</span>
                                </div>
                            </template>
                        </multiselect>
                    </div>
                    <div class="col-md-8 p-1">
                        <input v-model="formData.formPhoneNumber.value"
                               :class="['form-control', 'form-control-lg', {'is-invalid': formData.formPhoneNumber.error}]"
                               type="number"
                               :placeholder="$t('your_phone_number') + ((formData.formPhoneNumber.required) ? ' *' : '')">
                    </div>
                </div>

                <div class="form-row p-2">
                    <div class="col">
                        <input v-model="formData.formPostCode.value"
                               :class="['form-control', 'form-control-lg', {'is-invalid': formData.formPostCode.error}]"
                               type="text"
                               :placeholder="$t('post_code') + ((formData.formPostCode.required) ? ' *' : '')">
                    </div>
                </div>

                <div class="form-row p-2">
                    <div class="col">
                        <textarea v-model="formData.formMessage.value"
                                  :class="['form-control', 'form-control-lg', {'is-invalid': formData.formMessage.error}]"
                                  :placeholder="$t('your_message') + ((formData.formMessage.required) ? ' *' : '')">
                        </textarea>
                    </div>
                </div>

                <div class="form-row p-2">
                    <div class="col">
                        <multiselect v-model="multiSelectSelectedTagsHolder"
                                     :selectLabel="$t('add')"
                                     :selectedLabel="$t('selected')"
                                     :deselectLabel="$t('remove')"
                                     :class="[{'is-invalid': formData.formSkills.error}]"
                                     :placeholder="$t('skills') + ((formData.formSkills.required) ? ' *' : '')"
                                     label="name"
                                     track-by="name"
                                     :options="$content.recruitment_pro_skills"
                                     :multiple="true"
                                     :taggable="false"
                                     @select="addSkill"
                                     @remove="removeSkill">
                            <span slot="noResult">{{ $t('skill_not_found') }}</span>
                        </multiselect>
                    </div>
                </div>

                <div class="form-row p-2">
                    <div class="col">
                        <textarea v-model="formData.formOtherSkills.value"
                                  :class="['form-control', 'form-control-lg', {'is-invalid': formData.formOtherSkills.error}]"
                                  v-if="formData.formOtherSkills.required"
                                  :placeholder="$t('other_skills') + ((formData.formOtherSkills.required) ? ' *' : '')">
                        </textarea>
                    </div>
                </div>

                <div class="form-row p-2">
                    <div class="col">
                        <multiselect v-model="formData.formStatus.value"
                                     :options="$content.recruitment_pro_statuses"
                                     :searchable="false"
                                     :show-labels="false"
                                     :class="['status', {'is-invalid': formData.formStatus.error}]"
                                     :placeholder="$t('select_your_status') + ((formData.formStatus.required) ? ' *' : '')"></multiselect>
                    </div>
                </div>

                <div class="form-row p-2">

                  <div class="g-recaptcha" :data-sitekey="$content.captcha_google_site_key"></div>

                  <button @click="submitForm"
                            v-if="!sendingApplication"
                            type="button"
                            class="btn btn-custom-blue btn-lg btn-block mt-2">
                        {{ $t('receive_all_information') }}
                    </button>
                    <button v-if="sendingApplication"
                            type="button"
                            class="btn btn-default btn-lg btn-block mt-2"
                            disabled>
                        {{ $t('sending_your_application') }} <i class="fas fa-circle-notch fa-spin"></i>
                    </button>
                </div>
            </div>
        </template>
        <template v-if="applicationSent">
            <div class="application-sent text-center">
                <i class="fas fa-check-circle"></i>
                <h3>{{ $t('your_application_was_received') }}</h3>
                <button @click="applicationSent = false"
                        class="btn btn-custom with-shadow mt-5 btn-lg btn-block">
                    <i class="fas fa-chevron-left"></i> {{ $t('go_back') }}
                </button>
            </div>
        </template>
    </div>
</template>

<script>
    import Api from '../../modules/Api';

    export default {
        props: {
        },
        data() {
            return {
                formData: {
                    formName: { type: 'text', value: '', error: false, required: true },
                    formEmail: { type: 'email', value: '', error: false, required: true },
                    formPhoneNumberPrefix: { type:'text', value: this.$content.defaultCountry.phonePrefix, error: false, required: true },
                    formPhoneNumber: { type: 'number', value: '', error: false, required: true },
                    formPostCode: { type: 'text', value: '', error: false, required: true },
                    formMessage: { type: 'text', value: '', error: false, required: false },
                    formSkills: { type: 'multi-select_tags', value: [], error: false, required: true },
                    formOtherSkills: { type: 'text', value: '', error: false, required: false },
                    formStatus: { type: 'multi-select_single', value: null, error: false, required: true }
                },
                formDataHasErrors: false,
                maximumFileSizeInMb: 2,
                sendingApplication: false,
                applicationSent: false,
                multiSelectSelectedTagsHolder: [],
                multiSelectCountryPhonePrefixHolder: this.$content.defaultCountry,
            }
        },
        created() {
            //console.log(this.$content.captcha_google_site_key);
        },
        mounted() {
          this.loadGoogleRecaptchaScript();
        },
        methods: {
            loadGoogleRecaptchaScript() {
              // Create a script element
              const script = document.createElement('script');
              script.src = 'https://www.google.com/recaptcha/api.js';
              script.async = true;
              script.defer = true;

              // Append the script element to the document body
              document.body.appendChild(script);
            },
            setPhonePrefix(country)
            {
                this.formData.formPhoneNumberPrefix.value = country.phonePrefix;
            },
            addSkill(skill) {
                this.formData.formSkills.value.push(skill.name);
                if (skill.isOther) {
                    // Show other skills text area (and make it required?)
                    this.formData.formOtherSkills.required = true;
                }
            },
            removeSkill(skill)
            {
                for (let i = 0; i < this.formData.formSkills.value.length; i ++) {
                    if (this.formData.formSkills.value[i] === skill.name) {
                        this.formData.formSkills.value.splice(i, 1);
                    }
                }

                if (skill.isOther) {
                    // Hide other skills text area (and make it non-required?)
                    this.formData.formOtherSkills.required = false;
                }
            },
            emailValidator(email)
            {
                let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },
            validatePhoneNumber(phoneNumber)
            {
                let testOne,
                    testTwo;
                this.$content.countries.forEach((country) => {
                    testOne = '+' + country.phonePrefix;
                    testTwo = '00' + country.phonePrefix;
                    if (phoneNumber.substring(0, testOne.length) === testOne) {
                        phoneNumber = phoneNumber.substring(testOne.length, phoneNumber.length);
                    }
                    if (phoneNumber.substring(0, testTwo.length) === testTwo) {
                        phoneNumber = phoneNumber.substring(testTwo.length, phoneNumber.length);
                    }
                });
                if (phoneNumber.substring(0, 1) === '0') {
                    phoneNumber = phoneNumber.substring(1, phoneNumber.length);
                }
                return phoneNumber;
            },
            async submitForm()
            {
                // Let's validate all required fields
                this.formDataHasErrors = false; // Reset the error flag
                Object.keys(this.formData).forEach((key) => {
                    if (this.formData[key].required) {
                        switch (this.formData[key].type) {
                            case 'text':
                                this.formData[key].error = !this.formData[key].value.length;
                                break;
                            case 'number':
                                this.formData[key].error = !this.formData[key].value.length;
                                if (!this.formData[key].error && key === 'formPhoneNumber') {
                                    this.formData[key].value = this.validatePhoneNumber(this.formData[key].value);
                                }
                                break;
                            case 'email':
                                this.formData[key].error = !this.emailValidator(this.formData[key].value);
                                break;
                            case 'multi-select_single':
                                this.formData[key].error = (this.formData[key].value === null);
                                break;
                            case 'multi-select_tags': // array
                                this.formData[key].error = !this.formData[key].value.length;
                                break;
                            case 'checkbox': // boolean
                                this.formData[key].error = !this.formData[key].value;
                                break;
                        }
                        if (this.formData[key].error) {
                            this.formDataHasErrors = true;
                        }
                    }
                });

                if (!this.formDataHasErrors) {
                    this.sendingApplication = true;
                    const formData = new FormData();
                    formData.append('recruitment-pro-name', this.formData.formName.value);
                    formData.append('recruitment-pro-email', this.formData.formEmail.value);
                    formData.append('recruitment-pro-phone-number', '00' + this.formData.formPhoneNumberPrefix.value + this.formData.formPhoneNumber.value);
                    formData.append('recruitment-pro-post-code', this.formData.formPostCode.value);
                    formData.append('recruitment-pro-message', this.formData.formMessage.value);
                    formData.append('recruitment-pro-skills', this.formData.formSkills.value.join(', '));
                    formData.append('recruitment-pro-other-skills', this.formData.formOtherSkills.value);
                    formData.append('recruitment-pro-status', this.formData.formStatus.value);
                    formData.append('recruitment-pro-language', this.$locale);
                    formData.append('recruitment-pro-captcha', grecaptcha.getResponse())

                  await Api.submitRecruitmentPro(formData);
                    this.sendingApplication = false;
                    this.applicationSent = true;
                }
            },
        },
        components: {
        }
    }
</script>
