<template>
    <div id="calculator" class="text-gray-700 row">
        <div class="w-100 form-group">
            <h2 class="m_title">{{ $t('quotation_page_mail_title') }}</h2>
            <div class="checkout-image-element-container text-center w-50 p-2 m-auto show_mobile_only" :style="{ 'background-image': 'url(' + $content.checkoutImage + ')' }">
            </div>
        </div>
        <div class="show_desktop row">
            <div class="col-xs-7 col-sm-7 col-md-8" ref="question_desktop">
                <div
                        v-for="(question, questionId) in questions"
                        v-bind:key="questionId"
                        v-show="question.shown"
                        class="mt-6 mb-2 p-4 rounded row bg-white">
                    <div class="col-6 d-inline-block">
                        <span class="font-bold v-align-m">{{ model.questions[questionId].title[currentLang] }}</span>
                    </div>
                    <component
                            v-bind:is="model.questions[questionId].type"
                            v-bind:model="model.questions[questionId]"
                            v-bind:value="question.value"
                            v-bind:currentLang="currentLang"
                            v-on:input="onQuestionValueInput(questionId, $event)"
                            class="col-6 d-inline-block">
                    </component>
                </div>
            </div>
            <div class="col-xs-5 col-sm-5 col-md-4" ref="resume_desktop">
                <div class="bg-blue-dark w-100 p-2 text-center mt--10px" ref="resume_desktop_content">
                    <div class="checkout-image-element-container text-center w-100 p-2 m-auto">
                        <img :src="$content.checkoutImage" :alt="$content.jobTypeName">
                    </div>
                    <span class="text-white">{{ $t('our_estimation_is') }}:
                        <MinMax v-bind:value="evaluatedFormulas"></MinMax>
                    </span>
                    <div class="row mt-2">
                        <div class="col-12 mb-2">
                            <button class="btn btn-checkout-calculator" @click="goToCheckoutSchedulePage()">
                                <img src="/assets/Belfix/images/truck.png" class="f-left l--5px" width="20px" alt="Belfix Truck">{{ $t('ask_for_validation') }}</button>
                            <span class="free-tag">{{ $t('quotation_free') }}</span>
                        </div>
                        <div class="col-12 mb-2">
                            <button class="btn btn-save-calculator" v-if="!btnDownloadClicked"
                                    @click="downloadPdfQuotation()"><i
                                    class="far fa-file-pdf f-left"></i> {{ $t('save_in_pdf') }}
                            </button>
                            <button class="btn btn-save-calculator" v-if="btnDownloadClicked"><i
                                    class="fas fa-spinner fa-spin"></i></button>
                        </div>
                        <div class="col-12 mb-2">
                            <button class="btn btn-save-calculator" @click="showInput('mail')"><i
                                    class="far fa-envelope f-left" aria-hidden="true"></i> {{ $t('send_by_mail') }}
                            </button>
                        </div>
                        <div :class="['col-12']">
                            <div class="text-left" v-show="isShareMailBtnClicked && !isShareLinkBtnClicked">
                                <label class="col-12 text-white"></label>
                                <div class="col-12">
                                    <div class="row form-group">
                                        <div class="col-10">
                                            <span class="errorMessage" v-show="flagEmailError">{{ $t('quotation_invalid_mail') }}</span>
                                            <span class="errorMessage" v-show="flagSentError">{{ $t('quotation_error_mail') }}</span>
                                            <span class="successMessage" v-show="flagSentSuccess">{{ $t('quotation_success_mail') }}</span>
                                            <input type="email"
                                                   :class="['form-control', {'errorInput': flagEmailError}, 'input-email-quotation']"
                                                   aria-describedby="emailHelp" v-model="emailInputForPdf"
                                                   :placeholder="$t('quotation_email_placeholer')">
                                        </div>
                                        <div class="col-2 pl-0 pr-0">
                                            <button v-if="!btnSendClicked" class="form-control btn-send-mail"
                                                    @click="sendEmailPdf(emailInputForPdf)"><i
                                                    class="fa fa-paper-plane f-white" aria-hidden="true"></i></button>
                                            <button v-if="btnSendClicked" class="form-control btn-send-mail"><i
                                                    class="fas fa-spinner fa-spin"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mb-2">
                            <button class="btn btn-save-calculator" v-if="!isShareLinkWait" @click="getAndShowPermalinkInput('desktop')">
                                <img src="/assets/Belfix/images/share-links.png" width="13px" class="mb--2px f-left" alt="Belfix Share Links"> {{ $t('quotation_share') }}</button>
                            <button class="btn btn-save-calculator" v-if="isShareLinkWait"><i
                                    class="fas fa-spinner fa-spin"></i></button>
                            <button class="btn btn-save-calculator" v-if="!isShareLinkWait && flagGetPermalinkError">{{ $t('quotation_something_wrong') }}</button>
                        </div>
                        <div class="col-12 mb-2">
                            <div class="col-12 text-left form-group"
                                 v-show="isShareLinkBtnClicked && !isShareMailBtnClicked && !isShareLinkWait">
                                <label class="text-white">{{ $t('quotation_copy_link') }}</label>
                                <input type="text" class="form-control" v-model="permalinkInput" ref="d_input_permalink" @click="selectInputValue('desktop')" id="sharedlink-desktop-tooltip">
                                <!-- Copied Tooltip -->
                                <!--                                <b-tooltip placement="topleft"-->
                                <!--                                           ref="sharedlink-desktop-tooltip"-->
                                <!--                                           target="sharedlink-desktop-tooltip"-->
                                <!--                                           triggers="focus">-->
                                <!--                                    <div class="sharedlink-desktop-tooltip-container text-center">-->
                                <!--                                        <span>Copié dans le presse papier</span>-->
                                <!--                                    </div>-->
                                <!--                                </b-tooltip>-->
                                <!-- Copied Tooltip -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="show_mobile row p-relative">
            <div class="col-12" ref="question_mobile">
                <div
                        v-for="(question, questionId) in questions"
                        v-bind:key="questionId"
                        v-show="question.shown"
                        class="mt-2 mb-2 p-2 rounded row bg-white">
                    <div class="col-12 d-inline-block text-left">
                        <span class="font-bold v-align-m font-question">{{ model.questions[questionId].title[currentLang] }}</span>
                    </div>
                    <component
                            v-bind:is="model.questions[questionId].type"
                            v-bind:model="model.questions[questionId]"
                            v-bind:value="question.value"
                            v-bind:currentLang="currentLang"
                            v-on:input="onQuestionValueInput(questionId, $event)"
                            class="col-12 d-inline-block p-right">
                    </component>
                </div>
            </div>
            <div class="col-12 fixed-resume" ref="show_mobile">
                <div class="row bg-blue-dark p-2">
                    <div class="col-4 d-mobile-none">
                        <div class="checkout-image-element-container text-center w-100 p-2">
                            <img :src="$content.checkoutImage" :alt="$content.jobTypeName">
                        </div>
                    </div>
                    <div class="col-8 w-xs-100 d-mobile-none">
                        <span class="text-white">{{ $t('our_estimation_is') }}:
                            <MinMax v-bind:value="evaluatedFormulas"></MinMax>
                        </span>
                    </div>
                    <div class="col-12 w-xs-100 d-mobile-block text-center pt-2">
                        <span class="text-white">{{ $t('our_estimation_is') }}:
                            <MinMax v-bind:value="evaluatedFormulas"></MinMax>
                        </span>
                    </div>
                    <!--                    <div class="col-12 w-xs-100 d-mobile-block text-center pt-1 pb-1 cursor-pointer" @click="scrollTo()">-->
                    <!--                        <span><i class="fas fa-angle-down fa-2x f-white"></i></span>-->
                    <!--                    </div>-->
                </div>
            </div>
            <div class="col-12" ref="show_mobile_hide">
                <div class="row bg-blue-dark p-2">
                    <div class="col-4 d-mobile-none">
                        <div class="checkout-image-element-container text-center w-100 p-2">
                            <img :src="$content.checkoutImage" :alt="$content.jobTypeName">
                        </div>
                    </div>
                    <div class="col-8 w-xs-100 d-mobile-none">
                        <span class="text-white">{{ $t('our_estimation_is') }}:
                            <MinMax v-bind:value="evaluatedFormulas"></MinMax>
                        </span>
                    </div>
                    <div class="col-12 w-xs-100 d-mobile-block text-center pt-2 pb-2">
                        <span class="text-white">{{ $t('our_estimation_is') }}:
                            <MinMax v-bind:value="evaluatedFormulas"></MinMax>
                        </span>
                    </div>
                    <div class="bg-blue-dark w-100 p-2 text-center col-12">
                        <div class="row mb-2">
                            <div class="col-12 mb-2">
                                <button class="btn btn-checkout-calculator w-100 mb-2" @click="goToCheckoutSchedulePage()">
                                    <img src="/assets/Belfix/images/truck.png" class="f-left l--5px" width="20px" alt="Belfix Truck"> {{ $t('ask_for_validation') }}</button>
                                <span class="free-tag">{{ $t('quotation_free') }}</span>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-4">
                                <button class="btn btn-save-calculator" v-if="!btnDownloadClicked" @click="downloadPdfQuotation"><i
                                        class="far fa-file-pdf fa-2x"></i></button>
                                <button class="btn btn-save-calculator" v-if="btnDownloadClicked"><i
                                        class="fas fa-spinner fa-spin"></i></button>
                            </div>
                            <div class="col-4">
                                <button class="btn btn-save-calculator" @click="showInput('mail')"><i
                                        class="far fa-envelope fa-2x"></i></button>
                            </div>
                            <div class="col-4">
                                <button class="btn btn-save-calculator" v-if="!isShareLinkWait" @click="getAndShowPermalinkInput('mobile')"><img src="/assets/Belfix/images/share-links.png" width="25px" alt="Belfix Share Links"></button>
                                <button class="btn btn-save-calculator" v-if="isShareLinkWait"><i
                                        class="fas fa-spinner fa-spin  fa-2x"></i></button>
                                <button class="btn btn-save-calculator" v-if="!isShareLinkWait && flagGetPermalinkError">
                                    <i class="fas fa-exclamation-triangle"></i></button>
                            </div>
                            <!--                            <div class="col-3">-->
                            <!--                                <button class="btn btn-save-calculator" @click="goToCheckoutSchedulePage()"><img src="/assets/Belfix/images/truck.png" width="42px" class="ml_trunck"alt="Belfix Trunck"></button>-->
                            <!--                            </div>-->
                        </div>
                        <div class="row mt-2">
                            <div class="col-12 text-left" v-show="isShareMailBtnClicked && !isShareLinkBtnClicked">
                                <div class="row mt-2">
                                    <div class="col-10">
                                        <span class="errorMessage" v-show="flagEmailError">{{ $t('quotation_invalid_mail') }}</span>
                                        <span class="errorMessage" v-show="flagSentError">{{ $t('quotation_error_mail') }}</span>
                                        <span class="successMessage" v-show="flagSentSuccess">{{ $t('quotation_success_mail') }}</span>
                                        <input type="email" :class="['form-control', {'errorInput': flagEmailError}]"
                                               aria-describedby="emailHelp" v-model="emailMobileInputForPdf"
                                               :placeholder="$t('quotation_email_placeholer')">
                                    </div>
                                    <div class="col-2 pl-0">
                                        <button v-if="!btnSendClicked"
                                                class="form-control btn-send-mail"
                                                @click="sendEmailPdf(emailMobileInputForPdf)"><i
                                                class="fa fa-paper-plane f-white" aria-hidden="true"></i></button>
                                        <button v-if="btnSendClicked" class="form-control btn-send-mail"><i
                                                class="fas fa-spinner fa-spin"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 text-left" v-show="isShareLinkBtnClicked && !isShareMailBtnClicked && !isShareLinkWait">
                                <label class="text-white">{{ $t('quotation_copy_link') }}</label>
                                <input type="text" class="form-control" v-model="permalinkInput" ref="m_input_permalink" @click="selectInputValue('mobile')" id="sharedlink-mobile-tooltip">
                                <!-- Copied Tooltip -->
                                <!--                                <b-tooltip placement="topleft"-->
                                <!--                                           ref="sharedlink-mobile-tooltip"-->
                                <!--                                           target="sharedlink-mobile-tooltip"-->
                                <!--                                           triggers="focus">-->
                                <!--                                    <div class="sharedlink-mobile-tooltip-container text-center">-->
                                <!--                                        <span>Copié dans le presse papier</span>-->
                                <!--                                    </div>-->
                                <!--                                </b-tooltip>-->
                                <!-- Copied Tooltip -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
        <div ref="endContent"></div>
    </div>
</template>
<script>
    import Slider from './devis/questions/Slider';
    import Choice from './devis/questions/Choice';
    import MinMax from './MinMax';
    import Api from '../../../modules/Api';
    import Routing from '../../../modules/BackendRouter';
    import bTooltipDirective from 'bootstrap-vue/es/directives/tooltip/tooltip';

    import {getDefaultValue, getRangeForValue} from './devis/modules/question';


    export default {
        components: {Slider, Choice, MinMax},
        directives: {
            'b-tooltip': bTooltipDirective
        },
        props: {
            model: Object,
        },
        data() {
            return {
                questions: {},
                isShareLinkBtnClicked: false,
                isShareMailBtnClicked: false,
                emailInputForPdf: '',
                emailMobileInputForPdf: '',
                permalinkInput: '',
                flagEmailError: false,
                flagSentError: false,
                flagSentSuccess: false,
                servicePositionTop: null,
                btnSendClicked: false,
                btnDownloadClicked: false,
                isShareLinkWait: false,
                flagGetPermalinkError: false,
                lastScrollTop: 0,
                condition: true,
                currentLang: this.$content.currentLang,
                checkoutScheduleUrl: Routing.generate('checkout.' + this.$locale, {slug: this.$content.jobTypeSlug, region: this.$content.selectedRegion, commune: ((this.$content.selectedCommune) ? this.$content.selectedCommune.commune_name : null)})
            };
        },
        created() {

            window.addEventListener('scroll', () => {

                let questionContainerHeight = this.$refs.question_desktop.offsetHeight;
                let resumeContentContainerHeight = this.$refs.resume_desktop_content.offsetHeight;
                let totalContainerHeight = (questionContainerHeight - resumeContentContainerHeight) - 10;
                let endSpy = totalContainerHeight + (this.$refs.question_desktop.getBoundingClientRect().top - document.body.getBoundingClientRect().top)
                let questionDesktopOffset = this.$refs.question_desktop.getBoundingClientRect();
                //Update ResumeMarginTop Container if window scrollTop is just above
                // if((questionDesktopOffset.top - document.body.getBoundingClientRect().top - 20) ===  window.pageYOffset ){
                //     this.$refs.resume_desktop.style.marginTop = '10px';
                // }
                //Update ResumeMarginTop Container if window scrollTop is between the beginning and the end of the container
                if ( (questionDesktopOffset.top - document.body.getBoundingClientRect().top ) < window.pageYOffset && (window.pageYOffset+10) < endSpy) {
                    this.$refs.resume_desktop.style.marginTop = -((questionDesktopOffset.top - 10 )) + 'px';
                }
                //Reset ResumeMarginTop Container if window scrollTop is above the container
                if((questionDesktopOffset.top - document.body.getBoundingClientRect().top ) > window.pageYOffset + 20 ){
                    this.$refs.resume_desktop.style.marginTop = '0px';
                }
                //Mobile resume container Display Logic
                if(((window.pageYOffset )) >= this.$refs.question_mobile.offsetHeight ){
                    this.$refs.show_mobile.style.display = 'none';
                    this.$refs.show_mobile_hide.style.display = 'block';
                } else {
                    this.$refs.show_mobile.style.display = 'block';
                    this.$refs.show_mobile_hide.style.display = 'none';
                }
            });

            if(this.$content.quotationDatas){
                for (const [questionId] of Object.entries(this.model.questions)) {
                    this.$set(
                        this.questions,
                        questionId,
                        {
                            value: this.$content.quotationDatas[questionId].value,
                            shown: this.$content.quotationDatas[questionId].shown
                        }
                    );
                }
            } else {
                for (const [questionId, questionModel] of Object.entries(this.model.questions)) {
                    this.$set(
                        this.questions,
                        questionId,
                        {
                            value: getDefaultValue(questionModel),
                            shown: questionModel.hidden === undefined || questionModel.hidden !== true
                        }
                    );
                }
            }
        },
        mounted() {

        },
        methods: {
            isElementVisible(el){
                var top = el.offsetTop;
                var left = el.offsetLeft;
                var width = el.offsetWidth;
                var height = el.offsetHeight;
                while(el.offsetParent) {
                    el = el.offsetParent;
                    top += el.offsetTop;
                    left += el.offsetLeft;
                }
                return (
                    top < (window.pageYOffset + window.innerHeight) &&
                    left < (window.pageXOffset + window.innerWidth) &&
                    (top + height) > window.pageYOffset &&
                    (left + width) > window.pageXOffset
                );
            },
            // scrollTo() {
            //     let serviceElemPosTop = (document.getElementById('services').offsetTop - 50);
            //     window.scrollTo(0, serviceElemPosTop);
            // },
            async sendEmailPdf(email) {
                this.btnSendClicked = true;
                this.flagEmailError = false;
                this.flagSentError = false;
                this.flagSentSuccess = false;
                let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (email.length === 0 || !(regex.test(email))) {
                    this.flagEmailError = true
                }
                if (!(this.flagEmailError) === true) {
                    let resp = await Api.sendEmailPdfQuotation(this.currentLang, this.$content.jobTypePath, this.model, this.questions, this.evaluatedFormulas, email);
                    if (resp.sent === true) {
                        this.flagSentSuccess = true;
                        this.emailInputForPdf = '';
                        this.emailMobileInputForPdf = '';
                    } else {
                        this.flagSentError = true;
                    }
                    this.btnSendClicked = false;
                }
            },
            async downloadPdfQuotation() {
                this.btnDownloadClicked = true;
                this.hideInput();
                let route = Routing.generate('download_pdf_quotation');
                this.postMy(route, {
                    currentLang: this.currentLang,
                    jobTypePath: this.$content.jobTypePath,
                    quotationModel: JSON.stringify(this.model),
                    quotationDatas: JSON.stringify(this.questions),
                    evaluatedFormulas: JSON.stringify(this.evaluatedFormulas),
                });
                //Try to catch the end of the download ?
                window.setTimeout(() => {
                    this.btnDownloadClicked = false;
                }, 2500);
            },
            postMy(path, params, method = 'post') {

                // The rest of this code assumes you are not using a library.
                // It can be made less wordy if you use one.
                const form = document.createElement('form');
                form.method = method;
                form.action = path;

                for (const key in params) {
                    if (params.hasOwnProperty(key)) {
                        const hiddenField = document.createElement('input');
                        hiddenField.type = 'hidden';
                        hiddenField.name = key;
                        hiddenField.value = params[key];
                        form.appendChild(hiddenField);
                    }
                }
                document.body.appendChild(form);
                form.submit();
            },
            hideInput() {
                this.isShareLinkBtnClicked = false;
                this.isShareMailBtnClicked = false;
                this.flagSentError = false;
                this.flagSentSuccess = false;
                this.flagEmailError = false;
            },
            async goToCheckoutSchedulePage(){
                let resp = await Api.saveEvaluatedFormulaOnSession(this.evaluatedFormulas.min, this.evaluatedFormulas.max, this.$content.jobTypePathUrl, this.questions);
                if (resp.evaluated_quotation_stored === true) {
                    window.location.href = this.checkoutScheduleUrl;
                }
            },
            showInput(inputType) {
                this.flagSentError = false;
                this.flagSentSuccess = false;
                this.flagEmailError = false;
                if (inputType === 'mail') {
                    if (this.isShareMailBtnClicked) {
                        this.isShareMailBtnClicked = false;
                    } else {
                        this.isShareMailBtnClicked = true;
                    }
                    this.isShareLinkBtnClicked = false;
                }
            },
            async selectInputValue(device){
                if(device === 'desktop'){
                    this.$refs.d_input_permalink.focus();
                    this.$refs.d_input_permalink.select();
                } else {
                    this.$refs.m_input_permalink.focus();
                    this.$refs.m_input_permalink.select();
                }
            },
            async getAndShowPermalinkInput(device){
                this.flagSentError = false;
                this.flagSentSuccess = false;
                this.flagEmailError = false;
                if (this.isShareLinkBtnClicked) {
                    this.isShareLinkBtnClicked = false;
                } else {
                    this.isShareLinkBtnClicked = true;
                    this.isShareLinkWait = true;
                    let resp = await Api.getQuotationPermalink(this.$content.jobTypePathUrl, this.questions);
                    if (resp.success_permalink === true) {
                        this.permalinkInput = resp.permalink;
                        setTimeout(() => {
                            if(device === 'desktop'){
                                this.$refs.d_input_permalink.focus();
                                this.$refs.d_input_permalink.select();
                                // setTimeout(() => {
                                //     this.$refs['sharedlink-desktop-tooltip'].$emit('close');
                                // }, 1500);
                            } else {
                                this.$refs.m_input_permalink.focus();
                                this.$refs.m_input_permalink.select();
                                // setTimeout(() => {
                                //     this.$refs['sharedlink-mobile-tooltip'].$emit('close');
                                // }, 1500);
                            }
                            // document.execCommand("copy");
                        }, 100);
                    } else {
                        this.flagGetPermalinkError = true;
                    }
                }
                //End
                this.isShareMailBtnClicked = false;
                this.isShareLinkWait = false;
            },
            getRangeForValue,
            onQuestionValueInput(questionId, value) {
                const parsedValue = Number(value); // TODO export parse in component
                this.questions[questionId].value = parsedValue
                const questionModel = this.model.questions[questionId];
                if (
                    questionModel.type === 'choice'
                    && questionModel.choices[parsedValue].action !== undefined
                ) {
                    const action = questionModel.choices[parsedValue].action;
                    switch (action.type) {
                        case 'show':
                            for (const questionId of action.questions) {
                                this.questions[questionId].shown = true;
                            }
                            break;
                        case 'hide':
                            for (const questionId of action.questions) {
                                const questionModel = this.model.questions[questionId];
                                this.questions[questionId].shown = false;
                                this.questions[questionId].value = getDefaultValue(questionModel);
                            }
                            break;
                        default:
                            throw new Error(`unknown action type [${action.type}]`);
                    }
                }
            }
        },
        computed: {
            formulas() {
                if (typeof this.model.formula === 'string') {
                    return {min: this.model.formula, max: this.model.formula};
                }
                return this.model.formula;
            },
            reifiedFormulas() {
                const reifiedFormulas = {...this.formulas};
                for (const [questionId, questionModel] of Object.entries(this.model.questions)) {
                    const range = getRangeForValue(questionModel, this.questions[questionId].value);
                    const tag = '[' + questionId + ']'
                    reifiedFormulas.min = reifiedFormulas.min.split(tag).join(range.min); // TODO: replace all mieux torché
                    reifiedFormulas.max = reifiedFormulas.max.split(tag).join(range.max);
                }
                return reifiedFormulas;
            },
            evaluatedFormulas() {
                return {
                    min: eval(this.reifiedFormulas.min).toFixed(0),
                    max: eval(this.reifiedFormulas.max).toFixed(0),
                };
            },
        },
    }
</script>
<style>
html {
    height: initial !important;
}
</style>
