<template>
    <div class="col-12">
        <div class="row">
            <div>
                <Calculator
                        v-if="model !== null"
                        v-bind:model="model"
                        class="mb-4 ml-4 mr-4">
                </Calculator>
            </div>
        </div>
    </div>
</template>

<script>
    import LocalForage from 'localforage';
    import Calculator from './calculator-components/Calculator';

    export default {
        components: {Calculator},
        data() {
            return {
                exampleModel: null,
                modelString: null,
                model: null,
            };
        },
        async created() {
            this.exampleModel = (require('../../../../devisCalculator/Belfix/'+ this.$content.jobTypeDevisCalculator+'.json'));
            this.modelString = await LocalForage.getItem('modelString');
            if (this.modelString === null) {
                this.modelString = JSON.stringify(this.exampleModel, null, 2);
            }
            this.tryToParseModelString();
        },
        errorCaptured(error) {
            this.$noty.error(error.message);
        },
        methods: {
            onModelStringChange(value) {
                LocalForage.setItem('modelString', value);
                this.modelString = value;
                this.tryToParseModelString();
            },
            tryToParseModelString() {
                try {
                    this.model = JSON.parse(this.modelString);
                }
                catch (error) {
                    this.model = null;
                    this.$noty.error(error.message);
                }
            },
            async reload() {
                const model = this.model;
                this.model = null;
                await this.$nextTick();
                this.model = model;
            },
        },
    }
</script>
